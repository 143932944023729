import ApiService from '../../../../services/api.service';

class MasterLawanTransaksi23Service extends ApiService {
  name= 'masterLawanTransaksi23';
  path= 'pasal23/lt';

  constructor() {
    super();
    this.init()
  }

  api = {
    findOrCreate: async (data)=> {
      var res = await this.http.post(this.apiLocation+'/'+this.path+'/check', data)
      return res.data;
    },
    getBySpt: async (params)=> {
      delete params.sort
      delete params.sortBy
      delete params.column
      delete params.total
      delete params.keyword
      delete params.startDate
      delete params.endDate
      params.page = params.page - 1
      var res = await this.http.get(this.apiLocation+'/'+this.path, {params: params})
      return res;
    },
  }
}


export default new MasterLawanTransaksi23Service();
