import React from 'react';
import TextField from "react-md/lib/TextFields/TextField";
var he = require('he')

export default class TextFieldCustom extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      viewValue: '',
    }
  }

  componentWillReceiveProps(nextProps) {
    try{
      if(this.props.input.value){
        if(this.props.input.value !== nextProps.input.value) {
          this.setState({viewValue: nextProps.input.value});
          this.props.input.onChange(nextProps.input.value)
        }
      }else{
        if(typeof nextProps.input.value === 'string'){
          var realValue = nextProps.input.value
          var value = he.encode(nextProps.input.value)
          this.setState({viewValue: value});
          this.props.input.onChange(realValue)
        }else{
          this.setState({viewValue: nextProps.input.value});
          this.props.input.onChange(nextProps.input.value)
        }
      }
    }catch(e){console.log(e)}
  }

  render() {
    let { input, meta: { touched, error }, ...others } = this.props;
    let idProps = Math.random().toString(36).substring(7)+this.props.name;
    if(this.props.meta.error === 'Required') others.label = this.props.label+' (*)'

    var type = "text"
    if(this.props.type){
      this.props.type
    }

    return <TextField id={idProps} type={type} {...input} value={this.state.viewValue} {...others} error={touched && !!error} errorText={error} />
  }
}
