import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';
import { Field, reduxForm, getFormValues} from 'redux-form';

import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../../components/form';
import {terbilang as terbilangService, terbilangAsync, terbilangAsync22} from '../../../../../../services/terbilang.service';
import MasterLawanTransaksi22Service from "../../../../Master/MasterLawanTransaksi22/MasterLawanTransaksi22.service";

@connect((state) => ({
  formData: getFormValues('BP_22_Form')(state)
}))
export default class F113303Form extends Component {
  typingTimeout=1000;
  typingObj=null;

  componentDidMount() {
    this.props.connectCmp(this.reCalculate.bind(this))
  }

  async reCalculate() {
    var form = {...this.props.formData}
    var d    = form.F113303;

    Object.keys(d).forEach((k, i) => {
      if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang' || k == 'lastModifiedBy' || k == 'lastModifiedDate' || k == 'id' || k == 'createdDate' || k == 'createdBy')) {
        var x = d[k];

        if(typeof x == 'object') {
          if(x.bruto) x.pph = x.bruto*x.tarif/100
        }
      }
    })

    await this.props.initialize(form);
    await this.calculateTotal()
  }

  async calculate(e,v,pv,f, parent) {
    try {
      if(this.typingObj) clearTimeout(this.typingObj);

      this.typingObj = setTimeout(async ()=> {
        var form = this.props.formData;
        var d    = form;

        if(!d['bruto'+parent]) d['bruto'+parent] = 0;
        if(!d['tarif'+parent]) d['tarif'+parent] = 0;

        var bruto = parseFloat(d['bruto'+parent]);
        var tarif = parseFloat(d['tarif'+parent]);

        if(f.search('bruto') >= 0) {
          bruto = parseFloat(v)
        } else if(f.search('tarif') >= 0) {
          tarif = parseFloat(v)
        }

        if(!bruto) bruto = 0;
        if(!tarif) tarif = 0;

        console.log(bruto, tarif, "ini")
        await this.props.change(`pph${parent}`, bruto*tarif/100);
        this.calculateTotal()
      }, this.typingTimeout);
    } catch(e){
      console.log(e)
    }
  }

  async calculateTotal() {
    var form = this.props.formData;
    var d    = form;

    console.log(d)

    var jmlPph   = 0;
    var jmlBruto = 0;
    Object.keys(d).forEach((k, i) => {
      if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang' || k == 'lastModifiedBy' || k == 'lastModifiedDate' || k == 'id' || k == 'createdDate' || k == 'createdBy' || k == 'tarifApi' || k == 'tarifNonApi')) {
        var x = d[k];


        if(k.search('bruto') >= 0 || k.search('brutoApi') >= 0 || k.search('brutNonApi') >= 0) {
          if(d[k]) {
            console.log(d[k])
            jmlBruto += parseFloat(d[k]);
          }
        }
        if(k.search('pph') >= 0 || k.search('pphApi') >= 0 || k.search('pphNonApi') >= 0) {
          if(d[k]) {
            console.log(d[k])
            jmlPph += parseFloat(d[k]);
          }
        }
      }

    })

    this.props.change('jmlBruto', jmlBruto)
    this.props.change('jmlPph', jmlPph);
    var terbilangRes = '';
    try {
      terbilangRes = await terbilangAsync22(jmlPph);
    } catch (error) {}
    this.props.change('terbilang', terbilangRes);

  }

  render() {
    return <div>
      <div className="md-grid">
      <Field
        label     = 'Jenis Barang'
        name      = 'jenisBarang'
        className = "md-cell md-cell--6"
        component = {Textfield}
        // validate  = {validation.required}
      />
      <Field
        label     = 'Banyak Barang'
        name      = 'qty'
        className = "md-cell md-cell--6"
        component = {Textfield}
        // validate  = {validation.required}
      />
      <Field
        label     = 'Satuan Barang'
        name      = 'satuan'
        className = "md-cell md-cell--6"
        component = {Textfield}
        // money     = {','}
      />
      <Field
        label='Nilai Impor'
        name      = 'nilaiImpor'
        component = {TextfieldMask}
        money     = {','}
        className = "md-cell md-cell--6 md-text-right"
      />
      </div>

    <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
      <TableHeader>
        <TableRow>
          <TableColumn>No.</TableColumn>
          <TableColumn>Jenis Pajak</TableColumn>
          <TableColumn className='md-text-right'>Nilai Impor</TableColumn>
          <TableColumn className='md-text-right'>Tarif(%)</TableColumn>
          <TableColumn className='md-text-right'>Pajak yg Dipungut(Rp)</TableColumn>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableColumn>1.</TableColumn>
          <TableColumn>Pajak Pertambahan Nilai</TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'brutoPajakPertambahanNilai'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'PajakPertambahanNilai')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'tarifPajakPertambahanNilai'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'PajakPertambahanNilai')}
              money     = {','}
              disabled={true}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pphPajakPertambahanNilai'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              disabled={true}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>2.</TableColumn>
          <TableColumn>Pajak Penjualan Atas Barang Mewah</TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'brutoPajakPenjualanAtasBarangMewahA'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'PajakPenjualanAtasBarangMewahA')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'tarifPajakPenjualanAtasBarangMewahA'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'PajakPenjualanAtasBarangMewahA')}
              money     = {','}
              disabled={true}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pphPajakPenjualanAtasBarangMewahA'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              disabled={true}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'brutoPajakPenjualanAtasBarangMewahB'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'PajakPenjualanAtasBarangMewahB')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'tarifPajakPenjualanAtasBarangMewahB'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'PajakPenjualanAtasBarangMewahB')}
              money     = {','}
              disabled={true}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pphPajakPenjualanAtasBarangMewahB'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              disabled={true}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'brutoPajakPenjualanAtasBarangMewahC'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'PajakPenjualanAtasBarangMewahC')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'tarifPajakPenjualanAtasBarangMewahC'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'PajakPenjualanAtasBarangMewahC')}
              money     = {','}
              disabled={true}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pphPajakPenjualanAtasBarangMewahC'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              disabled={true}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'brutoPajakPenjualanAtasBarangMewahD'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'PajakPenjualanAtasBarangMewahD')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'tarifPajakPenjualanAtasBarangMewahD'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'PajakPenjualanAtasBarangMewahD')}
              money     = {','}
              disabled={true}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pphPajakPenjualanAtasBarangMewahD'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              disabled={true}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'brutoPajakPenjualanAtasBarangMewahE'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'PajakPenjualanAtasBarangMewahE')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'tarifPajakPenjualanAtasBarangMewahE'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'PajakPenjualanAtasBarangMewahE')}
              money     = {','}
              disabled={true}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pphPajakPenjualanAtasBarangMewahE'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              disabled={true}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'brutoPajakPenjualanAtasBarangMewahF'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'PajakPenjualanAtasBarangMewahF')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'tarifPajakPenjualanAtasBarangMewahF'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'PajakPenjualanAtasBarangMewahF')}
              money     = {','}
              disabled={true}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pphPajakPenjualanAtasBarangMewahF'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              disabled={true}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'brutoPajakPenjualanAtasBarangMewahG'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'PajakPenjualanAtasBarangMewahG')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'tarifPajakPenjualanAtasBarangMewahG'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'PajakPenjualanAtasBarangMewahG')}
              money     = {','}
              disabled={true}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pphPajakPenjualanAtasBarangMewahG'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              disabled={true}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'brutoPajakPenjualanAtasBarangMewahH'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'PajakPenjualanAtasBarangMewahH')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'tarifPajakPenjualanAtasBarangMewahH'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'PajakPenjualanAtasBarangMewahH')}
              money     = {','}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pphPajakPenjualanAtasBarangMewahH'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              disabled={true}
            />
          </TableColumn>
        </TableRow>

        <TableRow>
          <TableColumn>3.</TableColumn>
          <TableColumn>PPh Atas Impor Dengan Angka Pengenal Impor (API)</TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'brutoApi'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'Api')}
              money     = {','}
              // validate  = {validation.required}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'tarifApi'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'Api')}
              money     = {','}
              // validate  = {validation.required}
              disabled={true}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pphApi'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              // validate  = {validation.required}
              disabled={true}
            />
          </TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn></TableColumn>
          <TableColumn>	PPh Atas Impor Tanpa Angka Pengenal Impor (Non-API)</TableColumn>
          <TableColumn>
            <Field
              // label='Bruto'
              name      = 'brutoNonApi'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'NonApi')}
              money     = {','}
              // validate  = {validation.required}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'tarifNonApi'
              component = {TextfieldMask}
              className = 'md-text-right'
              onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'NonApi')}
              money     = {','}
              // validate  = {validation.required}
              disabled={true}
            />
          </TableColumn>
          <TableColumn>
            <Field
              name      = 'pphNonApi'
              component = {TextfieldMask}
              className = 'md-text-right'
              money     = {','}
              // validate  = {validation.required}
              disabled={true}
            />
          </TableColumn>
        </TableRow>
      </TableBody>
    </DataTable>
    </div>
  }
}
