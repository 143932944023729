import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
// import NomorBPService from '../nomorBP.service';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
// import SPT21SignerService from '../SPT21Signer/SPT21Signer.service';
import NpwpService from './../../NpwpService';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import terbilangService from '../../../../../services/terbilang.service';
import BP4a2Service from './BP4a2.service';
import BlokBunifikasiService from 'app:modules/Main/pph/21_26/BlokBunifikasi/BlokBunifikasi.service';

import BP4a2HadiahForm from './form/BP4a2Hadiah.form';
import BP4a2BungaDepositoForm from './form/BP4a2BungaDeposito.form';
import BP4a2BungaDiskontoForm from './form/BP4a2BungaDiskonto.form';
import BP4a2BungaSimpananKoperasiForm from './form/BP4a2BungaSimpananKoperasi.Form';
import BP4a2DividenForm from './form/BP4a2Dividen.form';
import BP4a2JasaKonstruksiForm from './form/BP4a2JasaKonstruksi.form';
import BP4a2TransaksiDeviratifForm from './form/BP4a2TransaksiDeviratif.form';
import BP4a2PenjualanSahamForm from './form/BP4a2PenjualanSaham.form';
import BP4a2PersewaanTanahForm from './form/BP4a2PersewaanTanah.form';
import BP4a2WpPeredaranTertentuForm from './form/BP4a2WpPeredaranTertentu.form';
import Tarif4a2Service from '../master/tarif/Tarif4a2.service';
import LawanTransaksiDialog from '../../../../../components/entity/LawanTransaksi.dialog';
import MasterLawanTransaksi4a2Service from '../../../Master/MasterLawanTransaksi4a2/MasterLawanTransaksi4a2.service';

@reduxForm({form: 'BP_4a2_Form', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  access      : state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt         : state.authEppt.currentSPT,
  formData    : getFormValues('BP_4a2_Form')(state),
  auth        : state.auth.company,
}))
export default class BP4a2Form extends FormViewSpt {
  service  = BP4a2Service
  viewType = 2;

  typingObj=null;
  typingTimeout=1000;


  initialData={
  }

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      showCalculateProgress: false,
      npwpNotValid         : true,
      defaultNo            : null,

      lkDialog: false,
      tarif   : {
        "hadiahUndian"                               : 25.0,
        "bungaDeposito"                              : 20.0,
        "penjualanSahamPendiri"                      : 0.5,
        "penjualanSahamBukanPendiri"                 : 0.1,
        "sewaTanahDanBangunan"                       : 10.0,
        "penyediaJasaKonstruksiKualifikasi"          : 2.0,
        "penyediaJasaKonstruksiTidakPunyaKualifikasi": 4.0,
        "penyediaJasaKonstruksiLain"                 : 3.0,
        "jasaPerancanganPunyaKualifikasi"            : 4.0,
        "jasaPerancanganTidakPunyaKualifikasi"       : 6.0,
        "bungaDanDiskontoObligasi"                   : 15.0,
        "bungaSimpanan"                              : 10.0,
        "penghasilanTransaksiDerivatif"              : 0.0,
        "dividenDiterima"                            : 10.0,
        "peredaranTertentu"                          : 0.5,
      }
    }
  }

  typeOptions= [
    {
      name   : 'Bunga Deposito/Tabungan, Diskonto SBI, Jasa Giro,',
      objName: 'bungaDeposito',
      id     : 'BUNGA_DEPOSITO'
    },
    {
      name   : 'Bunga dan/atau Diskonto Obligasi dan Surat Berharga Negara (SBN)',
      objName: 'bungaDiskonto',
      id     : 'BUNGA_DISKONTO'
    },
    {
      name   : 'Bunga Simpanan yang dibayarkan oleh Koperasi kepada Anggota Koperasi Orang Pribadi',
      objName: 'bungaSimpanan',
      id     : 'BUNGA_SIMPANAN'
    },
    {
      name   : 'Dividen yang Diterima atau Diperoleh Wajib Pajak Orang Pribadi Dalam Negri',
      objName: 'dividenDiterima',
      id     : 'DIVIDEN_DITERIMA'
    },
    {
      name   : 'Hadiah Undian',
      objName: 'hadiahUndian',
      id     : 'HADIAH_UNDIAN'
    },
    {
      name   : 'Penghasilan dari Usaha jasa Konstruksi',
      objName: 'jasaKonstruksi',
      id     : 'JASA_KONSTRUKSI'
    },
    {
      name   : 'Penghasilan dari Transaksi Derivatif berupa Kontrak Berjangka yang Diperdagangkan di Bursa',
      objName: 'transaksiDerivatif',
      id     : 'TRANSAKSI_DERIVATIF'
    },
    {
      name   : 'Penghasilan dari Transaksi Penjualan Saham yang Diperdagangkan di Bursa Efek',
      objName: 'penjualanSaham',
      id     : 'PENJUALAN_SAHAM'
    },
    {
      name   : 'Penghasilan dari persewaan Tanah dan/atau Bangunan',
      objName: 'persewaanTanah',
      id     : 'PERSEWAAN_TANAH'
    },
    {
      name   : 'Penghasilan dari Usaha yang Diterima Oleh Wajib Pajak yang Memiliki Peredaran Bruto Tertentu',
      objName: 'peredaranTertentu',
      id     : 'WP_PEREDARAN_TERTENTU'
    },
  ]

  async npwpValidation(evt, value){
    if(value){
      if(this.typingObj) clearTimeout(this.typingObj);

      this.typingObj = setTimeout(async ()=> {
        // if(value){
          var res = await NpwpService.api.validate4(value)
          if(res.valid){
            this.setState({npwpNotValid: false})

            var lk = await MasterLawanTransaksi4a2Service.api.page({
              'npwp.equals': value,
              // 'organizationId.equals': this.props.spt.data.organization.id
            })

            if(lk.data) {
              var formData = {...this.props.formData};

              if(lk.data.length > 0) {
                var d = lk.data[0];
                formData.penerimaPenghasilan = {
                  "npwp"   : d.npwp,
                  "nik"    : d.nik,
                  "nama"   : d.name,
                  "alamat" : d.address,
                  "telepon": d.phone,
                  "kodePos": d.postalCode,
                  "email"  : d.email
                };

              } else {
                formData.penerimaPenghasilan = {
                  "npwp" : value
                }
                this.toast.info({title: "Info", message: `Lawan Transaksi dengan npwp ${value} tidak ditemukan`})
              }
              this.props.initialize(formData);
            }
          } else {
            this.setState({npwpNotValid: true})
            this.toast.warning({title: "Validasi Npwp", message: "npwp tidak valid"})
          }
        // }
      }, this.typingTimeout);

      var nonNpwp = 0;
      if(value === '000000000000000' || value == '' || !value) {
        nonNpwp = 1;
      }

      var form = this.props.formData;
      var d    = form.bagB;
      if(d) {
        Object.keys(d).forEach((k, i) => {
          if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang')) {
            var x = d[k];

            x.nonNpwp = nonNpwp
          }
        })

        await this.props.change('bagB', d)
      }

    }
  }

  async beforeSave(value) {
    /*if (value.type === "WP_PEREDARAN_TERTENTU") {
      let defaultNo = this.state.defaultNo
      let ntpn = value.no

      if (ntpn.length !== 16) {
        this.toast.warning({title:'Warning', message:'NTPN harus 16 digit.'})
        return false
      }
      else {
        if (ntpn === defaultNo) {
          this.toast.warning({title:'Warning', message:'NTPN belum diubah.'})
          return false
        }
      }

      let letterNumber = /^[0-9A-Z]+$/;

      if (!ntpn.match(letterNumber)) {
        this.toast.warning({title:'Warning', message:'NTPN harus hanya berupa angka dan huruf.'})
        return false
      }
    }*/

    if(!value.type && value.type === '') {
      this.toast.warning({title:'Warning', message:'Jenis Bukti Potong harus diisi.'})
      return false;
    }
    if(value.bruto == 0 || value.bruto == '') {
      this.toast.warning({title:'Warning', message:'Isi bruto harus lebih besar dari 0'})
      return false;
    }

    if (value.type === 'HADIAH_UNDIAN') {
      let hadiahUndian = value.hadiahUndian

      if (hadiahUndian.b01.bruto > 0 && hadiahUndian.b01.jenisHadiahUndian.trim() === '') {
        this.toast.warning({title:'Warning', message:'Jenis hadiah undian 1 harus diisi jika bruto 1 diisi.'})
        return false;
      }

      if (hadiahUndian.b02.bruto > 0 && hadiahUndian.b02.jenisHadiahUndian.trim() === '') {
        this.toast.warning({title:'Warning', message:'Jenis hadiah undian 2 harus diisi jika bruto 2 diisi.'})
        return false;
      }

      if (hadiahUndian.b03.bruto > 0 && hadiahUndian.b03.jenisHadiahUndian.trim() === '') {
        this.toast.warning({title:'Warning', message:'Jenis hadiah undian 3 harus diisi jika bruto 3 diisi.'})
        return false;
      }

      if (hadiahUndian.b04.bruto > 0 && hadiahUndian.b04.jenisHadiahUndian.trim() === '') {
        this.toast.warning({title:'Warning', message:'Jenis hadiah undian 4 harus diisi jika bruto 4 diisi.'})
        return false;
      }

      if (hadiahUndian.b05.bruto > 0 && hadiahUndian.b05.jenisHadiahUndian.trim() === '') {
        this.toast.warning({title:'Warning', message:'Jenis hadiah undian 5 harus diisi jika bruto 5 diisi.'})
        return false;
      }

      if (hadiahUndian.b06.bruto > 0 && hadiahUndian.b06.jenisHadiahUndian.trim() === '') {
        this.toast.warning({title:'Warning', message:'Jenis hadiah undian 6 harus diisi jika bruto 6 diisi.'})
        return false;
      }
    }

    this.typeOptions.forEach((d, i)=> {
      if(value.type !== d.id) {
        delete value[d.objName];
      }
    })

    // console.log(value);

    var date;
    if(typeof value.tanggal === 'string') {
      if(value.tanggal.search('/') != -1) {
        date = moment(value.tanggal, 'DD/MM/YYYY');
      } else {
        date = moment(value.tanggal, 'YYYY-MM-DD');
      }
    } else if(typeof value.tanggal === 'object') {
      date = moment(value.tanggal)
    }

    if (value.type !== 'WP_PEREDARAN_TERTENTU') {
      if(!(date.year() == this.props.spt.data.year && date.month()+1 == this.props.spt.data.month)) {
        this.toast.warning({title:'Warning', message:'Tanggal bukti potong harus sesuai dengan masa SPT'})
        return false;
      }
    }

    // Remapping Objek
    var mapping = {
      "address"     : value.penerimaPenghasilan.alamat,
      "cuttingDate" : value.tanggal,
      "email"       : value.penerimaPenghasilan.email,
      "gross"       : value.bruto,
      "name"        : value.penerimaPenghasilan.nama,
      "no"          : value.no,
      "npwp"        : value.penerimaPenghasilan.npwp,
      "phone"       : value.penerimaPenghasilan.telepon,
      "postal_code" : value.penerimaPenghasilan.kodePos,
      "pph"         : value.pph,
      "ref"         : value.ref,
      "signName"    : value.namaPemotong,
      "signNpwp"    : value.npwpPemotong,
      "type"        : value.type,
      "words"       : value.terbilang,
      "createdUsername" : value.createdUsername,
      "modifiedUsername" : value.modifiedUsername,
      "transaksiDerivatif": {
        "id"    : null,
        "gross" : value.transaksiDerivatif ? value.transaksiDerivatif.nilaiMarginAwal : 0,
        "pph"   : value.transaksiDerivatif ? value.transaksiDerivatif.pph : 0,
        "rates" : value.transaksiDerivatif ? value.transaksiDerivatif.tarif : 0
      },
      "persewaanTanah": {
        "id"      : null,
        "b1Gross" : value.persewaanTanah ? value.persewaanTanah.bruto : 0,
        "b1Pph"   : value.persewaanTanah ? value.persewaanTanah.pph : 0,
        "b1Rates" : value.persewaanTanah ? value.persewaanTanah.tarif : 0,
        "lokasi"  : value.persewaanTanah ? value.persewaanTanah.lokasi : "",
        "nop"     : value.persewaanTanah ? value.persewaanTanah.nop : ""
      },
      "peredaranBruto": {
        "id"      : null,
        "b1Gross" : value.peredaranTertentu ? value.peredaranTertentu.bruto : 0,
        "b1Pph"   : value.peredaranTertentu ? value.peredaranTertentu.pph : 0,
        "b1Rates" : value.peredaranTertentu ? value.peredaranTertentu.tarif : 0,
      },
      "penjualanSaham": {
        "id"        : null,
        "b1Gross"   : value.penjualanSaham ? value.penjualanSaham.sahamPendiri.nilaiTransaksiPenjualan : 0,
        "b1Pph"     : value.penjualanSaham ? value.penjualanSaham.sahamPendiri.pph : 0,
        "b1Rates"   : value.penjualanSaham ? value.penjualanSaham.sahamPendiri.tarif : 0,
        "b2Gross"   : value.penjualanSaham ? value.penjualanSaham.bukanSahamPendiri.nilaiTransaksiPenjualan : 0,
        "b2Pph"     : value.penjualanSaham ? value.penjualanSaham.bukanSahamPendiri.pph : 0,
        "b2Rates"   : value.penjualanSaham ? value.penjualanSaham.bukanSahamPendiri.tarif : 0,
      },
      "jasaKonstruksi": {
        "id"        : null,
        "b5Type"    : 1,
        "b4Type"    : 1,
        "b1Gross"   : value.jasaKonstruksi ? value.jasaKonstruksi.jasaPelaksanaanDenganKualifikasi.bruto : 0,
        "b1Pph"     : value.jasaKonstruksi ? value.jasaKonstruksi.jasaPelaksanaanDenganKualifikasi.pph : 0,
        "b1Rates"   : value.jasaKonstruksi ? value.jasaKonstruksi.jasaPelaksanaanDenganKualifikasi.tarif : 0,
        "b2Gross"   : value.jasaKonstruksi ? value.jasaKonstruksi.jasaPelaksanaanTanpaKualifikasi.bruto : 0,
        "b2Pph"     : value.jasaKonstruksi ? value.jasaKonstruksi.jasaPelaksanaanTanpaKualifikasi.pph : 0,
        "b2Rates"   : value.jasaKonstruksi ? value.jasaKonstruksi.jasaPelaksanaanTanpaKualifikasi.tarif : 0,
        "b3Gross"   : value.jasaKonstruksi ? value.jasaKonstruksi.jasaPelaksanaanSelainNo1Dan2.bruto : 0,
        "b3Pph"     : value.jasaKonstruksi ? value.jasaKonstruksi.jasaPelaksanaanSelainNo1Dan2.pph : 0,
        "b3Rates"   : value.jasaKonstruksi ? value.jasaKonstruksi.jasaPelaksanaanSelainNo1Dan2.tarif : 0,
        "b4Gross"   : value.jasaKonstruksi ? value.jasaKonstruksi.jasaPengawasanDenganKualifikasi.bruto : 0,
        "b4Pph"     : value.jasaKonstruksi ? value.jasaKonstruksi.jasaPengawasanDenganKualifikasi.pph : 0,
        "b4Rates"   : value.jasaKonstruksi ? value.jasaKonstruksi.jasaPengawasanDenganKualifikasi.tarif : 0,
        "b5Gross"   : value.jasaKonstruksi ? value.jasaKonstruksi.jasaPengawasanTanpaKualifikasi.bruto : 0,
        "b5Pph"     : value.jasaKonstruksi ? value.jasaKonstruksi.jasaPengawasanTanpaKualifikasi.pph : 0,
        "b5Rates"   : value.jasaKonstruksi ? value.jasaKonstruksi.jasaPengawasanTanpaKualifikasi.tarif : 0,
      },
      "hadiahUndian": {
        "id"        : null,
        "b1Gross"   : value.hadiahUndian ? value.hadiahUndian.b01.bruto : 0,
        "b1Pph"     : value.hadiahUndian ? value.hadiahUndian.b01.pph : 0,
        "b1Rates"   : value.hadiahUndian ? value.hadiahUndian.b01.tarif : 0,
        "b1Type"    : value.hadiahUndian ? value.hadiahUndian.b01.jenisHadiahUndian : 0,
        "b2Gross"   : value.hadiahUndian ? value.hadiahUndian.b02.bruto : 0,
        "b2Pph"     : value.hadiahUndian ? value.hadiahUndian.b02.pph : 0,
        "b2Rates"   : value.hadiahUndian ? value.hadiahUndian.b02.tarif : 0,
        "b2Type"    : value.hadiahUndian ? value.hadiahUndian.b02.jenisHadiahUndian : 0,
        "b3Gross"   : value.hadiahUndian ? value.hadiahUndian.b03.bruto : 0,
        "b3Pph"     : value.hadiahUndian ? value.hadiahUndian.b03.pph : 0,
        "b3Rates"   : value.hadiahUndian ? value.hadiahUndian.b03.tarif : 0,
        "b3Type"    : value.hadiahUndian ? value.hadiahUndian.b03.jenisHadiahUndian : 0,
        "b4Gross"   : value.hadiahUndian ? value.hadiahUndian.b04.bruto : 0,
        "b4Pph"     : value.hadiahUndian ? value.hadiahUndian.b04.pph : 0,
        "b4Rates"   : value.hadiahUndian ? value.hadiahUndian.b04.tarif : 0,
        "b4Type"    : value.hadiahUndian ? value.hadiahUndian.b04.jenisHadiahUndian : 0,
        "b5Gross"   : value.hadiahUndian ? value.hadiahUndian.b05.bruto : 0,
        "b5Pph"     : value.hadiahUndian ? value.hadiahUndian.b05.pph : 0,
        "b5Rates"   : value.hadiahUndian ? value.hadiahUndian.b05.tarif : 0,
        "b5Type"    : value.hadiahUndian ? value.hadiahUndian.b05.jenisHadiahUndian : 0,
        "b6Gross"   : value.hadiahUndian ? value.hadiahUndian.b06.bruto : 0,
        "b6Pph"     : value.hadiahUndian ? value.hadiahUndian.b06.pph : 0,
        "b6Rates"   : value.hadiahUndian ? value.hadiahUndian.b06.tarif : 0,
        "b6Type"    : value.hadiahUndian ? value.hadiahUndian.b06.jenisHadiahUndian : 0,
      },
      "dividenDiterima": {
        "id"      : null,
        "gross"   : value.dividenDiterima ? value.dividenDiterima.bruto : 0,
        "pph"     : value.dividenDiterima ? value.dividenDiterima.pph : 0,
        "rates"   : value.dividenDiterima ? value.dividenDiterima.tarif : 0
      },
      "bungaSimpanan": {
        "id"      : null,
        "gross"   : value.bungaSimpanan ? value.bungaSimpanan.bruto : 0,
        "pph"     : value.bungaSimpanan ? value.bungaSimpanan.pph : 0,
        "rates"   : value.bungaSimpanan ? value.bungaSimpanan.tarif : 0
      },
      "bungaDiskonto": {
        "id"            : null,
        "b1"            : value.bungaDiskonto ? value.bungaDiskonto.namaObligasi : 0,
        "b10"           : value.bungaDiskonto ? value.bungaDiskonto.diskonto : 0,
        "b11"           : value.bungaDiskonto ? value.bungaDiskonto.bunga : 0,
        "b2"            : value.bungaDiskonto ? value.bungaDiskonto.jumlahNilaiNominal : 0,
        "b3"            : value.bungaDiskonto ? value.bungaDiskonto.nomorSeri : 0,
        "b4"            : value.bungaDiskonto ? value.bungaDiskonto.tingkatBunga : 0,
        "b5"            : value.bungaDiskonto ? value.bungaDiskonto.tanggalJatuhTempo : 0,
        "b6"            : value.bungaDiskonto ? value.bungaDiskonto.tanggalPerolehan : 0,
        "b7"            : value.bungaDiskonto ? value.bungaDiskonto.tanggalPenjualan : 0,
        "b8"            : value.bungaDiskonto ? value.bungaDiskonto.jumlahHargaPerolehanBersih : 0,
        "b9"            : value.bungaDiskonto ? value.bungaDiskonto.jumlahHargaJualBersih : 0,
        "holdingPeriod" : value.bungaDiskonto ? value.bungaDiskonto.holdingPeriod : 0,
        "jumlahBunga"   : value.bungaDiskonto ? value.bungaDiskonto.jumlahBunga : 0,
        "tarif"         : value.bungaDiskonto ? value.bungaDiskonto.tarif : 0,
        "timePeriod"    : value.bungaDiskonto ? value.bungaDiskonto.timePeriod : 0,
      },
      "bungaDeposito"   : {
        "id"            : null,
        "b1aGross"      : value.bungaDeposito ? value.bungaDeposito.depositoBerjangkaRupiah.bruto : 0,
        "b1aPph"        : value.bungaDeposito ? value.bungaDeposito.depositoBerjangkaRupiah.pph : 0,
        "b1aRates"      : value.bungaDeposito ? value.bungaDeposito.depositoBerjangkaRupiah.tarif : 0,
        "b1aType"       : value.bungaDeposito ? value.bungaDeposito.depositoBerjangkaRupiah.dnLn : true,
        "b1bGross"      : value.bungaDeposito ? value.bungaDeposito.depositoBerjangkaValutaAsingPremiForward.bruto : 0,
        "b1bPph"        : value.bungaDeposito ? value.bungaDeposito.depositoBerjangkaValutaAsingPremiForward.pph : 0,
        "b1bRates"      : value.bungaDeposito ? value.bungaDeposito.depositoBerjangkaValutaAsingPremiForward.tarif : 0,
        "b1bType"       : value.bungaDeposito ? value.bungaDeposito.depositoBerjangkaValutaAsingPremiForward.dnLn : true,
        "b1cGross"      : value.bungaDeposito ? value.bungaDeposito.depositoBerjangkaValutaAsingTanpaPremiForward.bruto : 0,
        "b1cPph"        : value.bungaDeposito ? value.bungaDeposito.depositoBerjangkaValutaAsingTanpaPremiForward.pph : 0,
        "b1cRates"      : value.bungaDeposito ? value.bungaDeposito.depositoBerjangkaValutaAsingTanpaPremiForward.tarif : 0,
        "b1cType"       : value.bungaDeposito ? value.bungaDeposito.depositoBerjangkaValutaAsingTanpaPremiForward.dnLn : true,
        "b2Gross"       : value.bungaDeposito ? value.bungaDeposito.sertifikatDeposito.bruto : 0,
        "b2Pph"         : value.bungaDeposito ? value.bungaDeposito.sertifikatDeposito.pph : 0,
        "b2Rates"       : value.bungaDeposito ? value.bungaDeposito.sertifikatDeposito.tarif : 0,
        "b2Type"        : value.bungaDeposito ? value.bungaDeposito.sertifikatDeposito.dnLn : true,
        "b3Gross"       : value.bungaDeposito ? value.bungaDeposito.tabungan.bruto : 0,
        "b3Pph"         : value.bungaDeposito ? value.bungaDeposito.tabungan.pph : 0,
        "b3Rates"       : value.bungaDeposito ? value.bungaDeposito.tabungan.tarif : 0,
        "b3Type"        : value.bungaDeposito ? value.bungaDeposito.tabungan.dnLn : true,
        "b4Gross"       : value.bungaDeposito ? value.bungaDeposito.sertifikatBankIndonesia.bruto : 0,
        "b4Pph"         : value.bungaDeposito ? value.bungaDeposito.sertifikatBankIndonesia.pph : 0,
        "b4Rates"       : value.bungaDeposito ? value.bungaDeposito.sertifikatBankIndonesia.tarif : 0,
        "b5Gross"       : value.bungaDeposito ? value.bungaDeposito.jasaGiro.bruto : 0,
        "b5Pph"         : value.bungaDeposito ? value.bungaDeposito.jasaGiro.pph : 0,
        "b5Rates"       : value.bungaDeposito ? value.bungaDeposito.jasaGiro.tarif : 0,
        "b6Description" : value.bungaDeposito ? value.bungaDeposito.no6.jenisPenghasilan : 0,
        "b6Gross"       : value.bungaDeposito ? value.bungaDeposito.no6.bruto : 0,
        "b6Pph"         : value.bungaDeposito ? value.bungaDeposito.no6.pph : 0,
        "b6Rates"       : value.bungaDeposito ? value.bungaDeposito.no6.tarif : 0,
      },
      spt : this.props.spt.data
    }
    // console.log(mapping)
    if(mapping.bungaDeposito){
      if(mapping.bungaDeposito.b1aType == "DN"){
        mapping.bungaDeposito.b1aType = true
      } else {
        mapping.bungaDeposito.b1aType = false
      }
      if(mapping.bungaDeposito.b1bType == "DN"){
        mapping.bungaDeposito.b1bType = true
      } else {
        mapping.bungaDeposito.b1bType = false
      }
      if(mapping.bungaDeposito.b1cType == "DN"){
        mapping.bungaDeposito.b1cType = true
      } else {
        mapping.bungaDeposito.b1cType = false
      }
      if(mapping.bungaDeposito.b2Type == "DN"){
        mapping.bungaDeposito.b2Type = true
      } else {
        mapping.bungaDeposito.b2Type = false
      }
      if(mapping.bungaDeposito.b3Type == "DN"){
        mapping.bungaDeposito.b3Type = true
      } else {
        mapping.bungaDeposito.b3Type = false
      }
    }
    // console.log(mapping, "AFTER")
    if(value.jasaKonstruksi){
      if(value.jasaKonstruksi.jasaPengawasanDenganKualifikasi.opsi == 1){
        mapping.jasaKonstruksi.b4Type = true
      } else {
        mapping.jasaKonstruksi.b4Type = false
      }
      if(value.jasaKonstruksi.jasaPengawasanTanpaKualifikasi.opsi == 1){
        mapping.jasaKonstruksi.b5Type = true
      } else {
        mapping.jasaKonstruksi.b5Type = false
      }
    }
    if(value.id && value.id != "new") mapping.id = value.id
    if(value.consumeId) mapping.consumeId = value.consumeId
    if(typeof mapping.cuttingDate == "object") mapping.cuttingDate = moment(mapping.cuttingDate).format("YYYY-MM-DD")
    mapping.numbering = true

    value = mapping;
    // Remapping Objek

    value.blockBunifikasiMonth = 4
    value.blockBunifikasiYear = 2022;

    try {
      let res = await BlokBunifikasiService.api.getSettingAccess(this.props.match.params.companyId)
      if (res.data.active) {
        value.blockBunifikasiMonth = res.data.bulan
        value.blockBunifikasiYear = res.data.tahun
      }
    } catch(e) {}

    return value;
  }

  async afterSave(res, val) {
    var formData = this.props.formData;

    var data = {
      ...formData.penerimaPenghasilan,
      organization: this.props.spt.data.organization,
      npwpProfile: this.props.spt.data.organization.npwp
    }
    console.log(res, val, formData)
    if(data.id) delete data.id;
    await MasterLawanTransaksi4a2Service.api.findOrCreate(data)
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      // let nomorBPReq = await nomorBP23Service.api.get(this.props.spt.data.organization.id)
      let nomorBPReq = await this.service.api.getNumberBP(this.props.spt.data.id)
      var nomorBP    = "";

      if(nomorBPReq.data.mode === 'PREF_SUF') {
        nomorBP = nomorBPReq.data.prefix+nomorBPReq.data.suffix;
      } else {
        nomorBP = nomorBPReq.data.suffix+nomorBPReq.data.prefix;
      }
      let signer = {};  //await SPT21SignerService.api.getSigner(this.props.spt.data.id);

      // tarif
      var tarif = {};
      try {
        // tarif = await Tarif4a2Service.api.byCompany({
        //   compId: this.props.organization.company.id,
        //   tgl   : moment().format("YYYY-MM-DD")
        // });
        let getTarif = await this.service.api.getTarif()
        tarif = {
          "hadiahUndian"                               : getTarif.data.r1,
          "bungaDeposito"                              : getTarif.data.r2,
          "penjualanSahamPendiri"                      : getTarif.data.r3,
          "penjualanSahamBukanPendiri"                 : getTarif.data.r4,
          "sewaTanahDanBangunan"                       : getTarif.data.r5,
          "penyediaJasaKonstruksiKualifikasi"          : getTarif.data.r6,
          "penyediaJasaKonstruksiTidakPunyaKualifikasi": getTarif.data.r7,
          "penyediaJasaKonstruksiLain"                 : getTarif.data.r8,
          "jasaPerancanganPunyaKualifikasi"            : getTarif.data.r9,
          "jasaPerancanganTidakPunyaKualifikasi"       : getTarif.data.r10,
          "bungaDanDiskontoObligasi"                   : getTarif.data.r11,
          "bungaSimpanan"                              : getTarif.data.r12,
          "penghasilanTransaksiDerivatif"              : getTarif.data.r13,
          "dividenDiterima"                            : getTarif.data.r14,
        }
      } catch (error) {}

      if(tarif) {
        await this.setState({'tarif': tarif});
      }

      if(!tarif) {
        tarif = {
          "hadiahUndian"                               : 25.0,
          "bungaDeposito"                              : 20.0,
          "penjualanSahamPendiri"                      : 0.5,
          "penjualanSahamBukanPendiri"                 : 0.1,
          "sewaTanahDanBangunan"                       : 10.0,
          "penyediaJasaKonstruksiKualifikasi"          : 2.0,
          "penyediaJasaKonstruksiTidakPunyaKualifikasi": 4.0,
          "penyediaJasaKonstruksiLain"                 : 3.0,
          "jasaPerancanganPunyaKualifikasi"            : 4.0,
          "jasaPerancanganTidakPunyaKualifikasi"       : 6.0,
          "bungaDanDiskontoObligasi"                   : 15.0,
          "bungaSimpanan"                              : 10.0,
          "penghasilanTransaksiDerivatif"              : 0.0,
          "dividenDiterima"                            : 10.0,
        }
      }
      this.initialData = {
        ...this.initialData,
        no             : nomorBP,                        //.data.no,
        namaPemotong   : this.props.organization.name,
        npwpPemotong   : this.props.organization.npwp,
        spt            : this.props.spt.data,
        pph            : 0,
        tanggal        : new Date(),
        bruto          : 0,
        terbilang      : "",
        "bungaDeposito": {
          "depositoBerjangkaRupiah": {
            "jenisPenghasilan": "",
            "bruto"           : 0,
            "tarif"           : tarif.bungaDeposito,
            "pph"             : 0,
            "dnLn"            : "DN"
          },
          "depositoBerjangkaValutaAsingPremiForward": {
            "jenisPenghasilan": "",
            "bruto"           : 0,
            "tarif"           : tarif.bungaDeposito,
            "pph"             : 0,
            "dnLn"            : "DN"
          },
          "depositoBerjangkaValutaAsingTanpaPremiForward": {
            "jenisPenghasilan": "",
            "bruto"           : 0,
            "tarif"           : tarif.bungaDeposito,
            "pph"             : 0,
            "dnLn"            : "DN"
          },
          "sertifikatDeposito": {
            "jenisPenghasilan": "",
            "bruto"           : 0,
            "tarif"           : tarif.bungaDeposito,
            "pph"             : 0,
            "dnLn"            : "DN"
          },
          "tabungan": {
            "jenisPenghasilan": "",
            "bruto"           : 0,
            "tarif"           : tarif.bungaDeposito,
            "pph"             : 0,
            "dnLn"            : "DN"
          },
          "sertifikatBankIndonesia": {
            "jenisPenghasilan": "",
            "bruto"           : 0,
            "tarif"           : tarif.bungaDeposito,
            "pph"             : 0,
            "dnLn"            : "DN"
          },
          "jasaGiro": {
            "jenisPenghasilan": "",
            "bruto"           : 0,
            "tarif"           : tarif.bungaDeposito,
            "pph"             : 0,
            "dnLn"            : "DN"
          },
          "no6": {
            "jenisPenghasilan": "",
            "bruto"           : 0,
            "tarif"           : tarif.bungaDeposito,
            "pph"             : 0,
            "dnLn"            : "DN"
          }
        },

        "bungaDiskonto": {
          "namaObligasi"              : "",
          "jumlahNilaiNominal"        : 0,
          "nomorSeri"                 : "",
          "tingkatBunga"              : 0,
          "tanggalJatuhTempo"         : new Date(),
          "tanggalPerolehan"          : new Date(),
          "tanggalPenjualan"          : moment(this.props.spt.data.year+'-'+this.props.spt.data.month+'-01', "YYYY-MM-DD").toDate(),
          "jumlahHargaPerolehanBersih": 0,
          "jumlahHargaJualBersih"     : 0,
          "diskonto"                  : 0,
          "bunga"                     : 0,
          "tarif"                     : tarif.bungaDanDiskontoObligasi,
          "holdingPeriod"             : 0,
          "timePeriod"                : 0,
          "jumlahBunga"               : 0
        },

        "bungaSimpanan": {
          "bruto": 0,
          "tarif": tarif.bungaSimpanan,
          "pph"  : 0
        },

        "dividenDiterima": {
          "bruto": 0,
          "tarif": tarif.dividenDiterima,
          "pph"  : 0
        },

        "hadiahUndian": {
          "b01": {
            "jenisHadiahUndian": "",
            "bruto"            : 0,
            "tarif"            : tarif.hadiahUndian,
            "pph"              : 0
          },
          "b02": {
            "jenisHadiahUndian": "",
            "bruto"            : 0,
            "tarif"            : tarif.hadiahUndian,
            "pph"              : 0
          },
          "b03": {
            "jenisHadiahUndian": "",
            "bruto"            : 0,
            "tarif"            : tarif.hadiahUndian,
            "pph"              : 0
          },
          "b04": {
            "jenisHadiahUndian": "",
            "bruto"            : 0,
            "tarif"            : tarif.hadiahUndian,
            "pph"              : 0
          },
          "b05": {
            "jenisHadiahUndian": "",
            "bruto"            : 0,
            "tarif"            : tarif.hadiahUndian,
            "pph"              : 0
          },
          "b06": {
            "jenisHadiahUndian": "",
            "bruto"            : 0,
            "tarif"            : tarif.hadiahUndian,
            "pph"              : 0
          }
        },


        "jasaKonstruksi": {
          "jasaPelaksanaanDenganKualifikasi": {
            "bruto": 0,
            "tarif": tarif.penyediaJasaKonstruksiKualifikasi,
            "pph"  : 0
          },
          "jasaPelaksanaanTanpaKualifikasi": {
            "bruto": 0,
            "tarif": tarif.penyediaJasaKonstruksiTidakPunyaKualifikasi,
            "pph"  : 0
          },
          "jasaPelaksanaanSelainNo1Dan2": {
            "bruto": 0,
            "tarif": tarif.penyediaJasaKonstruksiLain,
            "pph"  : 0
          },
          "jasaPengawasanDenganKualifikasi": {
            "opsi" : 1,
            "bruto": 0,
            "tarif": tarif.jasaPerancanganPunyaKualifikasi,
            "pph"  : 0
          },
          "jasaPengawasanTanpaKualifikasi": {
            "opsi" : 1,
            "bruto": 0,
            "tarif": tarif.jasaPerancanganTidakPunyaKualifikasi,
            "pph"  : 0
          }
        },

        "transaksiDerivatif": {
          "nilaiMarginAwal": 0,
          "tarif"          : tarif.penghasilanTransaksiDerivatif,
          "pph"            : 0
        },

        "penjualanSaham": {
          "sahamPendiri": {
            "nilaiTransaksiPenjualan": 0,
            "tarif"                  : tarif.penjualanSahamPendiri,
            "pph"                    : 0
          },
          "bukanSahamPendiri": {
            "nilaiTransaksiPenjualan": 0,
            "tarif"                  : tarif.penjualanSahamBukanPendiri,
            "pph"                    : 0
          }
        },

        "persewaanTanah": {
          "bruto" : 0,
          "tarif" : tarif.sewaTanahDanBangunan,
          "pph"   : 0,
          "lokasi": ""
        },

        "peredaranTertentu": {
          "ntpn" : "",
          "tanggal" : "",
          "jmlBruto" : 0,
          "pph"   : 0,
        },

      }
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);

      // Remapping Objek
      var data = res.data
      var remapping = {
        id            : data.id,
        numbering     : data.numbering,
        consumeId     : data.consumeId,
        no            : data.no,
        tanggal       : data.cuttingDate,
        bruto         : data.gross,
        pph           : data.pph,
        ref     : data.ref,
        npwpPemotong  : data.signNpwp,
        namaPemotong  : data.signName,
        type          : data.type,
        terbilang     : data.words,
        penerimaPenghasilan: {
          alamat    : data.address,
          email     : data.email,
          nama      : data.name,
          npwp      : data.npwp,
          telepon   : data.phone,
          kodePos   : data.postal_code,
        },
        transaksiDerivatif: {
          id              : data.transaksiDerivatif.id,
          nilaiMarginAwal : data.transaksiDerivatif.gross,
          pph             : data.transaksiDerivatif.pph,
          tarif           : data.transaksiDerivatif.rates,
        },
        persewaanTanah: {
          id              : data.persewaanTanah.id,
          bruto           : data.persewaanTanah.b1Gross,
          pph             : data.persewaanTanah.b1Pph,
          tarif           : data.persewaanTanah.b1Rates,
          lokasi          : data.persewaanTanah.lokasi,
          nop             : data.persewaanTanah.nop,
        },
        peredaranTertentu: {
          id              : data.peredaranBruto.id,
          bruto           : data.peredaranBruto.b1Gross,
          pph             : data.peredaranBruto.b1Pph,
          tarif           : data.peredaranBruto.b1Rates,
        },
        penjualanSaham: {
          id              : data.penjualanSaham.id,
          sahamPendiri    : {
            nilaiTransaksiPenjualan           : data.penjualanSaham.b1Gross,
            pph             : data.penjualanSaham.b1Pph,
            tarif           : data.penjualanSaham.b1Rates,
          },
          bukanSahamPendiri: {
            nilaiTransaksiPenjualan           : data.penjualanSaham.b1Gross,
            pph             : data.penjualanSaham.b1Pph,
            tarif           : data.penjualanSaham.b1Rates,
          }
        },
        jasaKonstruksi: {
          id                : data.jasaKonstruksi.id,
          jasaPelaksanaanDenganKualifikasi: {
            bruto           : data.jasaKonstruksi.b1Gross,
            pph             : data.jasaKonstruksi.b1Pph,
            tarif           : data.jasaKonstruksi.b1Rates,
          },
          jasaPelaksanaanTanpaKualifikasi: {
            bruto           : data.jasaKonstruksi.b2Gross,
            pph             : data.jasaKonstruksi.b2Pph,
            tarif           : data.jasaKonstruksi.b2Rates,
          },
          jasaPelaksanaanSelainNo1Dan2: {
            bruto           : data.jasaKonstruksi.b3Gross,
            pph             : data.jasaKonstruksi.b3Pph,
            tarif           : data.jasaKonstruksi.b3Rates,
          },
          jasaPengawasanDenganKualifikasi: {
            opsi            : data.jasaKonstruksi.b4Type ? '1':'2',
            bruto           : data.jasaKonstruksi.b4Gross,
            pph             : data.jasaKonstruksi.b4Pph,
            tarif           : data.jasaKonstruksi.b4Rates,
          },
          jasaPengawasanTanpaKualifikasi: {
            opsi            : data.jasaKonstruksi.b5Type ? '1':'2',
            bruto           : data.jasaKonstruksi.b5Gross,
            pph             : data.jasaKonstruksi.b5Pph,
            tarif           : data.jasaKonstruksi.b5Rates,
          }
        },
        hadiahUndian: {
          id                  : data.hadiahUndian.id,
          b01: {
            bruto             : data.hadiahUndian.b1Gross,
            pph               : data.hadiahUndian.b1Pph,
            tarif             : data.hadiahUndian.b1Rates,
            jenisHadiahUndian : data.hadiahUndian.b1Type
          },
          b02: {
            bruto             : data.hadiahUndian.b2Gross,
            pph               : data.hadiahUndian.b2Pph,
            tarif             : data.hadiahUndian.b2Rates,
            jenisHadiahUndian : data.hadiahUndian.b2Type
          },
          b03: {
            bruto             : data.hadiahUndian.b3Gross,
            pph               : data.hadiahUndian.b3Pph,
            tarif             : data.hadiahUndian.b3Rates,
            jenisHadiahUndian : data.hadiahUndian.b3Type
          },
          b04: {
            bruto             : data.hadiahUndian.b4Gross,
            pph               : data.hadiahUndian.b4Pph,
            tarif             : data.hadiahUndian.b4Rates,
            jenisHadiahUndian : data.hadiahUndian.b4Type
          },
          b05: {
            bruto             : data.hadiahUndian.b5Gross,
            pph               : data.hadiahUndian.b5Pph,
            tarif             : data.hadiahUndian.b5Rates,
            jenisHadiahUndian : data.hadiahUndian.b5Type
          },
          b06: {
            bruto             : data.hadiahUndian.b6Gross,
            pph               : data.hadiahUndian.b6Pph,
            tarif             : data.hadiahUndian.b6Rates,
            jenisHadiahUndian : data.hadiahUndian.b6Type
          }
        },
        dividenDiterima : {
          id                : data.dividenDiterima.id,
          bruto             : data.dividenDiterima.gross,
          pph               : data.dividenDiterima.pph,
          tarif             : data.dividenDiterima.rates,
        },
        bungaSimpanan : {
          id                : data.bungaSimpanan.id,
          bruto             : data.bungaSimpanan.gross,
          pph               : data.bungaSimpanan.pph,
          tarif             : data.bungaSimpanan.rates,
        },
        bungaDiskonto: {
          id                          : data.bungaDiskonto.id,
          namaObligasi                : data.bungaDiskonto.b1,
          jumlahNilaiNominal          : data.bungaDiskonto.b2,
          nomorSeri                   : data.bungaDiskonto.b3,
          tingkatBunga                : data.bungaDiskonto.b4,
          tanggalJatuhTempo           : data.bungaDiskonto.b5,
          tanggalPerolehan            : data.bungaDiskonto.b6,
          tanggalPenjualan            : data.bungaDiskonto.b7,
          jumlahHargaPerolehanBersih  : data.bungaDiskonto.b8,
          jumlahHargaJualBersih       : data.bungaDiskonto.b9,
          diskonto                    : data.bungaDiskonto.b10,
          bunga                       : data.bungaDiskonto.b11,
          holdingPeriod               : data.bungaDiskonto.holdingPeriod,
          jumlahBunga                 : data.bungaDiskonto.jumlahBunga,
          tarif                       : data.bungaDiskonto.tarif,
          timePeriod                  : data.bungaDiskonto.timePeriod,
        },
        bungaDeposito: {
          id: data.bungaDeposito.id,
          depositoBerjangkaRupiah: {
            bruto             : data.bungaDeposito.b1aGross,
            pph               : data.bungaDeposito.b1aPph,
            tarif             : data.bungaDeposito.b1aRates,
            dnLn              : data.bungaDeposito.b1aType ? 'DN' : 'LN',
          },
          depositoBerjangkaValutaAsingPremiForward: {
            bruto             : data.bungaDeposito.b1bGross,
            pph               : data.bungaDeposito.b1bPph,
            tarif             : data.bungaDeposito.b1bRates,
            dnLn              : data.bungaDeposito.b1bType ? 'DN' : 'LN',
          },
          depositoBerjangkaValutaAsingTanpaPremiForward: {
            bruto             : data.bungaDeposito.b1cGross,
            pph               : data.bungaDeposito.b1cPph,
            tarif             : data.bungaDeposito.b1cRates,
            dnLn              : data.bungaDeposito.b1cType ? 'DN' : 'LN',
          },
          sertifikatDeposito: {
            bruto             : data.bungaDeposito.b2Gross,
            pph               : data.bungaDeposito.b2Pph,
            tarif             : data.bungaDeposito.b2Rates,
            dnLn              : data.bungaDeposito.b2Type ? 'DN' : 'LN',
          },
          tabungan: {
            bruto             : data.bungaDeposito.b3Gross,
            pph               : data.bungaDeposito.b3Pph,
            tarif             : data.bungaDeposito.b3Rates,
            dnLn              : data.bungaDeposito.b3Type ? 'DN' : 'LN',
          },
          sertifikatBankIndonesia: {
            bruto             : data.bungaDeposito.b4Gross,
            pph               : data.bungaDeposito.b4Pph,
            tarif             : data.bungaDeposito.b4Rates,
          },
          jasaGiro: {
            bruto             : data.bungaDeposito.b5Gross,
            pph               : data.bungaDeposito.b5Pph,
            tarif             : data.bungaDeposito.b5Rates,
          },
          no6: {
            bruto             : data.bungaDeposito.b6Gross,
            pph               : data.bungaDeposito.b6Pph,
            tarif             : data.bungaDeposito.b6Rates,
            jenisPenghasilan  : data.bungaDeposito.b6Description,
          },
        },
        spt : this.props.spt.data
      }

      res.data = remapping;
      // Remapping Objek

      this.props.initialize(res.data);
      // this.npwpValidation(null, res.data.bagA.npwp)
      this.setState({npwpNotValid: false})
    }
  }


  async changeTarif(d, e) {
    var tarif = await Tarif4a2Service.api.byCompany({
      compId: this.props.organization.company.id,
      tgl   : moment(d).format("YYYY-MM-DD")
    })

    if(tarif) {
      await this.setState({'tarif': tarif});
    }

    var form = {...this.props.formData};

    if(form.bungaDeposito) {
      form.bungaDeposito.depositoBerjangkaRupiah.tarif                       = this.state.tarif.bungaDeposito;
      form.bungaDeposito.depositoBerjangkaValutaAsingPremiForward.tarif      = this.state.tarif.bungaDeposito;
      form.bungaDeposito.depositoBerjangkaValutaAsingTanpaPremiForward.tarif = this.state.tarif.bungaDeposito;
      form.bungaDeposito.sertifikatDeposito.tarif                            = this.state.tarif.bungaDeposito;
      form.bungaDeposito.tabungan.tarif                                      = this.state.tarif.bungaDeposito;
      form.bungaDeposito.sertifikatBankIndonesia.tarif                       = this.state.tarif.bungaDeposito;
      form.bungaDeposito.jasaGiro.tarif                                      = this.state.tarif.bungaDeposito;
      form.bungaDeposito.no6.tarif                                           = this.state.tarif.bungaDeposito;
    }
    if(form.bungaDiskonto) {
      form.bungaDiskonto.tarif = this.state.tarif.bungaDanDiskontoObligasi;
    }
    if(form.bungaSimpanan) {
      form.bungaSimpanan.tarif = this.state.tarif.bungaSimpanan;
    }
    if(form.dividenDiterima) {
      form.dividenDiterima.tarif = this.state.tarif.dividenDiterima;
    }
    if(form.hadiahUndian) {
      form.hadiahUndian.b01.tarif = this.state.tarif.hadiahUndian;
      form.hadiahUndian.b02.tarif = this.state.tarif.hadiahUndian;
      form.hadiahUndian.b03.tarif = this.state.tarif.hadiahUndian;
      form.hadiahUndian.b04.tarif = this.state.tarif.hadiahUndian;
      form.hadiahUndian.b05.tarif = this.state.tarif.hadiahUndian;
      form.hadiahUndian.b06.tarif = this.state.tarif.hadiahUndian;
    }
    if(form.jasaKonstruksi) {
      form.jasaKonstruksi.jasaPelaksanaanDenganKualifikasi.tarif = this.state.tarif.penyediaJasaKonstruksiKualifikasi;
      form.jasaKonstruksi.jasaPelaksanaanTanpaKualifikasi.tarif  = this.state.tarif.penyediaJasaKonstruksiTidakPunyaKualifikasi;
      form.jasaKonstruksi.jasaPelaksanaanSelainNo1Dan2.tarif     = this.state.tarif.penyediaJasaKonstruksiLain;
      form.jasaKonstruksi.jasaPengawasanDenganKualifikasi.tarif  = this.state.tarif.jasaPerancanganPunyaKualifikasi;
      form.jasaKonstruksi.jasaPengawasanTanpaKualifikasi.tarif   = this.state.tarif.jasaPerancanganTidakPunyaKualifikasi;
    }
    if(form.transaksiDerivatif) {
      form.transaksiDerivatif.tarif = this.state.tarif.penghasilanTransaksiDerivatif
    }
    if(form.penjualanSaham) {
      form.penjualanSaham.sahamPendiri.tarif      = this.state.tarif.penjualanSahamPendiri;
      form.penjualanSaham.bukanSahamPendiri.tarif = this.state.tarif.penjualanSahamBukanPendiri;
    }
    if(form.persewaanTanah) {
      form.persewaanTanah.tarif = this.state.tarif.sewaTanahDanBangunan
    }

    await this.props.initialize(form);

    await this.reCalculate()
  }

  async calculate(e,v,pv,f, parent) {
    var form = this.props.formData;
    var d    = form.bagB[parent];

    var bruto = parseFloat(d.bruto);
    var tarif = parseFloat(d.tarif);

    if(f.search('bruto') >= 0) {
      bruto = parseFloat(v)
    } else if(f.search('tarif') >= 0) {
      tarif = parseFloat(v)
    }

    if(!bruto) bruto = 0;
    if(!tarif) tarif = 0;

    var nonNpwp                  = 1;
    if  (d.nonNpwp == 1) nonNpwp = 2;

    var pph = Math.floor(bruto * tarif * nonNpwp / 100)

    await this.props.change(`bagB.${parent}.pph`, pph);
    this.calculateTotal()
  }

  async calculateTotal() {
    var form = this.props.formData;
    var d    = form.bagB;

    var jmlPph   = 0;
    var jmlBruto = 0;
    Object.keys(d).forEach((k, i) => {
      if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang')) {
        var x = d[k];

        jmlPph   = jmlPph+ parseFloat(x.pph);
        jmlBruto = jmlBruto+ parseFloat(x.bruto)
      }
    })

    jmlBruto = Math.floor(jmlBruto)
    jmlPph = Math.floor(jmlPph)

    this.props.change('bagB.jmlBruto', jmlBruto)
    this.props.change('bagB.jmlPph', jmlPph);

    this.props.change('bagB.terbilang', terbilangService(parseInt(jmlPph)));

  }

  // async calculate(e,v,pv,f, parent) {
  //   var form = this.props.formData;
  //   var d    = form.bagB[parent];

  //   var bruto = parseFloat(d.bruto);
  //   var tarif = parseFloat(d.tarif);

  //   if(f.search('bruto') >= 0) {
  //     bruto = parseFloat(v)
  //   } else if(f.search('tarif') >= 0) {
  //     tarif = parseFloat(v)
  //   }

  //   if(!bruto) bruto = 0;
  //   if(!tarif) tarif = 0;

  //   var nonNpwp                  = 1;
  //   if  (d.nonNpwp == 1) nonNpwp = 2;

  //   await this.props.change(`bagB.${parent}.pph`, bruto*tarif*nonNpwp/100);
  //   this.calculateTotal()
  // }

  // async calculateTotal() {
  //   var form = this.props.formData;
  //   var d    = form.bagB;

  //   var jmlPph   = 0;
  //   var jmlBruto = 0;
  //   Object.keys(d).forEach((k, i) => {
  //     if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang')) {
  //       var x = d[k];

  //       jmlPph   = jmlPph+ parseFloat(x.pph);
  //       jmlBruto = jmlBruto+ parseFloat(x.bruto)
  //     }
  //   })

  //   this.props.change('bagB.jmlBruto', jmlBruto)
  //   this.props.change('bagB.jmlPph', jmlPph);

  //   this.props.change('bagB.terbilang', terbilangService(parseInt(jmlPph)));

  // }

  async reCalculate() {

  }

  jenisFormSwithcer() {
    switch (this.props.formData.type) {
      case 'HADIAH_UNDIAN':
        return <BP4a2HadiahForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      case 'BUNGA_DEPOSITO':
        return <BP4a2BungaDepositoForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      case 'BUNGA_DISKONTO':
        return <BP4a2BungaDiskontoForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      case 'BUNGA_SIMPANAN':
        return <BP4a2BungaSimpananKoperasiForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />;
      case 'DIVIDEN_DITERIMA':
        return <BP4a2DividenForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      case 'JASA_KONSTRUKSI':
        return <BP4a2JasaKonstruksiForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      case 'TRANSAKSI_DERIVATIF':
        return <BP4a2TransaksiDeviratifForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      case 'PENJUALAN_SAHAM':
        return <BP4a2PenjualanSahamForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      case 'PERSEWAAN_TANAH':
        return <BP4a2PersewaanTanahForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      case 'WP_PEREDARAN_TERTENTU':
        return <BP4a2WpPeredaranTertentuForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      default:
        break;
    }
  }

  formView() {
    var formData                                                     = {bagB: {}};
    if  (this.props.formData && this.props.formData.values) formData = this.props.formData.values

    var companyId                                                              = '';
    if  (this.props.organization && this.props.organization.company) companyId = this.props.organization.company.id;
    if  (formData.spt && formData.spt.organization) companyId                  = formData.spt.organization.company.id

    return (
      <div>
        <LawanTransaksiDialog
          spt      = {this.props.spt.data}
          onSelect = {(d)=> {
            var formData = {...this.props.formData};

            formData.penerimaPenghasilan = {
              "npwp"   : d.npwp,
              "nik"    : d.nik,
              "nama"   : d.name,
              "alamat" : d.address,
              "telepon": d.phone,
              "kodePos": d.postalCode,
              "email"  : d.email
            };

            this.setState({npwpNotValid: false})

            this.props.initialize(formData);

          }}
          service = {MasterLawanTransaksi4a2Service}
          visible = {this.state.lkDialog}
          onHide  = {()=> this.setState({lkDialog: false})}
        />
        <Card>
          <div className="md-grid">
            <Field
              label     = 'No.Bukti Potong/NTPN/PBK'
              name      = 'no'
              className = "md-cell md-cell--6"
              component = {Textfield}
              validate  = {validation.required}
            />

            <Field
              label        = 'Tanggal Bukti Potong'
              name         = 'tanggal'
              className    = "md-cell md-cell--6"
              onDateChange = {async (d, e) => this.changeTarif(d)}
              component    = {Datepicker}
              validate     = {validation.required}
            />

            <Field
              label     = 'Referensi'
              name      = 'ref'
              id        = "BP21FForm-ref"
              className = "md-cell md-cell--12"
              component = {Textfield}
            />
          </div>
        </Card>

        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. IDENTITAS WAJIB PAJAK YANG DIPOTONG ' />
          <Divider/>
          <div className="md-grid">
            <div className='md-cell md-cell--6'>
            <div className='mpk-layout'>
              <Field
                label      = 'NPWP'
                className  = 'flex'
                name       = 'penerimaPenghasilan.npwp'
                component  = {TextfieldMask}
                maskFormat = "##.###.###.#-###-###"
                mask       = "_"
                length     = {15}
                onChange   = {this.npwpValidation.bind(this)}
                validate   = {validation.required}
              />
              <Button icon primary swapTheming style={{marginTop: 10}} onClick={()=> this.setState({lkDialog: true})}>search</Button>
              </div>
              <Field
                label     = 'Nama'
                name      = 'penerimaPenghasilan.nama'
                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
                validate  = {validation.required}
              />
              {/* <Field
                label     = 'NIK'
                name      = 'penerimaPenghasilan.nik'
                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
              /> */}
              <Field
                label     = 'Telepon'
                name      = 'penerimaPenghasilan.telepon'
                id        = "BP21FForm-name"
                component = {Textfield}
                />

            </div>
            <div className='md-cell md-cell--6'>
              <Field
                label     = 'Kode Pos'
                name      = 'penerimaPenghasilan.kodePos'
                id        = "BP21FForm-codePos"
                component = {Textfield}
              />
              <Field
                label = 'Alamat'
                name  = 'penerimaPenghasilan.alamat'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
                validate  = {validation.required}
              />
              <Field
                label = 'Email'
                name  = 'penerimaPenghasilan.email'

                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
              />
            </div>
          </div>
        </Card>

        <br/>

        {this.state.showCalculateProgress && <LinearProgress id='lin_pro' style={{margin: 0}} />}
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. DETAIL DIPOTONG' />
          <Divider/>
          <div>
           <Field
              label     = 'Jenis Bukti Potong'
              className = "md-cell md-cell--12"
              name      = 'type'
              component = {Searchfield}
              options   = {this.typeOptions}
              validate  = {validation.required}
            />

            <br/>
          </div>


          {this.jenisFormSwithcer()}
        </Card>

        <br/>

        <Card>
          <div className='md-grid'>
            <Field
              label     = 'Jumlah Bruto'
              name      = 'bruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              disabled
              money = {','}
            />
            <Field
              label     = 'Jumlah PPh'
              name      = 'pph'
              component = {TextfieldMask}
              className = 'md-text-right'
              disabled
              money = {','}
            />
            <Field
              label     = 'Terbilang'
              name      = 'terbilang'
              component = {Textfield}
              disabled
            />
          </div>
        </Card>

        <br />

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='C. IDENTITAS PEMOTONG ' />
          <Divider/>

          <div className="md-grid">
          <Field
            label     = 'NPWP'
            name      = 'npwpPemotong'
            className = "md-cell md-cell--6"
            disabled
            component  = {TextfieldMask}
            maskFormat = "##.###.###.#-###-###"
            mask       = "_"
            validate   = {validation.required}
          />
          <Field
            label     = 'Nama'
            name      = 'namaPemotong'
            className = "md-cell md-cell--6"
            disabled
            component = {Textfield}
            validate  = {validation.required}
          />


          </div>
        </Card>

      </div>
    )
  }

}
