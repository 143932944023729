import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues } from 'redux-form';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  Tab,
  Tabs,
  SelectionControlGroup,
  Checkbox
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import SSP23Service from './SSP23.service';
import {validation, Textfield, Searchfield, Switch, Datepicker, TextfieldMask, convert} from '../../../../../components/form';
import { SearchField } from 'react-mpk';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import ColumnService from '../../../../../services/column.service';

import Master23KapService from '../master/Kap/Master23Kap.service';
import Master23KjsService from '../master/Kjs/Master23Kjs.service';
import SPT2326Service from '../SPT/23_26.spt.service';
import BlokBunifikasiService from '../../21_26/BlokBunifikasi/BlokBunifikasi.service';

@reduxForm({form: 'SSP23Form', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt: state.authEppt.currentSPT,
  auth: state.auth.company,
  formData: getFormValues('SSP23Form')(state)
}))
export default class SSP23Form extends FormViewSpt {
  service=SSP23Service
  viewType =2;

  constructor(props){
    super(props)
    this.state = {
      ...this.state,
      kjs: [],
      formData: {
        ssp23s:[],
        ssp26s:[],
        jumlah23: 0,
        jumlah26: 0,
        jumlahTerbayar23: 0,
        jumlahTerbayar26: 0
      }
    }
  }

  async initData() {
    if(this.props.match.params.id == 'new') {
      await this.getSptInfo()
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      res.data.kap = res.data.kjs.kap
      await this.getSptInfo()
      this.props.initialize(res.data);
    }
  }

  async getSptInfo() {

    var res = await SSP23Service.api.getSptInfo(this.props.match.params.sptId);
    var resData = {
      jumlah23: res.data.amountBp23,
      jumlah26: res.data.amountBp26,
      jumlahTerbayar23: res.data.paidOfBp23,
      jumlahTerbayar26: res.data.paidOfBp26,
      ssp23s: res.data.ssp23s,
      ssp26s: res.data.ssp26s
    }
    // res.data.ssp23s.forEach(function(ssp23s) {
    //   resData.jumlah23 += ssp23s.amount;
    //   resData.jumlahTerbayar23 += ssp23s.paid;
    // });
    // res.data.ssp26s.forEach(function(ssp26s) {
    //   resData.jumlah26 += ssp26s.amount;
    //   resData.jumlahTerbayar26 += ssp26s.paid;
    // });
    this.setState({formData: resData})
  }

  async beforeSave(value) {
    value.blockBunifikasiMonth = 4
    value.blockBunifikasiYear = 2022
    try {
      let res = await BlokBunifikasiService.api.getSettingAccess(this.props.match.params.companyId)
      if(res.data.active){
        value.blockBunifikasiMonth = res.data.bulan
        value.blockBunifikasiYear = res.data.tahun
      }
    } catch(e) {}

    return value;
  } 

  async handleSave(values) {
    var value = await this.beforeSave(values);
    try {
      this.setState({onProgress: true})
      if(value.id) {
        await this.service.api.update(value)
      } else {
        value.spt = { id: this.props.spt.data.id }
        value.sspEbilling = null
        await this.service.api.save(value)
      }
      this.setState({onProgress: false})

      var pathArray = this.props.location.pathname.split('/');
      var path = '';
      pathArray.forEach((d, i)=> {
        if(pathArray.length-1 != i) {
          path = path+d;
          if(i != pathArray.length-2) path = path+'/'
        }
      })

      this.props.history.push(path)
    } catch(e) {
      var msg = e.message;
      if(e.response) msg = e.response.data.message;
      this.setState({
        isError: true,
        onProgress: false,
        errorMessage: msg
      })
    }
  }

  async findKjs(evt, value){
    var kapId = value.id;
    var kjs = await Master23KjsService.api.findByKap(kapId);
    // var _kjs = _.cloneDeep(kjs.data);
    // var _kjsKapId = _kjs.filter((i) => {
    //   return i.kap.id == kapId
    // })
    this.setState({ kjs: kjs })
  }

  formView() {
    return (
      <div>
        <div className='mpk-layout row'>
          <br/>
          <Card className='flex' style={{marginBottom: 32, marginRight: 10}}>
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle='DAFTAR SSP PASAL 23'/>
            <Divider/>
              <DataTable plain baseId="simple-selectable-table" indeterminate>
                  <TableHeader>
                  <TableRow>
                      <TableColumn>Uraian</TableColumn>
                      <TableColumn>PPH Terutang</TableColumn>
                      <TableColumn>SSP yang disetor</TableColumn>
                      {/*<TableColumn>Request ID Biling</TableColumn>*/}
                  </TableRow>
                  </TableHeader>
                  <TableBody>
                    {this.state.formData.ssp23s.map((d) => {
                      return <TableRow>
                        <TableColumn>{d.kapKjs}</TableColumn>
                        <TableColumn className='md-text-right'>
                          { ColumnService.money(d.amount)  }
                        </TableColumn>
                        <TableColumn className='md-text-right'>
                          { ColumnService.money(d.paid)  }
                        </TableColumn>
                        {/*<TableColumn>-</TableColumn>*/}
                      </TableRow>
                    })}

                    <TableRow>
                      <TableColumn><strong>TOTAL</strong></TableColumn>
                      <TableColumn className='md-text-right'>
                        <strong>{ ColumnService.money(this.state.formData.jumlah23)  }</strong>
                      </TableColumn>
                      <TableColumn className='md-text-right'>
                        <strong>{ ColumnService.money(this.state.formData.jumlahTerbayar23)  }</strong>
                      </TableColumn>
                      {/*<TableColumn>-</TableColumn>*/}
                    </TableRow>
                  </TableBody>
              </DataTable>
          </Card>
          <Card className='flex' style={{marginBottom: 32, marginLeft: 10}}>
            <CardTitle title='' style={{padding: '10px 16px'}} subtitle='DAFTAR SSP PASAL 26'/>
            <Divider/>
              <DataTable plain baseId="simple-selectable-table" indeterminate>
                  <TableHeader>
                  <TableRow>
                      <TableColumn>Uraian</TableColumn>
                      <TableColumn>PPH Terutang</TableColumn>
                      <TableColumn>SSP yang disetor</TableColumn>
                      {/*<TableColumn>Request ID Biling</TableColumn>*/}
                  </TableRow>
                  </TableHeader>
                  <TableBody>
                    {this.state.formData.ssp26s.map((d) => {
                      return <TableRow>
                        <TableColumn>{d.kapKjs}</TableColumn>
                        <TableColumn className='md-text-right'>
                          { ColumnService.money(d.amount)  }
                        </TableColumn>
                        <TableColumn className='md-text-right'>
                          { ColumnService.money(d.paid)  }
                        </TableColumn>
                        {/*<TableColumn>-</TableColumn>*/}
                      </TableRow>
                    })}

                    <TableRow>
                      <TableColumn><strong>TOTAL</strong></TableColumn>
                      <TableColumn className='md-text-right'>
                        <strong>{ ColumnService.money(this.state.formData.jumlah26)  }</strong>
                      </TableColumn>
                      <TableColumn className='md-text-right'>
                        <strong>{ ColumnService.money(this.state.formData.jumlahTerbayar26)  }</strong>
                      </TableColumn>
                      {/*<TableColumn>-</TableColumn>*/}
                    </TableRow>
                  </TableBody>
              </DataTable>
          </Card>
        </div>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='PPH TERUTANG YANG TELAH DIBAYAR' />
          <Divider/>

          <div className='md-grid'>
            <div className="md-cell md-cell--6">
              <Field
                label='Kode Jenis Pajak'
                name='kap'
                component={Searchfield}
                service={Master23KapService}
                validate={validation.required}
                onChange={this.findKjs.bind(this)}
                viewField="code"
                valueField="parent"

                itemTemplate={function(d) {
                  return {
                    primaryText: `${d.code}`,
                    onClick: () => {
                      if(!this.props.disabled){
                        this.handleItemClick(d); this.hide()
                      }
                    }
                  }
                }}
              />
              <Field
                label='Kode Jenis Setoran'
                name='kjs'
                component={Searchfield}
                validate={validation.required}
                rerender={true}
                options={this.state.kjs}
                viewField="code"
                valueField="parent"

                itemTemplate={function(d) {
                  return {
                    primaryText: `${d.code} - ${d.name}`,
                    onClick: () => {
                      if(!this.props.disabled){
                        this.handleItemClick(d); this.hide()
                      }
                    }
                  }
                }}
              />
              <Field
                label='Tanggal'
                name='date'
                component={Datepicker}
                validate={validation.required}
              />
            </div>

            <div className="md-cell md-cell--6">
                <Field
                    label='NPTN'
                    name='ntpn'
                    component={Textfield}
                    mask="_"
                    length={16}
                    maxLength={16}
                    maskFormat="################"
                    validate={validation.required}
                />
                <Field
                    label='Jumlah Disetor'
                    name='total'
                    component={TextfieldMask}
                    money={true}
                    normalize={convert.money}
                    validate={validation.required}
                />
            </div>
          </div>
        </Card>
        <br/>
      </div>
    )
  }

}
