import React from 'react';
import { LoaderInfo, rdxConnect, authService } from 'react-mpk';
import { connect } from 'react-redux';
import Bluebird from 'bluebird';
import NpwpService from '../pph/NpwpService';
import http from './../../../services/http.service'

@connect((state) => ({
    access: state.entity.access.api.find,
    organization: state.authEppt.currentOrganization,
    // EPPT CORE
    user: state.auth.user,
    auth: state.auth.company
    // EPPT CORE
}))
class Spt1771 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            accessKey: null,
            organizations: [],
            isLoading: true
        }
    }

    async componentDidMount() {
        try {
            var org = []
            if (this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
            var isSupport = false
            if (this.props.user && this.props.user.role && this.props.user.role.name == "SUPPORT_AGENT") isSupport = true
            // org = [
            //     {
            //         "id": 466,
            //         "companyId": 108,
            //         "name": "BATANGHARI PROPERTINDO",
            //         "address": "JL SULTAN THAHA",
            //         "city": "Jakarta",
            //         "postalCode": "00000",
            //         "phone": "0213123213",
            //         "email": "mulyana@pajakku.com",
            //         "npwp": "023586498331000",
            //         "picName": "Mulyana",
            //         "picEmail": "mulyana@pajakku.com",
            //         "aliasEmail": "mulyana+43@pajakku.com",
            //         "picPosition": "tax head",
            //         "picPhone": "02113123213",
            //         "createdBy": "mulya",
            //         "createdAt": "2021-08-05T13:18:09.607",
            //         "updatedBy": "mulya",
            //         "updatedAt": "2021-08-06T17:30:25.561"
            //     }
            // ]

            let newOrgs = []
            newOrgs = [
                ...org
            ]
            var mappingOrgs = await Bluebird.mapSeries(newOrgs, async (item, index) => {
                return new Promise(async (resolve, reject) => {
                    try {
                        var getSertel = await fetch(`/api/ebupot/cert/search/${item.npwp}`)
                        var x = await getSertel.json()
                        item.infoAddress = item.address
                        item.infoPostal = item.postalCode
                        item.infoCity = item.city
                        item.infoPhone = item.phone
                        item.infoMobile = "-"
                        item.infoFax = "-"
                        item.certPassword = x.certPassword
                        item.certFilename = x.certFilename
                        resolve(item)
                    } catch (e) {
                        console.log(e, "TELL ME WHY")
                        item.infoAddress = item.address
                        item.infoPostal = item.postalCode
                        item.infoCity = item.city
                        item.infoPhone = item.phone
                        item.infoMobile = "-"
                        item.infoFax = "-"
                        // item.certPassword = item.address
                        resolve(item)
                    }
                })
            })
            console.log(mappingOrgs, "TELL ME WHY")
            this.setState({
                organizations: mappingOrgs,
                isLoading: false
            })

            let count = 0
            this.tick = setInterval(() => {
                count++;
                if (window.xml1771Pajakku) {
                    this.clearTick();
                    this.init();
                }
                if (count === 20) {
                    this.clearTick();
                    this.props.toastActions.izi(
                        'FAILED',
                        'ID-Bill widget js interface is not installed correctly. Please contact administrator',
                        'warning'
                    )
                }
            }, 500)
        } catch (e) {
            this.setState({ isLoading: false })
            console.log(e)
        }
    }

    componentWillUnmount() {
        this.clearTick();
    }

    async getSertel() {
        window.xml1771Pajakku.post('cert-data', {
            npwp: 'XXXXXXXXXXXXXXX',
            publicKey: 'TESTING123',
            privateKey: '321'
        })
    }

    init = () => {
        let { widgetInterface } = this.props.global;
        this.setState({ isLoading: false }, () => {
            // window.xml1771Pajakku.setOnMessageCallback((message) => {
            //     let { type } = message;
            //     switch (type) {
            //         case 'on-get-new-access-token':
            //             this.props.auth.getNewAccessToken();
            //             break;
            //         default:
            //             return;
            //     }
            // })

            window.xml1771Pajakku.render('webchat-client-pajakku', {
                accessToken: authService.getAccessToken(),
                client: this.props.widgetClientId,
                applicationType: 'internal', // [private, internal]
                channel: 'eppt-core',
                channelName: 'eppt-core',
                organizations: this.state.organizations,
                companyId: this.props.auth.id
                // additionalId: 1,
                // additionalUsername: 'sinatria'
            }, (eventName, data) => {
                switch (eventName) {
                    // case 'request-access-token':
                    //     setTimeout(function () {
                    //         window.xml1771Pajakku.post('update-access-token', { accessToken: '__NEW_ACCESS_TOKEN_HERE__' })
                    //     }, 2000)
                    //     break;
                    case 'error':
                        setTimeout(function () {
                            // Pasang Error Service
                            window.xml1771Pajakku.reload(data.id, {
                                accessToken: '__NEW_ACCESS_TOKEN_HERE__'
                            })
                        }, 2000)
                    case 'request-cert':
                        // data.npwp
                        // Request Sertel 1 NPWP ke Sertel 
                        // Widget 1771 meminta meta data sertifikat atas NPWP pada profil spt
                        // Informasi NPWP didapatkan dari objek data
                        // this.getSertel()
                        break;
                }
            });
        })
    }

    clearTick = () => {
        if (this.tick) clearInterval(this.tick);
    }

    render() {
        return (
            <div id="webchat-client-pajakku" className="mpk-full width height">
                {this.state.isLoading && <LoaderInfo />}
            </div>
        )
    }
}

export default rdxConnect(Spt1771);