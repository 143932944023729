/**
 * Created by dwiargo on 2/6/18.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Paper, LinearProgress, Button } from 'react-md';
import { MitraDJP, StatusInfo, navService, AppLogoStatic } from 'react-mpk';

import './ContainerPaper.scss';

class MainContent extends Component{
  toLoginPage = () => {
    navService.redirectTo('/account/login');
  };

  render(){
    const {children, status, validation, footer, headerText, deck, global, toLogin} = this.props;
    return(
      <div>
        {status==='progress' ? (
          <LinearProgress id="container-paper-progress"/>
        ) : (null)}
        <div className="header mpk-layout column">
          <h2 className="decorative-text">{ headerText }</h2>
          <div>{status === 'completed' ? (
            <StatusInfo
              status="success"
              message={deck}
              global={global}
              />
          ) :(
           <p className="mpk-font-color-D3">{deck}</p>
          )}
          </div>
        </div>
        {validation && validation.isError ? (
          <StatusInfo
            status="error"
            message={validation.errorText}
            />
        ) : (null)}
        <div className="children">
          {status === 'completed' ? (
            <div>
              {toLogin ? (
                <div className="action">
                  <Button
                    raised primary
                    onClick={this.toLoginPage}
                    >
                    Go to Login Page
                  </Button>
                </div>
              ) : (null)}
            </div>
          ):(children)}
        </div>
        {footer ? (
          <div className="footer">
            {footer}
          </div>
        ):(null)}
      </div>
    )
  }
}

class ContainerPaper extends Component{
  render(){
    const { children, headerText, deck, size, footer, flexHeight, validation, global, status, toLogin } = this.props;

    return(
      <div className={"container-paper " +(flexHeight ? "flex-height " : " ") + size}>
        <div className="container">
          <div className="brand">
            <AppLogoStatic
              appName="eppt"
              style={{
                height: 54
              }}
            />
          </div>
          <div className="cb">
            <Paper zDepth={3}>
              <MainContent
                children={children}
                validation={validation}
                status={status}
                footer={footer}
                deck={deck}
                headerText={headerText}
                global={global}
                toLogin={toLogin}
                />
            </Paper>
            <div className="info mpk-layout align-center">
              <div className="flex pajakku">
                <div className="mpk-font-size-NS">&copy; <b>PT. Mitra Pajakku</b></div>
                <div className="link">
                  <a href="#">Privacy Policy</a>
                  <a href="#">Terms of Service</a>
                  <a href="#">Support</a>
                </div>
              </div>
              <MitraDJP/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  global:state.global
});

export default connect(mapStateToProps)(ContainerPaper);
