import ApiService from '../../../../../services/api.service';

class SPT4a2Service extends ApiService {
  name= 'SPT_4a2';
  // EPPT CORE
  cloud=true
  path= 'pasal4/spt';
  // EPPT CORE
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    save: async(data)=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}`, data, {
        params: {
          blockBunifikasiMonth: data.blockBunifikasiMonth,
          blockBunifikasiYear: data.blockBunifikasiYear
        }
      })
      return res;
    },
    pembetulan: async(id, dispatch=()=>{})=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${id}/rev`)

      return res;
    },
    pembetulanBulk: async(ids, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/pembetulan`, ids)

      return res;
    },

    downloadLapor: async(id, dispatch=()=>{})=> {
      var res = await this.http.get(`${this.apiLocation}/pasal4/master/files/download/${id}`,{
        responseType: 'blob'
      })

      return res;
    },

    laporAll: async(data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/lapor`, data)

      return res;
    },

    laporAllWithSummary: async(data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/generate/summary`, data)

      return res;
    },

    laporAllWithSummaryParam: async(data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/generate/summary/param`, {params: data})

      return res;
    },

    pengajuanApprovalBulk: async(data, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/pasal4/mainFlow/pengajuan/bulk`, data)

      return res;
    },

    getSummary: async(sptId)=> {
      return await this.http.get(`${this.apiLocation}/pasal4/spt4a2/ssp-4a2/detail/${sptId}`)
    },

    lapor: async(sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/submit`)

      return res;
    },

    exportCsvSelected: async(ids, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/export/csv`, ids)

      return res;
    },

    exportPdfSelected: async(ids, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/export/pdf`, ids)

      return res;
    },

    exportPdfMergeSelected: async(ids, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/export/pdf/merge`, ids)

      return res;
    },

    summaryDaftarSpt: async (params, dispatch=()=>{})=> {
      if(params) {
        for (let key in params) {
          let value = params[key]
          let fixedKey = key.replace('__', '.')

          params[fixedKey] = value

          delete params[key]
        }
      }

      var res = await this.http.get(`${this.apiLocation}/${this.path}/export/excel/daftar-spt`, {
        params: params,
        responseType: 'blob'
      })

      return res;
    },
    cetakBulkLog: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report/log`, data)
      return res;
    },
    syncOrg: async(id)=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/sync/${id}`)
      return res;
    },
  }

  // apiMockup= {
  //   find: [{
  //     id: 1,
  //     status: 'Process',
  //     npwp: '023945840000',
  //     name: 'PT. A',
  //     pasal: '4a2',
  //     year: '2018',
  //     organization: {name: 'PT. A', npwp: '123123'},
  //     month: 'Januari',
  //     pembetulan: '0'
  //   }],
  //   findOne: {data: {
  //     id: 1,
  //     status: 'Process',
  //     npwp: '023945840000',
  //     name: 'PT. A',
  //     pasal: '4a2',
  //     year: '2018',
  //     organization: {name: 'PT. A', npwp: '123123'},
  //     month: 'Januari',
  //     pembetulan: '0'
  //   }}
  // }
}


export default new SPT4a2Service();
