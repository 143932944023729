import React from 'react'
import { connect } from 'react-redux';
import env from 'env'

@connect((state) => ({
    // EPPT CORE
    auth: state.auth.company
    // EPPT CORE
}))
export default class TarraLapor extends React.Component {

    readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    componentDidMount(){
        var token = null
        if(env.mode == 'prod'){
            try {
                token = this.readCookie('MPK_ACCESS_TOKEN')
                window.tarraLapor.render('tarra-lapor-pajakku', {
                    accessToken: token,
                    widgetClientId: "3yQrn1cjBBHZthL1PrOxWkYNtxjicUMl0JCiFmB1",
                    clientId: "bnscmorhN32I2zQVSmKBuoGhKhRGrYcK0pCnbNgV",
                    companyId: this.props.match.params.companyId,
                    setOnMessageCallback: (e)=> {
                        console.log("Error", e)
                    }
                }, (e)=> {
                    console.log(e, "TELL ME")
                })
                window.tarraLapor.onMessage = (e)=> {
                    console.log(e)
                }
            } catch(e){
                console.log(e, "error component")
            }
        } else {
            try {
                token = this.readCookie('MPK_ACCESS_TOKEN')
                console.log(token, this.props.match.params.companyId, "6UGsT9hJsJmNjYKtMWlvMaRttlHuys1ZJor4K6C1", "bnscmorhN32I2zQVSmKBuoGhKhRGrYcK0pCnbNgV")
                window.tarraLapor.render('tarra-lapor-pajakku', {
                    accessToken: token,
                    widgetClientId: "6UGsT9hJsJmNjYKtMWlvMaRttlHuys1ZJor4K6C1",
                    clientId: "bnscmorhN32I2zQVSmKBuoGhKhRGrYcK0pCnbNgV",
                    companyId: this.props.match.params.companyId,
                    username: "siapa saja",
                    setOnMessageCallback: (e)=> {
                        console.log("Errror", e)
                    }
                }, (e)=> {
                    console.log(e, "TELL ME")
                })
                window.tarraLapor.onMessage = (e)=> {
                    console.log(e)
                }
            } catch(e){
                console.log(e, "error component")
            }
        }
    }

    render(){
        return (
            <div id="tarra-lapor-pajakku" style={{ height: '100%', width: '100%' }}>
                
            </div>
        )
    }
}