import ApiService from '../../../../../services/api.service';
import iziToast from 'izitoast';
import http from '../../../../../services/http.service';

class SPT2126Service extends ApiService {
  name= 'SPT_21';
  // EPPT CORE
  cloud=true
  path= 'spt';
  // EPPT CORE
  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    deleteViaKafka: async (sptId, consumeId)=> {
      // let res = await http.delete(`${this.apiLocation}/spt/${sptId}/request`)
      // return res
      var res = await this.http.post(`${this.apiLocation}/${this.path}/delete`, [sptId], {
        headers: {
          'X-ConsumeID': consumeId
        }
      })

      return res;
    },

    /*deleteBulk: async (sptId, ids)=> {
      let res = await http.post(`/api/eppt/delete/spt`, ids);

      return res;
    },*/

    submitWithConsume: async (id, consumeId)=> {
      // EPPT CORE
      // http.headers.common['X-ConsumeID'] = consumeId
      // EPPT CORE
      let res = await http.get(`${this.apiLocation}/spt/submit/${id}`, {
        headers: {
          'X-ConsumeID': consumeId
        }
      });
      return res;
    },

    deleteWithConsume: async (id, consumeId)=> {
      // EPPT CORE
      // http.defaults.headers.common['X-ConsumeID'] = consumeId
      // EPPT CORE
      let res = await http.delete(`${this.apiLocation}/${this.path}/${id}`, {
        headers: {
          'X-ConsumeID': consumeId
        }
      });
      return res;
    },

    deleteBulk: async(ids, consumeId)=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/delete`, ids, {
        headers: {
          'X-ConsumeID': consumeId
        }
      })

      return res;
    },

    pembetulan: async(id, dispatch=()=>{})=> {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/pembetulan/${id}`)

      return res;
    },

    pembetulanBulk: async(ids, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/pembetulan`, ids)

      return res;
    },

    downloadLapor: async(id, dispatch=()=>{})=> {
      var res = await this.http.get(`${this.apiLocation}/master/files/download/${id}`,{
        responseType: 'blob'
      })

      return res;
    },

    laporAll: async(data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/lapor`, data)

      return res;
    },

    laporAllWithSummary: async(data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/generate/summary`, data)

      return res;
    },

    laporAllWithSummaryParam: async(data) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/generate/summary/param`, {params: data})

      return res;
    },

    pengajuanApprovalBulk: async(data, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/mainFlow/pengajuan/bulk`, data)

      return res;
    },

    // lapor: async(data) => {
    //   var res = await this.http.post(`${this.apiLocation}/efiling/upload/csv`, data)

    //   return res;
    // }
    lapor: async(sptId) => {
      var res = await this.http.get(`${this.apiLocation}/${this.path}/${sptId}/submit`)

      return res;
    },

    exportCsvSelected: async(ids, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/export/csv`, ids)

      return res;
    },

    exportPdfSelected: async(ids, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/export/pdf`, ids)

      return res;
    },

    exportPdfMergeSelected: async(ids, dispatch=()=>{})=> {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/export/pdf/merge`, ids)

      return res;
    },

    summaryDaftarSpt: async (params, dispatch=()=>{})=> {
      if(params) {
        for (let key in params) {
          let value = params[key]
          let fixedKey = key.replace('__', '.')

          params[fixedKey] = value

          delete params[key]
        }
      }

      var res = await this.http.get(`${this.apiLocation}/${this.path}/export/excel/daftar-spt`, {
        params: params,
        responseType: 'blob'
      })

      return res;
    },
    cetakBulkLog: async (data) => {
      var res = await this.http.post(`${this.apiLocation}/${this.path}/report/log`, data)
      return res;
    },
    syncOrg: async(id)=> {
      var res = await this.http.get(`${this.apiLocation}/pasal21/spt/sync/${id}`)
      return res;
    },
  }
  // apiMockup= {
  //   find: [{
  //     id: 1,
  //     status: 'Process',
  //     npwp: '023945840000',
  //     name: 'PT. A',
  //     pasal: '21/26',
  //     year: '2018',
  //     month: 'Januari',
  //     pembetulan: '0'
  //   }],
  //   findOne: {
  //     id: 1,
  //     status: 'Process',
  //     npwp: '023945840000',
  //     name: 'PT. A',
  //     pasal: '21/26',
  //     year: '2018',
  //     month: 'Januari',
  //     pembetulan: '0'
  //   }
  // }
}


export default new SPT2126Service();
