/**
 * Created by dwiargo on 11/29/17.
 */

import React, { Component } from 'react';
import { Appbar, Notification, rdxConnect, navService, Companies } from 'react-mpk';
import utilsService from './../../services/utilsService';
import companyService from './../../services/companyService'
import { Button } from 'react-md';
import t from 'counterpart';

class Main extends Component{
  constructor(){
    super();
    this.state = {
      isReady: false
    }
  }

  componentWillMount(){
    try {
      if(this.props.auth.user.isSupport || this.props.auth.user.isAdmin){
        navService.redirectTo('main-support')
      }
    } catch(e){}
    utilsService.setReqOptionsCompanyId(null);
    this.props.authActions.setProperties({
      company: null,
      meInCompany: null
    });
  }

  redirectToCompany = async (company, subscription) => {
    var companyId = company.id
    var subscriptionId = subscription.id
    utilsService.setReqOptionsCompanyId(company.id);
    utilsService.setReqOptionsSubscriptionId(subscription.id);
    // GET COMPANY
    try {
      let res = await companyService.meAsUser(companyId, subscriptionId);
      let { company } = res.data;
      this.props.authActions.setProperties({
        company,
        meInCompany: res.data 
      });
      localStorage.setItem("companyId", companyId)
      localStorage.setItem("subscriptionId", subscriptionId)
      navService.redirectTo(`/company/${company.id}/${subscription.id}/init`)
    } catch(error){
      this.props.toastActions.izi(
        t.translate('word.failed'),
        error.message[this.props.global.loceleCode],
        'warning'
      )
      localStorage.removeItem("companyId", companyId)
      localStorage.removeItem("subscriptionId", subscriptionId)
    }
    // GET COMPANY
  }

  render(){
    let { auth, global } = this.props;
    global.username = auth.user.username;
    var halonaurl = []
    if(window.location.href.indexOf('demo') != -1){
        halonaurl = [
            {
              label:"e-Filing Pajakku",
              materialIcon:'dashboard',
              url: "http://e-filing.staging.pajakku.com",
              appName : 'efiling',
              // img : '/images/apps/efiling-picturemark-d.png'
            },
            {
              label:"Tarra e-Faktur Pajakku",
              materialIcon:'dashboard',
              url: "https://efaktur.pajakku.com/",
              appName : 'tarra',
              // img : '/images/apps/tarra-picturemark-d.png'
            },
            {
              label:"e-Bill Pajakku",
              materialIcon:'dashboard',
              url: "https://e-billing.pajakku.com/",
              appName : 'ebilling',
              // img : '/images/apps/ebilling-picturemark-d.png'
            },
            {
              label:"e-Met Pajakku",
              materialIcon:'dashboard',
              url: "https://e-meterai.pajakku.com/",
              appName : 'emet',
              // img : '/images/apps/e-met-picturemark-d.png'
            },
            {
              label:"e-Bunifikasi",
              materialIcon:'dashboard',
              url: "https://bunifikasi.pajakku.com/",
              appName : 'buni',
              // img : '/images/apps/e-bunifikasi-picturemark-d.png'
            },
            {
              label:"Penerimaan Negara Pajakku",
              materialIcon:'dashboard',
              url: "https://mpn.pajakku.com/",
              appName : 'mpn',
              // img : '/images/apps/mpn-picturemark-d.png'
            },
            {
              label:"e-Pengolahan Pajak Terpadu",
              materialIcon:'dashboard',
              url: "https://eppt.pajakku.com/",
              appName : 'eppt',
              // img : '/images/apps/eppt-picturemark-d.png'
            },
            {
              label:"Sistem Informasi Perpajakan",
              materialIcon:'dashboard',
              url: "http://e-filing.pajakku.com",
              appName : 'sip',
              // img : '/images/apps/sip-picturemark-d.png'
            },
            {
              label:"e-Konfirmasi Status Wajib Pajak",
              materialIcon:'dashboard',
              url: "https://eks.pajakku.com/",
              appName : 'eks',
              // img : '/images/apps/e-ks-picturemark-d.png'
            },
            {
              label:"Halona Cloud",
              materialIcon:'dashboard',
              url: "http://halona.pajakku.com",
              appName : 'halona',
              // img : "/images/apps/Logo-Product-v2.0_halonasoft-def-pm.png"
            }
        ]
    } else {
        halonaurl = [
            {
              label:"e-Filing Pajakku",
              materialIcon:'dashboard',
              url: "http://e-filing.pajakku.com",
              appName : 'efiling',
              // img : '/images/apps/e-filing-picturemark-d.png'
            },
            {
              label:"Tarra e-Faktur Pajakku",
              materialIcon:'dashboard',
              url: "https://efaktur.pajakku.com/",
              appName : 'tarra',
              // img : '/images/apps/tarra-picturemark-d.png'
            },
            {
              label:"e-Bill Pajakku",
              materialIcon:'dashboard',
              url: "https://e-billing.pajakku.com/",
              appName : 'ebilling',
              // img : '/images/apps/id-billing-picturemark-d.png'
            },
            {
              label:"e-Met Pajakku",
              materialIcon:'dashboard',
              url: "https://e-meterai.pajakku.com/",
              appName : 'emet',
              // img : '/images/apps/e-met-picturemark-d.png'
            },
            {
              label:"e-Bunifikasi",
              materialIcon:'dashboard',
              url: "https://bunifikasi.pajakku.com/",
              appName : 'buni',
              // img : '/images/apps/e-bunifikasi-picturemark-d.png'
            },
            {
              label:"Penerimaan Negara Pajakku",
              materialIcon:'dashboard',
              url: "https://mpn.pajakku.com/",
              appName : 'mpn',
              // img : '/images/apps/mpn-picturemark-d.png'
            },
            {
              label:"e-Pengolahan Pajak Terpadu",
              materialIcon:'dashboard',
              url: "https://eppt.pajakku.com/",
              appName : 'eppt',
              // img : '/images/apps/eppt-picturemark-d.png'
            },
            {
              label:"Sistem Informasi Perpajakan",
              materialIcon:'dashboard',
              url: "http://e-filing.pajakku.com",
              appName : 'sip',
              // img : '/images/apps/sip-picturemark-d.png'
            },
            {
              label:"e-Konfirmasi Status Wajib Pajak",
              materialIcon:'dashboard',
              url: "https://eks.pajakku.com/",
              appName : 'eks',
              // img : '/images/apps/e-ks-picturemark-d.png'
            },
            {
                label:"Halona Cloud",
                materialIcon:'dashboard',
                url: "http://cloud.halonasoft.com",
                appName : 'halona',
                // img : "/images/apps/halonasoft-picturemark-d.png"
            }
        ]
    }
    return ( 
      <div className="mpk-layout column fill">
        <Appbar
          notification={<Notification newFeeds={0}/>}
          appName="eppt"
          showVersion fixedVersion={false}
          profileMenu={[]}
          appsShortcut={halonaurl}
          consoleShortcut={Object.keys(this.props.global.appConsole).map(key => {
            return this.props.global.appConsole[key];
          })}
          translate={true}
        /> 
          <Companies 
            className="mpk-animation slide-in"
            onSelectCompany={this.redirectToCompany}
            footer={
              <div className="mpk-margin-N margin-top margin-bottom">
                <Button 
                  raised secondary
                  onClick={() => {
                    window.open(`${this.props.global.appConsole.userAccount.url}/main/access/company/add`)
                  }}
                >
                  {t.translate('custom.words.addNewCompany')}
                </Button>
              </div>
            }
          />
        {/* )} */}
      </div>
    )
  }
}

export default rdxConnect(Main);