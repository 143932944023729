import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize } from 'redux-form';
import {TabsContainer, Tabs, Tab, Chip, DialogContainer,
  Button,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn
} from 'react-md';
import moment from 'moment'
// import LoadingOverlay from 'react-loading-overlay';
// import Loader from 'react-spinners/ScaleLoader'
import MDSpinner from "react-md-spinner";

import Espt23Service from './SPT23.service'
import ListViewEbupot from './../../../../../../components/entity/ListViewEbupot'
import iziToast from 'izitoast';
import OrganizationService from './../../Organization/Organization.service';
import {validation, FileInputNext, Searchfieldv2, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio, convert, CheckboxSelection, Multiselect, Multiselectv2} from '../../../../../../components/form';

import download from 'downloadjs';
import uuid from 'uuid';
import counterpart from 'counterpart';
import Bluebird from 'bluebird';

@reduxForm({form: 'SptExportForm', destroyOnUnmount: true})
@connect((state)=> ({
  ...Espt23Service.stateConnectSetting()(state),
  // EPPT CORE
  user: state.auth.user,
  auth: state.auth.company
  // EPPT CORE
}), Espt23Service.actionConnectSetting())
export default class Espt23View extends ListViewEbupot {
  service=Espt23Service;

  constructor(props){
    super(props);
    this.state = {
      ...this.state,
      total: {},
      cetak: false,
      cetakDaftar: false,
      loadingPage: false
    }
  }

  beforeFetch(params) {
    delete params.startDate
    delete params.endDate
    delete params.column
    delete params.total
    delete params.keyword


    // params.sortBy = 'id'
    // params.sort = 1
}

  rootPath = this.props.match.url
  editDialog = false;
  addDialog = false;

  columns=Espt23Service.columns

  static contextTypes = {
    showDialog: PropTypes.func.isRequired,
  };

  async submitXml(item){
    item.rebuild = false
    var askAttachment = await this.context.showDialog(()=> ({
      title: null,
      initialValue: item,
      width: 500,
      height: 132,
      text: (
        <div>
          <p>Apa anda yakin akan mensubmit SPT terpilih ?</p>
        </div>
      )
    }))
    if(askAttachment){
      try {
        let generate = await this.service.api.submitXml(askAttachment)
        iziToast.success({
          title: "Submit SPT",
          message: "berhasil"
        })
      } catch(e){
        iziToast.error({
          title: "Submit SPT",
          message: "gagal " + e.message
        })
      }
    }
  }

  belowTopBar(){
    return (
      <DialogContainer
        visible={this.state.loadingPage}
        dialogStyle={{ width: '200px', height: '200px', borderRadius: '30px', textAlign: 'center', paddingTop: '50px' }}
      >
        <MDSpinner size={45} />
      </DialogContainer>
    )
  }

  async checkSpt(item){
    item.rebuild = false
    var askAttachment = await this.context.showDialog(()=> ({
      title: null,
      initialValue: item,
      width: 500,
      height: 132,
      text: (
        <div>
          <p>Apa anda yakin akan mencheck XML SPT terpilih ?</p>
        </div>
      )
    }))
    if(askAttachment){
      try {
        this.setState({ loadingPage: true })
        let generate = await this.service.api.getCheckById(askAttachment)
        iziToast.success({
          title: "Check XML",
          message: "berhasil"
        })
        this.setState({ loadingPage: false })
      } catch(e){
        iziToast.error({
          title: "Check XML",
          message: "gagal " + e.message
        })
        this.setState({ loadingPage: false })
      }
    }
  }

  async postingSpt(item){
    item.rebuild = false
    var askAttachment = await this.context.showDialog(()=> ({
      title: null,
      initialValue: item,
      width: 500,
      height: 132,
      text: (
        <div>
          <p>Apa anda yakin akan menposting SPT terpilih ?</p>
        </div>
      )
    }))
    if(askAttachment){
      try {
        this.setState({ loadingPage: true })
        let generate = await this.service.api.getPostingById(askAttachment)
        var message = ""
        if(generate && generate.data && generate.data.message) message = generate.data.message
        if(generate && generate.data && generate.data.status) {
          iziToast.success({
            title: "Posting SPT",
            message: "berhasil, " + message
          })
        } else {
          iziToast.error({
            title: "Posting SPT",
            message: "gagal, " + message
          })
        }
        this.setState({ loadingPage: false })
      } catch(e){
        iziToast.error({
          title: "Posting SPT",
          message: "gagal " + e.message
        })
        this.setState({ loadingPage: false })
      }
    }
  }

  async sendingSptBackDate(item){
    var askAttachment = await this.context.showDialog(()=> ({
      title: null,
      initialValue: item,
      width: 800,
      height: 600,
      contentStyle: {
        width: 800,
        height: 600
      },
      text: (
        <div>
          <p>Apa anda yakin akan mengirim SPT terpilih dengan tanggal :</p>
          <Field
              label='Back Date'
              name='tgl'
              className="md-cell md-cell--12"
              component={Datepicker}
              validate={validation.required}
          />
        </div>
      )
    }))
    if(askAttachment){
      try {
        this.setState({ loadingPage: true })
        var tgl = moment(new Date(askAttachment.tgl)).format('DDMMYYYY')
        let generate = await this.service.api.sendingBackdate({
          sptId: item.id,
          tgl: tgl
        })
        var message = ""
        if(generate && generate.data && generate.data.message) message = generate.data.message
        if(generate && generate.data && generate.data.status) {
          iziToast.success({
            title: "Kirim SPT",
            message: "berhasil, " + message
          })
        } else {
          iziToast.error({
            title: "Kirim SPT",
            message: "gagal, " + message
          })
        }
        this.setState({ loadingPage: false })
      } catch(e){
        iziToast.error({
          title: "Kirim SPT",
          message: "gagal " + e.message
        })
        this.setState({ loadingPage: false })
      }
    }
  }

  async sendingSpt(item){
    item.rebuild = false
    var askAttachment = await this.context.showDialog(()=> ({
      title: null,
      initialValue: item,
      width: 500,
      height: 132,
      text: (
        <div>
          <p>Apa anda yakin akan mengirim SPT terpilih ?</p>
        </div>
      )
    }))
    if(askAttachment){
      try {
        this.setState({ loadingPage: true })
        let generate = await this.service.api.getSendingById(askAttachment)
        var message = ""
        if(generate && generate.data && generate.data.message) message = generate.data.message
        if(generate && generate.data && generate.data.status) {
          iziToast.success({
            title: "Kirim SPT",
            message: "berhasil, " + message
          })
        } else {
          iziToast.error({
            title: "Kirim SPT",
            message: "gagal, " + message
          })
        }
        this.setState({ loadingPage: false })
      } catch(e){
        iziToast.error({
          title: "Kirim SPT",
          message: "gagal " + e.message
        })
        this.setState({ loadingPage: false })
      }
    }
  }

  // ifstatus posting -> sending, check
  // ifstatus submit -> check, posting
  // ifstatus posting -> sending
  // ifstatus finish -> tidak ditampilkan apa2x

  // STATUS CREATED -> SUBMIT DAN CHECK
  // STATUS SUBMIT -> CHECK DAN POSTING
  // STATUS POSTING -> SENDING
  // STATUS FINISH -> GAK DITAMPILKAN APA2

  async revisiItem(item){
    var askAttachment = await this.context.showDialog(()=> ({
      title: null,
      initialValue: item,
      width: 500,
      height: 132,
      text: (
        <div>
          <p>Apa anda yakin akan melakukan pembetulan SPT terpilih ?</p>
        </div>
      )
    }))
    if(askAttachment){
      try {
        var res = await this.service.api.revisionSpt(item.id)
        iziToast.success({
          title: "Success",
          message: "proses revisi spt berhasil"
        })
      } catch (e){
        iziToast.error({
          title: "Error",
          message: "proses revisi spt gagal, " + e.message
        })
      }
    }
  }

  async downloadBPE(item){
    this.props.history.push(`ebupot-23/bpe/${item.id}`)
    // try {
    //   var report = await this.service.api.cetakBpe(item.id)
    //   var filename = report.headers.filename
    //   download(report.data, filename);
    //   this.setState({cetak: false})
    //   iziToast.success({
    //     title: "Success",
    //     message: "download BPE sedang diproses"
    //   })
    // } catch (e) {
    //   console.log(e)
    //   iziToast.error({
    //     title: "Failed",
    //     message: "download BPE gagal, " + e.message
    //   })
    //   this.setState({cetak: false})
    // }
  }

  async historySPT(item){
    localStorage.setItem("spt_timestamp", item.createdDate);
    this.props.history.push(`ebupot-23/spt-history/${item.id}/${item.createdDate}`)
    // try {
    //   var report = await this.service.api.cetakBpe(item.id)
    //   var filename = report.headers.filename
    //   download(report.data, filename);
    //   this.setState({cetak: false})
    //   iziToast.success({
    //     title: "Success",
    //     message: "download BPE sedang diproses"
    //   })
    // } catch (e) {
    //   console.log(e)
    //   iziToast.error({
    //     title: "Failed",
    //     message: "download BPE gagal, " + e.message
    //   })
    //   this.setState({cetak: false})
    // }
  }

  async bulkCheck(){
    var ids = []
    var selected = this.props.table.selected
    selected.map((i)=> {
      ids.push(i.id)
    })
    var filter = selected.filter((item, i)=> {
      return item.status != "CREATED" && item.status != "SUBMIT" && item.status != "POSTING"
    })
    if(filter.length > 0){
      iziToast.info({
        title: "Validasi",
        message: "Check XML SPT hanya dapat dilakukan pada SPT dengan status CREATED, SUBMIT dan POSTING"
      })
    } else {
      var desc = "check xml"
      var confirmation = await this.context.showDialog(()=> ({
        title: null,
        width: 500,
        height: 132,
        text: (
          <div>
            <p>Apa anda yakin akan {desc} XML SPT terpilih ?</p>
          </div>
        )
      }))
      if(confirmation){
        this.setState({ loadingPage: true })
        try {
          var res = await this.service.api.bulkCheck(ids)
          iziToast.success({
            title: "Success",
            message: "proses "+ desc +" spt berhasil"
          })
          await this.removeCheckbox()
          this.fetchData()
          this.setState({ loadingPage: false })
        } catch (e){
          iziToast.error({
            title: "Error",
            message: "proses "+ desc +" spt gagal, " + e.message
          })
          await this.removeCheckbox()
          this.setState({ loadingPage: false })
        }
      }
    }
  }

  async bulkPosting(){
    var ids = []
    var selected = this.props.table.selected
    selected.map((i)=> {
      ids.push(i.id)
    })
    var filter = selected.filter((item, i)=> {
      return item.status == 'FINISH'
    })
    if(filter.length > 0){
      iziToast.info({
        title: "Validasi",
        message: "Check SPT hanya dapat dilakukan pada SPT dengan status FINISH"
      })
    } else {
      var desc = "posting"
      var confirmation = await this.context.showDialog(()=> ({
        title: null,
        width: 500,
        height: 132,
        text: (
          <div>
            <p>Apa anda yakin akan {desc} SPT terpilih ?</p>
          </div>
        )
      }))
      if(confirmation){
        this.setState({ loadingPage: true })
        try {
          var res = await this.service.api.bulkPosting(ids)
          iziToast.success({
            title: "Success",
            message: "proses "+ desc +" spt berhasil"
          })
          await this.removeCheckbox()
          this.fetchData()
          this.bulkReview(res)
          this.setState({ loadingPage: false })
        } catch (e){
          iziToast.error({
            title: "Error",
            message: "proses "+ desc +" spt gagal, " + e.message
          })
          await this.removeCheckbox()
          this.setState({ loadingPage: false })
        }
      }
    }
  }

  async bulkSending(){
    var ids = []
    var selected = this.props.table.selected
    selected.map((i)=> {
      ids.push(i.id)
    })
    var filter = selected.filter((item, i)=> {
      return item.status != 'POSTING'
    })
    if(filter.length > 0){
      iziToast.info({
        title: "Validasi",
        message: "Kirim SPT hanya dapat dilakukan pada SPT dengan status POSTING"
      })
    } else {
      var desc = "mengirim"
      var confirmation = await this.context.showDialog(()=> ({
        title: null,
        width: 500,
        height: 132,
        text: (
          <div>
            <p>Apa anda yakin akan {desc} SPT terpilih ?</p>
          </div>
        )
      }))
      if(confirmation){
        this.setState({ loadingPage: true })
        try {
          var res = await this.service.api.bulkSending(ids)
          iziToast.success({
            title: "Success",
            message: "proses "+ desc +" spt berhasil"
          })
          await this.removeCheckbox()
          this.fetchData()
          this.bulkReview(res)
          this.setState({ loadingPage: false })
        } catch (e){
          iziToast.error({
            title: "Error",
            message: "proses "+ desc +" spt gagal, " + e.message
          })
          await this.removeCheckbox()
          this.setState({ loadingPage: false })
        }
      }
    }
  }

  async bulkRevision(){
    var ids = []
    var selected = this.props.table.selected
    selected.map((i)=> {
      ids.push(i.id)
    })
    var filter = selected.filter((item, i)=> {
      return item.status != 'FINISH'
    })
    if(filter.length > 0){
      iziToast.info({
        title: "Validasi",
        message: "Revisi SPT hanya dapat dilakukan pada SPT dengan status FINISH"
      })
    } else {
      var desc = "revisi"
      var confirmation = await this.context.showDialog(()=> ({
        title: null,
        width: 500,
        height: 132,
        text: (
          <div>
            <p>Apa anda yakin akan {desc} SPT terpilih ?</p>
          </div>
        )
      }))
      if(confirmation){
        this.setState({ loadingPage: true })
        try {
          var res = await this.service.api.bulkRevision(ids)
          iziToast.success({
            title: "Success",
            message: "proses "+ desc +" spt berhasil"
          })
          await this.removeCheckbox()
          this.fetchData()
          this.bulkReview(res)
          this.setState({ loadingPage: false })
        } catch (e){
          iziToast.error({
            title: "Error",
            message: "proses "+ desc +" spt gagal, " + e.message
          })
          await this.removeCheckbox()
          this.setState({ loadingPage: false })
        }
      }
    }
  }

  async bulkDelete(){
    var ids = []
    var selected = this.props.table.selected
    selected.map((i)=> {
      ids.push(i.id)
    })
    var filter = selected.filter((item, i)=> {
      return item.status == 'FINISH' || item.status == 'POSTING'
    })
    if(filter.length > 0){
      iziToast.info({
        title: "Validasi",
        message: "Delete SPT hanya dapat dilakukan pada SPT dengan selain status FINISH dan POSTING"
      })
    } else {
      var desc = "delete"
      var confirmation = await this.context.showDialog(()=> ({
        title: null,
        width: 500,
        height: 132,
        text: (
          <div>
            <p>Apa anda yakin akan {desc} SPT terpilih ?</p>
          </div>
        )
      }))
      if(confirmation){
        this.setState({ loadingPage: true })
        try {
          var res = await this.service.api.bulkDelete(ids)
          iziToast.success({
            title: "Success",
            message: "proses "+ desc +" spt berhasil"
          })
          await this.removeCheckbox()
          this.fetchData()
          this.bulkReview(res)
          this.setState({ loadingPage: false })
        } catch (e){
          iziToast.error({
            title: "Error",
            message: "proses "+ desc +" spt gagal, " + e.message
          })
          await this.removeCheckbox()
          this.setState({ loadingPage: false })
        }
      }
    }
  }

  async bulkReview(res){
    var data = []
    try {
      data = res.data
    } catch (e){}
    var confirmation = await this.context.showDialog(()=> ({
      title: null,
      width: 800,
      height: 600,
      okText: ' ',
      cancelText: 'Close',
      contentStyle: {
        height: 600
      },
      text: (
        <DataTable plain>
          <TableHeader>
            <TableRow>
              <TableColumn>No.</TableColumn>
              <TableColumn>Masa</TableColumn>
              <TableColumn>Tahun</TableColumn>
              <TableColumn>Message</TableColumn>
              <TableColumn>Status</TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.length > 0 &&
              data.map((d, i)=> {
                return (
                  <TableRow key={i}>
                    <TableColumn>{ i+1 }</TableColumn>
                    <TableColumn>{ d.spt.month }</TableColumn>
                    <TableColumn>{ d.spt.year }</TableColumn>
                    <TableColumn>{ d.message }</TableColumn>
                    <TableColumn style={{ textAlign: 'center' }}>
                      {d.status &&
                        <Button icon secondary iconClassName="mdi icon mdi-check"></Button>
                      }
                      {!d.status &&
                        <Button icon secondary iconClassName="mdi icon mdi-close"></Button>
                      }
                    </TableColumn>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </DataTable>
      )
    }))
  }

  // async exportData(){
  //   var ids = []
  //   var selected = this.props.table.selected
  //   selected.map((i)=> {
  //     ids.push(i.id)
  //   })
  //   var desc = "export data"
  //   var confirmation = await this.context.showDialog(()=> ({
  //     title: null,
  //     width: 500,
  //     height: 132,
  //     text: (
  //       <div>
  //         <p>Apa anda yakin akan {desc} SPT terpilih ?</p>
  //       </div>
  //     )
  //   }))
  //   if(confirmation){
  //     this.setState({ loadingPage: true })
  //     try {
  //       var res = await this.service.api.bulkRevision(ids)
  //       iziToast.success({
  //         title: "Success",
  //         message: "proses "+ desc +" spt berhasil"
  //       })
  //       this.removeCheckbox()
  //       this.fetchData()
  //       this.bulkReview(res)
  //       this.setState({ loadingPage: false })
  //     } catch (e){
  //       iziToast.error({
  //         title: "Error",
  //         message: "proses "+ desc +" spt gagal, " + e.message
  //       })
  //       this.removeCheckbox()
  //       this.setState({ loadingPage: false })
  //     }
  //   }
  // }

  async exportItem(item){
    this.setState({ loadingPage: true })
    try {
      var report = await this.service.api.exportSpt(item.id)
      window.open(report.data.url);
      iziToast.success({
        title: "Success",
        message: "Export SPT berhasil"
      })
      this.setState({ loadingPage: false })
    } catch (e) {
      iziToast.error({
        title: "Failed",
        message: "Export SPT gagal, " + e.message
      })
      this.setState({ loadingPage: false })
    }
  }

  async rekapItem(){
    var org = []
    if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
    var isSupport = false
    if(this.props.user && this.props.user.role && this.props.user.role.name == "SUPPORT_AGENT") isSupport = true
    var data = await this.context.showDialog(()=> ({
      title: "Export Summary Rekap SPT",
      width: 800,
      height: 600,
      okText: "Export",
      cancelText: "Close",
      text: (
        <div style={{ height: 450 }}>
          <div className="md-grid">
            {!isSupport &&
              <Field
                label     = 'Organization'
                name      = 'npwp'
                id        = "spt2126-organization"
                className = "md-cell md-cell--12"
                component = {Multiselect}
                valueField = 'parent'
                options   = {org}
                validate  = {validation.required}
                itemTemplate = {function(d) {
                  let nameAndAlias = ""
                  if (d.name)
                    nameAndAlias = d.name
                  if (d.aliasName)
                    nameAndAlias += " - " + d.aliasName
                  return {
                    primaryText  : nameAndAlias,
                    secondaryText: d.npwp,
                    onClick      : () => {
                      if(!this.props.disabled){
                        this.handleItemClick(d); this.hide()
                      }
                    }
                  }
                }}
              />
            }
            {isSupport &&
              <Field
                  label         = 'Organization'
                  name          = 'npwp'
                  className     = "md-cell md-cell--12"
                  component     = {Multiselectv2}
                  valueField    = 'parent'
                  remoteSearch  = {true}
                  apiPath       = 'getAll'
                  params        = {{ user: this.props.user }}
                  service       = {OrganizationService}
                  validate      = {validation.required}
                  renderFirst   = {true}
                  validate      = {validation.required}
                  coreSearch    = {true}
                  npwpSearch    = {true}
                  itemTemplate  = {function(d) {
                    let nameAndAlias = ""
                    if (d.name)
                      nameAndAlias = d.name
                    if (d.aliasName)
                      nameAndAlias += " - " + d.aliasName
                    return {
                      primaryText  : nameAndAlias,
                      secondaryText: d.npwp,
                      onClick      : () => {
                        if(!this.props.disabled){
                          this.handleItemClick(d); this.hide()
                        }
                      }
                    }
                  }}
              />
            }
            <Field
              label     = 'Dari Masa'
              name      = 'masa1'
              className = "md-cell md-cell--6"
              component = {Searchfieldv2}
              options   = {[
                {id: 1, name: 'Januari'},
                {id: 2, name: 'Februari'},
                {id: 3, name: 'Maret'},
                {id: 4, name: 'April'},
                {id: 5, name: 'Mei'},
                {id: 6, name: 'Juni'},
                {id: 7, name: 'Juli'},
                {id: 8, name: 'Agustus'},
                {id: 9, name: 'September'},
                {id: 10, name: 'Oktober'},
                {id: 11, name: 'November'},
                {id: 12, name: 'Desember'}
              ]}
              validate  = {validation.required}
            />
            <Field
              label     = 'Ke Masa'
              name      = 'masa2'
              className = "md-cell md-cell--6"
              component = {Searchfieldv2}
              options   = {[
                {id: 1, name: 'Januari'},
                {id: 2, name: 'Februari'},
                {id: 3, name: 'Maret'},
                {id: 4, name: 'April'},
                {id: 5, name: 'Mei'},
                {id: 6, name: 'Juni'},
                {id: 7, name: 'Juli'},
                {id: 8, name: 'Agustus'},
                {id: 9, name: 'September'},
                {id: 10, name: 'Oktober'},
                {id: 11, name: 'November'},
                {id: 12, name: 'Desember'}
              ]}
              validate  = {validation.required}
            />
            <Field
              label     = 'Dari Tahun'
              name      = 'tahun'
              className = "md-cell md-cell--12"
              component = {Textfield}
              type      = "number"
              validate  = {validation.required}
            />
          </div>
        </div>
      )
    }))
    if(data){
      var formData = {}
      formData.masa1 = data.masa1
      formData.masa2 = data.masa2
      formData.tahun = data.tahun
      formData.npwp = ""
      data.npwp.map((d)=> {
        formData.npwp = formData.npwp + d.npwp
      })
      try {
        this.setState({ loadingPage: true })
        var desc = "export"
        var report = await this.service.api.rekapSpt(formData)
        window.open(report.data.url)
        // var filename = report.headers.filename
        // download(report.data, filename);
        iziToast.success({
          title: "Success",
          message: "proses "+ desc +" spt berhasil"
        })
        this.setState({ loadingPage: false })
      } catch (e){
        iziToast.error({
          title: "Error",
          message: "proses "+ desc +" spt gagal, " + e.message
        })
        this.setState({ loadingPage: false })
      }
    }
  }

  async sspItem(){
    var org = []
    if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
    var isSupport = false
    if(this.props.user && this.props.user.role && this.props.user.role.name == "SUPPORT_AGENT") isSupport = true
    var data = await this.context.showDialog(()=> ({
      title: "Export Summary SSP SPT",
      width: 800,
      height: 600,
      okText: "Export",
      cancelText: "Close",
      okText2: "Export Pisah",
      okText3: "Export Gabung",
      initialValue: {
        action3: null,
        action2: null
      },
      text: (
        <div style={{ height: 450 }}>
          <div className="md-grid">
          {!isSupport &&
            <Field
                label     = 'Organization'
                name      = 'npwp'
                id        = "spt2126-organization"
                className = "md-cell md-cell--12"
                component = {Multiselect}
                valueField = 'parent'
                options   = {org}
                validate  = {validation.required}
                itemTemplate = {function(d) {
                  let nameAndAlias = ""
                  if (d.name)
                    nameAndAlias = d.name
                  if (d.aliasName)
                    nameAndAlias += " - " + d.aliasName
                  return {
                    primaryText  : nameAndAlias,
                    secondaryText: d.npwp,
                    onClick      : () => {
                      if(!this.props.disabled){
                        this.handleItemClick(d); this.hide()
                      }
                    }
                  }
                }}
              />
            }
            {isSupport &&
              <Field
                  label         = 'Organization'
                  name          = 'npwp'
                  className     = "md-cell md-cell--12"
                  component     = {Multiselectv2}
                  valueField    = 'parent'
                  remoteSearch  = {true}
                  apiPath       = 'getAll'
                  params        = {{ user: this.props.user }}
                  service       = {OrganizationService}
                  validate      = {validation.required}
                  renderFirst   = {true}
                  validate      = {validation.required}
                  coreSearch    = {true}
                  npwpSearch    = {true}
                  itemTemplate  = {function(d) {
                    let nameAndAlias = ""
                    if (d.name)
                      nameAndAlias = d.name
                    if (d.aliasName)
                      nameAndAlias += " - " + d.aliasName
                    return {
                      primaryText  : nameAndAlias,
                      secondaryText: d.npwp,
                      onClick      : () => {
                        if(!this.props.disabled){
                          this.handleItemClick(d); this.hide()
                        }
                      }
                    }
                  }}
              />
            }
            <Field
              label     = 'Dari Masa'
              name      = 'masa1'
              className = "md-cell md-cell--6"
              component = {Searchfieldv2}
              options   = {[
                {id: 1, name: 'Januari'},
                {id: 2, name: 'Februari'},
                {id: 3, name: 'Maret'},
                {id: 4, name: 'April'},
                {id: 5, name: 'Mei'},
                {id: 6, name: 'Juni'},
                {id: 7, name: 'Juli'},
                {id: 8, name: 'Agustus'},
                {id: 9, name: 'September'},
                {id: 10, name: 'Oktober'},
                {id: 11, name: 'November'},
                {id: 12, name: 'Desember'}
              ]}
              validate  = {validation.required}
            />
            <Field
              label     = 'Ke Masa'
              name      = 'masa2'
              className = "md-cell md-cell--6"
              component = {Searchfieldv2}
              options   = {[
                {id: 1, name: 'Januari'},
                {id: 2, name: 'Februari'},
                {id: 3, name: 'Maret'},
                {id: 4, name: 'April'},
                {id: 5, name: 'Mei'},
                {id: 6, name: 'Juni'},
                {id: 7, name: 'Juli'},
                {id: 8, name: 'Agustus'},
                {id: 9, name: 'September'},
                {id: 10, name: 'Oktober'},
                {id: 11, name: 'November'},
                {id: 12, name: 'Desember'}
              ]}
              validate  = {validation.required}
            />
            <Field
              label     = 'Dari Tahun'
              name      = 'tahun1'
              className = "md-cell md-cell--6"
              component = {Textfield}
              type      = "number"
              validate  = {validation.required}
            />
            <Field
              label     = 'Ke Tahun'
              name      = 'tahun2'
              className = "md-cell md-cell--6"
              component = {Textfield}
              type      = "number"
              validate  = {validation.required}
            />
          </div>
        </div>
      )
    }))
    if(data){
      var formData = {}
      formData.masa1 = data.masa1
      formData.masa2 = data.masa2
      formData.tahun1 = data.tahun1
      formData.tahun2 = data.tahun2
      formData.npwp = ""
      data.npwp.map((d)=> {
        formData.npwp = formData.npwp + d.npwp
      })
      try {
        this.setState({ loadingPage: true })
        var desc = "export"
        if(localStorage.getItem("action3") == "true"){
          var report = await this.service.api.summaryGabung(formData)
          localStorage.removeItem("action3")
        } else if(localStorage.getItem("action2") == "true"){
          var report = await this.service.api.summaryPisah(formData)
          localStorage.removeItem("action2")
        } else {
          var report = await this.service.api.summarySspSpt(formData)
        }
        window.open(report.data.url)
        // var filename = report.headers.filename
        // download(report.data, filename);
        iziToast.success({
          title: "Success",
          message: "proses "+ desc +" spt berhasil"
        })
        this.setState({ loadingPage: false })
      } catch (e){
        // iziToast.error({
        //   title: "Error",
        //   message: "proses "+ desc +" spt gagal, " + e.message
        // })
        this.setState({ loadingPage: false })
      }
    }
  }

  async pdfBpItem(){
    var org = []
    if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
    var isSupport = false
    if(this.props.user && this.props.user.role && this.props.user.role.name == "SUPPORT_AGENT") isSupport = true
    var data = await this.context.showDialog(()=> ({
      title: "Export PDF BP",
      width: 800,
      height: 600,
      okText: "Export",
      cancelText: "Close",
      initialValue: {},
      text: (
        <div style={{ height: 450 }}>
          <div className="md-grid">
            {!isSupport &&
            <Field
              label     = 'Organization'
              name      = 'npwp'
              id        = "spt2126-organization"
              className = "md-cell md-cell--12"
              component = {Multiselect}
              valueField = 'parent'
              options   = {org}
              /*validate  = {validation.required}*/
              itemTemplate = {function(d) {
                let nameAndAlias = ""
                if (d.name)
                  nameAndAlias = d.name
                if (d.aliasName)
                  nameAndAlias += " - " + d.aliasName
                return {
                  primaryText  : nameAndAlias,
                  secondaryText: d.npwp,
                  onClick      : () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}
            />
            }
            {isSupport &&
            <Field
              label         = 'Organization'
              name          = 'npwp'
              className     = "md-cell md-cell--12"
              component     = {Multiselectv2}
              valueField    = 'parent'
              remoteSearch  = {true}
              apiPath       = 'getAll'
              params        = {{ user: this.props.user }}
              service       = {OrganizationService}
              /*validate      = {validation.required}*/
              renderFirst   = {true}
              coreSearch    = {true}
              npwpSearch    = {true}
              itemTemplate  = {function(d) {
                let nameAndAlias = ""
                if (d.name)
                  nameAndAlias = d.name
                if (d.aliasName)
                  nameAndAlias += " - " + d.aliasName
                return {
                  primaryText  : nameAndAlias,
                  secondaryText: d.npwp,
                  onClick      : () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}
            />
            }
            <Field
              label     = 'Dari Masa'
              name      = 'masa1'
              className = "md-cell md-cell--6"
              component = {Searchfieldv2}
              options   = {[
                {id: 1, name: 'Januari'},
                {id: 2, name: 'Februari'},
                {id: 3, name: 'Maret'},
                {id: 4, name: 'April'},
                {id: 5, name: 'Mei'},
                {id: 6, name: 'Juni'},
                {id: 7, name: 'Juli'},
                {id: 8, name: 'Agustus'},
                {id: 9, name: 'September'},
                {id: 10, name: 'Oktober'},
                {id: 11, name: 'November'},
                {id: 12, name: 'Desember'}
              ]}
              validate  = {validation.required}
            />
            <Field
              label     = 'Ke Masa'
              name      = 'masa2'
              className = "md-cell md-cell--6"
              component = {Searchfieldv2}
              options   = {[
                {id: 1, name: 'Januari'},
                {id: 2, name: 'Februari'},
                {id: 3, name: 'Maret'},
                {id: 4, name: 'April'},
                {id: 5, name: 'Mei'},
                {id: 6, name: 'Juni'},
                {id: 7, name: 'Juli'},
                {id: 8, name: 'Agustus'},
                {id: 9, name: 'September'},
                {id: 10, name: 'Oktober'},
                {id: 11, name: 'November'},
                {id: 12, name: 'Desember'}
              ]}
              validate  = {validation.required}
            />
            <Field
              label     = 'Tahun'
              name      = 'tahun'
              className = "md-cell md-cell--6"
              component = {Textfield}
              type      = "number"
              validate  = {validation.required}
            />
            <Field
              label     = 'All Organization'
              name      = 'allNpwp'
              className = "md-cell md-cell--3"
              component = {Switch}
            />
            <Field
              label     = 'Merge'
              name      = 'isMerge'
              className = "md-cell md-cell--3"
              component = {Switch}
            />
          </div>
        </div>
      )
    }))
    if(data){
      var formData = {}
      formData.masa1 = data.masa1
      formData.masa2 = data.masa2
      formData.tahun = data.tahun
      formData.allNpwp = data.allNpwp
      formData.merge = data.isMerge ? "Y" : "N"
      formData.npwps = []
      if (data.npwp) {
        data.npwp.map((d)=> {
          formData.npwps.push(d.npwp)
        })
      }
      try {
        var desc = "Export PDF BP"
        this.setState({ loadingPage: true })
        var report = await this.service.api.exportPdfBp(formData)
        iziToast.success({
          title: "Success",
          message: report.data.message
        })
        this.setState({ loadingPage: false })
      } catch (e){
        this.setState({ loadingPage: false })
      }
    }
  }

  async pdfBpeItem(){
    var org = []
    if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
    var isSupport = false
    if(this.props.user && this.props.user.role && this.props.user.role.name == "SUPPORT_AGENT") isSupport = true
    var data = await this.context.showDialog(()=> ({
      title: "Export PDF BPE",
      width: 800,
      height: 600,
      okText: "Export",
      cancelText: "Close",
      initialValue: {},
      text: (
        <div style={{ height: 450 }}>
          <div className="md-grid">
            {!isSupport &&
            <Field
              label     = 'Organization'
              name      = 'npwp'
              id        = "spt2126-organization"
              className = "md-cell md-cell--12"
              component = {Multiselect}
              valueField = 'parent'
              options   = {org}
              /*validate  = {validation.required}*/
              itemTemplate = {function(d) {
                let nameAndAlias = ""
                if (d.name)
                  nameAndAlias = d.name
                if (d.aliasName)
                  nameAndAlias += " - " + d.aliasName
                return {
                  primaryText  : nameAndAlias,
                  secondaryText: d.npwp,
                  onClick      : () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}
            />
            }
            {isSupport &&
            <Field
              label         = 'Organization'
              name          = 'npwp'
              className     = "md-cell md-cell--12"
              component     = {Multiselectv2}
              valueField    = 'parent'
              remoteSearch  = {true}
              apiPath       = 'getAll'
              params        = {{ user: this.props.user }}
              service       = {OrganizationService}
              /*validate      = {validation.required}*/
              renderFirst   = {true}
              coreSearch    = {true}
              npwpSearch    = {true}
              itemTemplate  = {function(d) {
                let nameAndAlias = ""
                if (d.name)
                  nameAndAlias = d.name
                if (d.aliasName)
                  nameAndAlias += " - " + d.aliasName
                return {
                  primaryText  : nameAndAlias,
                  secondaryText: d.npwp,
                  onClick      : () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}
            />
            }
            <Field
              label     = 'Dari Masa'
              name      = 'masa1'
              className = "md-cell md-cell--6"
              component = {Searchfieldv2}
              options   = {[
                {id: 1, name: 'Januari'},
                {id: 2, name: 'Februari'},
                {id: 3, name: 'Maret'},
                {id: 4, name: 'April'},
                {id: 5, name: 'Mei'},
                {id: 6, name: 'Juni'},
                {id: 7, name: 'Juli'},
                {id: 8, name: 'Agustus'},
                {id: 9, name: 'September'},
                {id: 10, name: 'Oktober'},
                {id: 11, name: 'November'},
                {id: 12, name: 'Desember'}
              ]}
              validate  = {validation.required}
            />
            <Field
              label     = 'Ke Masa'
              name      = 'masa2'
              className = "md-cell md-cell--6"
              component = {Searchfieldv2}
              options   = {[
                {id: 1, name: 'Januari'},
                {id: 2, name: 'Februari'},
                {id: 3, name: 'Maret'},
                {id: 4, name: 'April'},
                {id: 5, name: 'Mei'},
                {id: 6, name: 'Juni'},
                {id: 7, name: 'Juli'},
                {id: 8, name: 'Agustus'},
                {id: 9, name: 'September'},
                {id: 10, name: 'Oktober'},
                {id: 11, name: 'November'},
                {id: 12, name: 'Desember'}
              ]}
              validate  = {validation.required}
            />
            <Field
              label     = 'Tahun'
              name      = 'tahun'
              className = "md-cell md-cell--6"
              component = {Textfield}
              type      = "number"
              validate  = {validation.required}
            />
            <Field
              label     = 'All Organization'
              name      = 'allNpwp'
              className = "md-cell md-cell--6"
              component = {Switch}
            />
          </div>
        </div>
      )
    }))
    if(data){
      var formData = {}
      formData.masa1 = data.masa1
      formData.masa2 = data.masa2
      formData.tahun = data.tahun
      formData.allNpwp = data.allNpwp
      formData.npwps = []
      if (data.npwp) {
        data.npwp.map((d)=> {
          formData.npwps.push(d.npwp)
        })
      }
      try {
        var desc = "Export PDF BPE"
        this.setState({ loadingPage: true })
        var report = await this.service.api.exportPdfBpe(formData)
        iziToast.success({
          title: "Success",
          message: report.data.message
        })
        this.setState({ loadingPage: false })
      } catch (e){
        this.setState({ loadingPage: false })
      }
    }
  }

  async pdfIndukItem(){
    var org = []
    if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
    var isSupport = false
    if(this.props.user && this.props.user.role && this.props.user.role.name == "SUPPORT_AGENT") isSupport = true
    var data = await this.context.showDialog(()=> ({
      title: "Export PDF Induk",
      width: 800,
      height: 600,
      okText: "Export",
      cancelText: "Close",
      initialValue: {},
      text: (
        <div style={{ height: 450 }}>
          <div className="md-grid">
            {!isSupport &&
            <Field
              label     = 'Organization'
              name      = 'npwp'
              id        = "spt2126-organization"
              className = "md-cell md-cell--12"
              component = {Multiselect}
              valueField = 'parent'
              options   = {org}
              /*validate  = {validation.required}*/
              itemTemplate = {function(d) {
                let nameAndAlias = ""
                if (d.name)
                  nameAndAlias = d.name
                if (d.aliasName)
                  nameAndAlias += " - " + d.aliasName
                return {
                  primaryText  : nameAndAlias,
                  secondaryText: d.npwp,
                  onClick      : () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}
            />
            }
            {isSupport &&
            <Field
              label         = 'Organization'
              name          = 'npwp'
              className     = "md-cell md-cell--12"
              component     = {Multiselectv2}
              valueField    = 'parent'
              remoteSearch  = {true}
              apiPath       = 'getAll'
              params        = {{ user: this.props.user }}
              service       = {OrganizationService}
              /*validate      = {validation.required}*/
              renderFirst   = {true}
              coreSearch    = {true}
              npwpSearch    = {true}
              itemTemplate  = {function(d) {
                let nameAndAlias = ""
                if (d.name)
                  nameAndAlias = d.name
                if (d.aliasName)
                  nameAndAlias += " - " + d.aliasName
                return {
                  primaryText  : nameAndAlias,
                  secondaryText: d.npwp,
                  onClick      : () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}
            />
            }
            <Field
              label     = 'Dari Masa'
              name      = 'masa1'
              className = "md-cell md-cell--6"
              component = {Searchfieldv2}
              options   = {[
                {id: 1, name: 'Januari'},
                {id: 2, name: 'Februari'},
                {id: 3, name: 'Maret'},
                {id: 4, name: 'April'},
                {id: 5, name: 'Mei'},
                {id: 6, name: 'Juni'},
                {id: 7, name: 'Juli'},
                {id: 8, name: 'Agustus'},
                {id: 9, name: 'September'},
                {id: 10, name: 'Oktober'},
                {id: 11, name: 'November'},
                {id: 12, name: 'Desember'}
              ]}
              validate  = {validation.required}
            />
            <Field
              label     = 'Ke Masa'
              name      = 'masa2'
              className = "md-cell md-cell--6"
              component = {Searchfieldv2}
              options   = {[
                {id: 1, name: 'Januari'},
                {id: 2, name: 'Februari'},
                {id: 3, name: 'Maret'},
                {id: 4, name: 'April'},
                {id: 5, name: 'Mei'},
                {id: 6, name: 'Juni'},
                {id: 7, name: 'Juli'},
                {id: 8, name: 'Agustus'},
                {id: 9, name: 'September'},
                {id: 10, name: 'Oktober'},
                {id: 11, name: 'November'},
                {id: 12, name: 'Desember'}
              ]}
              validate  = {validation.required}
            />
            <Field
              label     = 'Tahun'
              name      = 'tahun'
              className = "md-cell md-cell--6"
              component = {Textfield}
              type      = "number"
              validate  = {validation.required}
            />
            <Field
              label     = 'All Organization'
              name      = 'allNpwp'
              className = "md-cell md-cell--6"
              component = {Switch}
            />
          </div>
        </div>
      )
    }))
    if(data){
      var formData = {}
      formData.masa1 = data.masa1
      formData.masa2 = data.masa2
      formData.tahun = data.tahun
      formData.allNpwp = data.allNpwp
      formData.npwps = []
      if (data.npwp) {
        data.npwp.map((d)=> {
          formData.npwps.push(d.npwp)
        })
      }
      try {
        var desc = "Export PDF Induk"
        this.setState({ loadingPage: true })
        var report = await this.service.api.exportPdfInduk(formData)
        iziToast.success({
          title: "Success",
          message: report.data.message
        })
        this.setState({ loadingPage: false })
      } catch (e){
        this.setState({ loadingPage: false })
      }
    }
  }

  async exportBpItem(){
    var org = []
    if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
    var isSupport = false
    if(this.props.user && this.props.user.role && this.props.user.role.name == "SUPPORT_AGENT") isSupport = true
    var data = await this.context.showDialog(()=> ({
      title: "Export BP",
      width: 800,
      height: 600,
      okText: "Export",
      cancelText: "Close",
      initialValue: {},
      text: (
        <div style={{ height: 450 }}>
          <div className="md-grid">
            {!isSupport &&
            <Field
              label     = 'Organization'
              name      = 'npwp'
              id        = "spt2126-organization"
              className = "md-cell md-cell--12"
              component = {Multiselect}
              valueField = 'parent'
              options   = {org}
              /*validate  = {validation.required}*/
              itemTemplate = {function(d) {
                let nameAndAlias = ""
                if (d.name)
                  nameAndAlias = d.name
                if (d.aliasName)
                  nameAndAlias += " - " + d.aliasName
                return {
                  primaryText  : nameAndAlias,
                  secondaryText: d.npwp,
                  onClick      : () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}
            />
            }
            {isSupport &&
            <Field
              label         = 'Organization'
              name          = 'npwp'
              className     = "md-cell md-cell--12"
              component     = {Multiselectv2}
              valueField    = 'parent'
              remoteSearch  = {true}
              apiPath       = 'getAll'
              params        = {{ user: this.props.user }}
              service       = {OrganizationService}
              /*validate      = {validation.required}*/
              renderFirst   = {true}
              coreSearch    = {true}
              npwpSearch    = {true}
              itemTemplate  = {function(d) {
                let nameAndAlias = ""
                if (d.name)
                  nameAndAlias = d.name
                if (d.aliasName)
                  nameAndAlias += " - " + d.aliasName
                return {
                  primaryText  : nameAndAlias,
                  secondaryText: d.npwp,
                  onClick      : () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}
            />
            }
            <Field
              label     = 'Dari Masa'
              name      = 'masa1'
              className = "md-cell md-cell--6"
              component = {Searchfieldv2}
              options   = {[
                {id: 1, name: 'Januari'},
                {id: 2, name: 'Februari'},
                {id: 3, name: 'Maret'},
                {id: 4, name: 'April'},
                {id: 5, name: 'Mei'},
                {id: 6, name: 'Juni'},
                {id: 7, name: 'Juli'},
                {id: 8, name: 'Agustus'},
                {id: 9, name: 'September'},
                {id: 10, name: 'Oktober'},
                {id: 11, name: 'November'},
                {id: 12, name: 'Desember'}
              ]}
              validate  = {validation.required}
            />
            <Field
              label     = 'Ke Masa'
              name      = 'masa2'
              className = "md-cell md-cell--6"
              component = {Searchfieldv2}
              options   = {[
                {id: 1, name: 'Januari'},
                {id: 2, name: 'Februari'},
                {id: 3, name: 'Maret'},
                {id: 4, name: 'April'},
                {id: 5, name: 'Mei'},
                {id: 6, name: 'Juni'},
                {id: 7, name: 'Juli'},
                {id: 8, name: 'Agustus'},
                {id: 9, name: 'September'},
                {id: 10, name: 'Oktober'},
                {id: 11, name: 'November'},
                {id: 12, name: 'Desember'}
              ]}
              validate  = {validation.required}
            />
            <Field
              label     = 'Tahun'
              name      = 'tahun'
              className = "md-cell md-cell--6"
              component = {Textfield}
              type      = "number"
              validate  = {validation.required}
            />
            <Field
              label     = 'All Organization'
              name      = 'allNpwp'
              className = "md-cell md-cell--6"
              component = {Switch}
            />
          </div>
        </div>
      )
    }))
    if(data){
      var formData = {}
      formData.masa1 = data.masa1
      formData.masa2 = data.masa2
      formData.tahun = data.tahun
      formData.allNpwp = data.allNpwp
      formData.npwps = []
      if (data.npwp) {
        data.npwp.map((d)=> {
          formData.npwps.push(d.npwp)
        })
      }
      try {
        var desc = "Export BP"
        this.setState({ loadingPage: true })
        var report = await this.service.api.exportBp(formData)
        iziToast.success({
          title: "Success",
          message: report.data.message
        })
        this.setState({ loadingPage: false })
      } catch (e){
        this.setState({ loadingPage: false })
      }
    }
  }

  async bpItem(){
    var org = []
    if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
    var isSupport = false
    if(this.props.user && this.props.user.role && this.props.user.role.name == "SUPPORT_AGENT") isSupport = true
    var data = await this.context.showDialog(()=> ({
      title: "Export Summary BP SPT",
      width: 800,
      height: 600,
      okText: "Export",
      cancelText: "Close",
      initialValue: {},
      text: (
        <div style={{ height: 450 }}>
          <div className="md-grid">
            {!isSupport &&
            <Field
              label     = 'Organization'
              name      = 'npwp'
              id        = "spt2126-organization"
              className = "md-cell md-cell--12"
              component = {Multiselect}
              valueField = 'parent'
              options   = {org}
              validate  = {validation.required}
              itemTemplate = {function(d) {
                let nameAndAlias = ""
                if (d.name)
                  nameAndAlias = d.name
                if (d.aliasName)
                  nameAndAlias += " - " + d.aliasName
                return {
                  primaryText  : nameAndAlias,
                  secondaryText: d.npwp,
                  onClick      : () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}
            />
            }
            {isSupport &&
            <Field
              label         = 'Organization'
              name          = 'npwp'
              className     = "md-cell md-cell--12"
              component     = {Multiselectv2}
              valueField    = 'parent'
              remoteSearch  = {true}
              apiPath       = 'getAll'
              params        = {{ user: this.props.user }}
              service       = {OrganizationService}
              validate      = {validation.required}
              renderFirst   = {true}
              validate      = {validation.required}
              coreSearch    = {true}
              npwpSearch    = {true}
              itemTemplate  = {function(d) {
                let nameAndAlias = ""
                if (d.name)
                  nameAndAlias = d.name
                if (d.aliasName)
                  nameAndAlias += " - " + d.aliasName
                return {
                  primaryText  : nameAndAlias,
                  secondaryText: d.npwp,
                  onClick      : () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}
            />
            }
            <Field
              label     = 'Dari Masa'
              name      = 'masa1'
              className = "md-cell md-cell--6"
              component = {Searchfieldv2}
              options   = {[
                {id: 1, name: 'Januari'},
                {id: 2, name: 'Februari'},
                {id: 3, name: 'Maret'},
                {id: 4, name: 'April'},
                {id: 5, name: 'Mei'},
                {id: 6, name: 'Juni'},
                {id: 7, name: 'Juli'},
                {id: 8, name: 'Agustus'},
                {id: 9, name: 'September'},
                {id: 10, name: 'Oktober'},
                {id: 11, name: 'November'},
                {id: 12, name: 'Desember'}
              ]}
              validate  = {validation.required}
            />
            <Field
              label     = 'Ke Masa'
              name      = 'masa2'
              className = "md-cell md-cell--6"
              component = {Searchfieldv2}
              options   = {[
                {id: 1, name: 'Januari'},
                {id: 2, name: 'Februari'},
                {id: 3, name: 'Maret'},
                {id: 4, name: 'April'},
                {id: 5, name: 'Mei'},
                {id: 6, name: 'Juni'},
                {id: 7, name: 'Juli'},
                {id: 8, name: 'Agustus'},
                {id: 9, name: 'September'},
                {id: 10, name: 'Oktober'},
                {id: 11, name: 'November'},
                {id: 12, name: 'Desember'}
              ]}
              validate  = {validation.required}
            />
            <Field
              label     = 'Dari Tahun'
              name      = 'tahun1'
              className = "md-cell md-cell--6"
              component = {Textfield}
              type      = "number"
              validate  = {validation.required}
            />
            <Field
              label     = 'Ke Tahun'
              name      = 'tahun2'
              className = "md-cell md-cell--6"
              component = {Textfield}
              type      = "number"
              validate  = {validation.required}
            />
          </div>
        </div>
      )
    }))
    if(data){
      var formData = {}
      formData.masa1 = data.masa1
      formData.masa2 = data.masa2
      formData.tahun1 = data.tahun1
      formData.tahun2 = data.tahun2
      formData.npwp = ""
      data.npwp.map((d)=> {
        formData.npwp = formData.npwp + d.npwp
      })
      try {
        var desc = "Summary BP SPT"
        this.setState({ loadingPage: true })
        var report = await this.service.api.summaryBpSpt(formData)
        window.open(report.data.url)
        // var filename = report.headers.filename
        // download(report.data, filename);
        iziToast.success({
          title: "Success",
          message: "proses "+ desc +" spt berhasil"
        })
        this.setState({ loadingPage: false })
      } catch (e){
        // iziToast.error({
        //   title: "Error",
        //   message: "proses "+ desc +" spt gagal, " + e.message
        // })
        this.setState({ loadingPage: false })
      }
    }

  }

  handleSyncOrg = async (items, callback) => {
    try {
      var dialog = await this.context.showDialog(()=> ({
        title: counterpart('word.syncOrg'),
        text: (
          <div>
            {counterpart('word.confirm')}
          </div>
        )
      }))
      if(dialog){
        var proses = counterpart('word.syncOrg')
        this.setState({ loadingPage: true })
        var selectedData = this.props.table.selected
        var errors = []
        if (selectedData.length > 0) {
          try{
            Bluebird.mapSeries(selectedData, async (di, di2) => {
              setTimeout(async () => {
                try {
                  await this.service.api.syncOrg(di.id)
                } catch (e) {
                  errors.push(e.message)
                }
                if ((di2 + 1) == selectedData.length) {
                  if (errors.length == 0) {
                    iziToast.success({
                      title: proses,
                      message: `${counterpart('word.proses')} ${proses} ${counterpart('word.prosesDone')}`
                    })
                  } else {
                    // DISPLAY ERROR LIST
                      var res = await this.context.showDialog(() => ({
                        title : '',
                        width: 800,
                        height: 'auto',
                        contentStyle: {
                          width: 800,
                          height: 'auto'
                        },
                        okText: " ",
                        cancelText: "Tutup",
                        text: (
                          <div>
                            {errors.map((er) => {
                              return <ListItem primaryText={''} secondaryText={er}></ListItem>
                            })}
                          </div>
                        )
                      }))
                    if (res) {
  
                    }
                  }
                }
              }, 1000 * di2)
            })
            this.setState({ loadingPage: false })
            this.removeCheckbox()
          }catch(e){
            this.setState({ loadingPage: false })
            console.log(e, 'cek')
          }
        } else {
          this.setState({ loadingPage: false })
        }
      }
    } catch (e) {
      this.setState({ loadingPage: false })
      iziToast.error({
        title : counterpart('word.syncOrg'),
        message : e.message
      })
    }
  }

  _barActions = [
    {
      label:'word.syncOrg',
      iconClassName:'mdi mdi-sync',
      onClick: async () => {
        await this.handleSyncOrg()
      },
      disabledFunc:() => this.props.table.selected.length === 0
    },
    {
      label:'word.pdfBp',
      iconClassName:'mdi mdi-file-pdf',
      onClick:() => this.pdfBpItem()
    },
    {
      label:'word.pdfBpe',
      iconClassName:'mdi mdi-file-pdf',
      onClick:() => this.pdfBpeItem()
    },
    {
      label:'word.pdfInduk',
      iconClassName:'mdi mdi-file-pdf',
      onClick:() => this.pdfIndukItem()
    },
    {
      label:'word.exportBp',
      iconClassName:'mdi mdi-file-excel',
      onClick:() => this.exportBpItem()
    },
    {
      label:'word.summaryBP',
      iconClassName:'mdi mdi-file-excel',
      onClick:() => this.bpItem()
    },
    {
      label:'word.summarySpt',
      iconClassName:'mdi mdi-file-excel',
      onClick:() => this.sspItem()
    },
    {
      label:'word.summaryRekap',
      iconClassName:'mdi mdi-file-excel',
      onClick:() => this.rekapItem()
    },
    {
      label:'word._',
      iconClassName:'hide-me',
      divider: true,
    },
    {
      label:'word.create',
      iconClassName:'mdi mdi-plus',
      onClick:() => this.addItem()
    },
    // {
    //   label:'word.export',
    //   iconClassName:'mdi mdi-file-excel',
    //   onClick:() => this.exportData()
    // },
    // {
    //   label:'word.check',
    //   iconClassName:'mdi mdi-check',
    //   onClick:() => this.bulkCheck()
    // },
    {
      label:'word.posting',
      iconClassName:'mdi mdi-file',
      onClick:() => this.bulkPosting()
    },
    {
      label:'word.sending',
      iconClassName:'mdi mdi-send',
      onClick:() => this.bulkSending()
    },
    {
      label:'word.revision',
      iconClassName:'mdi mdi-file-document',
      onClick:() => this.bulkRevision()
    },
    {
      label:'word.delete',
      iconClassName:'mdi mdi-delete',
      onClick:() => this.bulkDelete()
    },
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => this.fetchData()
    },
  ]

  _tableActionsFunc(){
    var support = false
    if(this.props.user && this.props.user.isSupport) support = this.props.user.isSupport
    if(support){
      return [
        {
          label:"Buka SPT",
          iconClassName:"mdi mdi-book-open",
          onClick: (item) => this.props.history.push(`ebupot-23/${item.id}/open`),
        },
        {
          label:"More Info",
          iconClassName:"mdi mdi-bell",
          onClick: (item) => this.editItem(item),
          ifCon: 'item.status != "FINISH" && item.status != "POSTING"'
        },
        // {
        //   label:"Check",
        //   iconClassName:"mdi mdi-check",
        //   onClick: (item) => this.checkSpt(item),
        //   ifCon: 'item.status == "CREATED" || item.status == "SUBMIT" || item.status == "POSTING"'
        // },
        {
          label:"Posting",
          iconClassName:"mdi mdi-file",
          onClick: (item) => this.postingSpt(item),
          // ifCon: 'item.status == "SENDING" || item.status == "SUBMIT"'
          ifCon: 'item.status != "FINISH"'
        },
        {
          label:"Sending",
          iconClassName:"mdi mdi-file-send",
          onClick: (item) => this.sendingSpt(item),
          ifCon: 'item.status == "POSTING"'
        },
        {
          label:"Sending Backdate",
          iconClassName:"mdi mdi-file-xml",
          onClick: (item) => this.sendingSptBackDate(item)
        },
        {label:"divider", iconClassName:"-"},
        {
          label:"List BPE",
          iconClassName:"mdi mdi-file",
          onClick: (item) => this.downloadBPE(item),
          // ifCon: 'item.status == "FINISH"'
        },
        {
          label:"History SPT",
          iconClassName:"mdi mdi-file",
          onClick: (item) => this.historySPT(item),
          // ifCon: 'item.status == "FINISH"'
        },
        {
          label:"Revision",
          iconClassName:"mdi mdi-file-document",
          onClick: (item) => this.revisiItem(item),
          ifCon: 'item.status == "FINISH"'
        },
        {
          label:"Export",
          iconClassName:"mdi mdi-file-excel",
          onClick: (item) => this.exportItem(item)
        },
        {
          label:"Delete",
          iconClassName:"mdi mdi-delete",
          onClick:(item, callback) => this.deleteItem(item, callback),
          // ifCon: 'item.status == "CREATED"',
          ifCon: 'item.status != "FINISH" && item.status != "POSTING"',
          confirmation:{
            title:"sentence.custom.deleteItem",
            message:"sentence.custom.deleteItemConfirmation"
          }
        }
      ]
    } else {
      return [
        {
          label:"Buka SPT",
          iconClassName:"mdi mdi-book-open",
          onClick: (item) => this.props.history.push(`ebupot-23/${item.id}/open`),
        },
        {
          label:"More Info",
          iconClassName:"mdi mdi-bell",
          onClick: (item) => this.editItem(item),
          ifCon: 'item.status != "FINISH" && item.status != "POSTING"'
        },
        // {
        //   label:"Check",
        //   iconClassName:"mdi mdi-check",
        //   onClick: (item) => this.checkSpt(item),
        //   ifCon: 'item.status == "CREATED" || item.status == "SUBMIT" || item.status == "POSTING"'
        // },
        {
          label:"Posting",
          iconClassName:"mdi mdi-file",
          onClick: (item) => this.postingSpt(item),
          // ifCon: 'item.status == "SENDING" || item.status == "SUBMIT"'
          ifCon: 'item.status != "FINISH"'
        },
        {
          label:"Sending",
          iconClassName:"mdi mdi-file-send",
          onClick: (item) => this.sendingSpt(item),
          ifCon: 'item.status == "POSTING"'
        },
        {label:"divider", iconClassName:"-"},
        {
          label:"List BPE",
          iconClassName:"mdi mdi-file",
          onClick: (item) => this.downloadBPE(item),
          // ifCon: 'item.status == "FINISH"'
        },
        {
          label:"History SPT",
          iconClassName:"mdi mdi-file",
          onClick: (item) => this.historySPT(item),
          // ifCon: 'item.status == "FINISH"'
        },
        {
          label:"Revision",
          iconClassName:"mdi mdi-file-document",
          onClick: (item) => this.revisiItem(item),
          ifCon: 'item.status == "FINISH"'
        },
        {
          label:"Export",
          iconClassName:"mdi mdi-file-excel",
          onClick: (item) => this.exportItem(item)
        },
        {
          label:"Delete",
          iconClassName:"mdi mdi-delete",
          onClick:(item, callback) => this.deleteItem(item, callback),
          // ifCon: 'item.status == "CREATED"',
          ifCon: 'item.status != "FINISH" && item.status != "POSTING"',
          confirmation:{
            title:"sentence.custom.deleteItem",
            message:"sentence.custom.deleteItemConfirmation"
          }
        }
      ]
    }
  }

  // NEW TAB MENU
  async hapusPencarian(){
    try {
      try {
        localStorage.removeItem(`search_${this.service.name}`)
      } catch(e){}
      var params = Object.assign({}, params=this.props.table.params)
      this.fetchData(params)
      await this.props.dispatch(initialize(this.service.name+'_search', {}));
    } catch(e){
      console.log(e, "Search Error ...")
    }
  }

  async handleSearching(values){
    try {
      var formData = this.props.tableFilter2
      var params = Object.assign({}, params=this.props.table.params)
      if(formData.organization && formData.organization.length > 0){
        var npwps = ""
        formData.organization.map((d, index)=> {
          if(index == (formData.organization.length - 1)){
            npwps += d.npwp
          } else {
            npwps += d.npwp  + ","
          }
        })
        params['npwp.in'] = npwps
      } else { delete params['npwp.in'] }
      if(formData.pembetulan){
        params['rev.equals'] = formData.pembetulan
      } else { delete params['rev.equals'] }
      if(formData.masa1){
        params['month.greaterOrEqualThan'] = formData.masa1
      } else { delete params['month.greaterOrEqualThan'] }
      if(formData.masa2){
        params['month.lessOrEqualThan'] = formData.masa2
      } else { delete params['month.lessOrEqualThan'] }
      if(formData.tahun1){
        params['year.greaterOrEqualThan'] = formData.tahun1
      } else { delete params['year.greaterOrEqualThan'] }
      if(formData.tahun2){
        params['year.lessOrEqualThan'] = formData.tahun2
      } else { delete params['year.lessOrEqualThan'] }
      if(formData.orgNpwp){
        params['npwp.contains'] = formData.orgNpwp
      } else { delete params['npwp.contains'] }
      if(formData.orgName){
        params['name.contains'] = formData.orgName
      } else { delete params['name.contains'] }
      if(formData.status && formData.status.length > 0){
        var statuss = ""
        formData.status.map((d, index)=> {
          if(index == (formData.status.length - 1)){
            statuss += d
          } else {
            statuss += d  + ","
          }
        })
        params['status.in'] = statuss
      } else { delete params['status.in'] }
      params.onSearch = true
      localStorage.setItem("formData_search", JSON.stringify(formData))
      this.fetchData(params)
    } catch(e){
      iziToast.info({
        title: "Pencarian",
        message: "Data tidak ditemukan, " + e.message
      })
    }
  }

  defaultSearchForm(){
    const { handleSubmit, submitting, valid, pristine } = this.props;
    var org = []
    if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
    var isSupport = false
    if(this.props.user && this.props.user.role && this.props.user.role.name == "SUPPORT_AGENT") isSupport = true
    return (
      <div className="md-grid" style={{ overflow: 'auto' }}>
        {!isSupport &&
          <Field
            label     = 'Organization'
            name      = 'organization'
            id        = "spt2126-organization"
            className = "md-cell md-cell--12"
            component = {Multiselect}
            valueField = 'parent'
            options   = {org}
            validate  = {validation.required}
            itemTemplate = {function(d) {
              let nameAndAlias = ""
              if (d.name)
                nameAndAlias = d.name
              if (d.aliasName)
                nameAndAlias += " - " + d.aliasName
              return {
                primaryText  : nameAndAlias,
                secondaryText: d.npwp,
                onClick      : () => {
                  if(!this.props.disabled){
                    this.handleItemClick(d); this.hide()
                  }
                }
              }
            }}
          />
        }
        {isSupport &&
          <Field
              label         = 'Organization'
              name          = 'organization'
              className     = "md-cell md-cell--12"
              component     = {Multiselectv2}
              valueField    = 'parent'
              remoteSearch  = {true}
              apiPath       = 'getAll'
              params        = {{ user: this.props.user }}
              service       = {OrganizationService}
              validate      = {validation.required}
              renderFirst   = {true}
              validate      = {validation.required}
              coreSearch    = {true}
              npwpSearch    = {true}
              itemTemplate  = {function(d) {
                let nameAndAlias = ""
                if (d.name)
                  nameAndAlias = d.name
                if (d.aliasName)
                  nameAndAlias += " - " + d.aliasName
                return {
                  primaryText  : nameAndAlias,
                  secondaryText: d.npwp,
                  onClick      : () => {
                    if(!this.props.disabled){
                      this.handleItemClick(d); this.hide()
                    }
                  }
                }
              }}
          />
        }
        <Field
          label     = 'Dari Masa'
          name      = 'masa1'
          className = "md-cell md-cell--6"
          component = {Searchfieldv2}
          options   = {[
            {id: 1, name: 'Januari'},
            {id: 2, name: 'Februari'},
            {id: 3, name: 'Maret'},
            {id: 4, name: 'April'},
            {id: 5, name: 'Mei'},
            {id: 6, name: 'Juni'},
            {id: 7, name: 'Juli'},
            {id: 8, name: 'Agustus'},
            {id: 9, name: 'September'},
            {id: 10, name: 'Oktober'},
            {id: 11, name: 'November'},
            {id: 12, name: 'Desember'}
          ]}
        />
        <Field
          label     = 'Ke Masa'
          name      = 'masa2'
          className = "md-cell md-cell--6"
          component = {Searchfieldv2}
          options   = {[
            {id: 1, name: 'Januari'},
            {id: 2, name: 'Februari'},
            {id: 3, name: 'Maret'},
            {id: 4, name: 'April'},
            {id: 5, name: 'Mei'},
            {id: 6, name: 'Juni'},
            {id: 7, name: 'Juli'},
            {id: 8, name: 'Agustus'},
            {id: 9, name: 'September'},
            {id: 10, name: 'Oktober'},
            {id: 11, name: 'November'},
            {id: 12, name: 'Desember'}
          ]}
        />
        <Field
          label     = 'Dari Tahun'
          name      = 'tahun1'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Ke Tahun'
          name      = 'tahun2'
          className = "md-cell md-cell--6"
          component = {Textfield}
        />
        <Field
          label     = 'Pembetulan'
          name      = 'pembetulan'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        <Field
          label     = 'Npwp Organisasi'
          name      = 'orgNpwp'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        <Field
          label     = 'Nama Organisasi'
          name      = 'orgName'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        <Field
          label     = 'Status'
          name      = 'status'
          className = "md-cell md-cell--12"
          component = {Multiselect}
          noChip    = {true}
          options   = {[
            { id: null, name: ' ' },
            { id: 'CREATED', name: 'CREATED' },
            { id: 'SUBMIT', name: 'SUBMIT' },
            { id: 'POSTING', name: 'POSTING' },
            { id: 'SENDING', name: 'SENDING' },
            { id: 'FINISH', name: 'FINISH' }
          ]}
        />
        <hr />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.handleSearching.bind(this))}>Cari</Button>
        <hr />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.hapusPencarian.bind(this))}>Hapus Pencarian</Button>
      </div>
    )
  }
  // yg ditambah
  tabWidth = 280
  tabs= ["search", "summary", "column"]

  tabComponents() {
    var obj = this;
    return {
      summary: this.report.bind(obj)
    }
  }
  // end yg ditambah
  async handleExport(value){
    try {
      var desc = "download summary"
      this.setState({ loadingPage: true })
      var formData = {}
      formData.masa1 = value.masa1
      formData.masa2 = value.masa2
      formData.tahun1 = value.tahun1
      formData.tahun2 = value.tahun2
      formData.npwp = ""
      value.organization.map((d)=> {
        formData.npwp = formData.npwp + d.npwp
      })
      var report = await this.service.api.summarySpt(formData)
      window.open(report.data.url)
      // var filename = report.headers.filename
      // download(report.data, filename);
      iziToast.success({
        title: "Success",
        message: "proses "+ desc +" spt berhasil"
      })
      this.setState({ loadingPage: false })
    } catch (e){
      console.log(e)
      // iziToast.error({
      //   title: "Error",
      //   message: "proses "+ desc +" spt gagal, " + e.message
      // })
      this.setState({ loadingPage: false })
    }
  }
  report(){
   const { handleSubmit, submitting, valid, pristine } = this.props;
   var org = []
   if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
   var isSupport = false
   if(this.props.user && this.props.user.role && this.props.user.role.name == "SUPPORT_AGENT") isSupport = true
   return (
       <div className='mpk-layout column fill'>
         <div className='flex'>
           <div className='md-grid'>
           {!isSupport &&
              <Field
                label     = 'Organization'
                name      = 'organization'
                id        = "spt2126-organization"
                className = "md-cell md-cell--12"
                component = {Multiselect}
                valueField = 'parent'
                options   = {org}
                validate  = {validation.required}
                itemTemplate = {function(d) {
                  let nameAndAlias = ""
                  if (d.name)
                    nameAndAlias = d.name
                  if (d.aliasName)
                    nameAndAlias += " - " + d.aliasName
                  return {
                    primaryText  : nameAndAlias,
                    secondaryText: d.npwp,
                    onClick      : () => {
                      if(!this.props.disabled){
                        this.handleItemClick(d); this.hide()
                      }
                    }
                  }
                }}
              />
            }
            {isSupport &&
              <Field
                  label         = 'Organization'
                  name          = 'organization'
                  className     = "md-cell md-cell--12"
                  component     = {Multiselectv2}
                  valueField    = 'parent'
                  remoteSearch  = {true}
                  apiPath       = 'getAll'
                  params        = {{ user: this.props.user }}
                  service       = {OrganizationService}
                  validate      = {validation.required}
                  renderFirst   = {true}
                  validate      = {validation.required}
                  coreSearch    = {true}
                  npwpSearch    = {true}
                  itemTemplate  = {function(d) {
                    let nameAndAlias = ""
                    if (d.name)
                      nameAndAlias = d.name
                    if (d.aliasName)
                      nameAndAlias += " - " + d.aliasName
                    return {
                      primaryText  : nameAndAlias,
                      secondaryText: d.npwp,
                      onClick      : () => {
                        if(!this.props.disabled){
                          this.handleItemClick(d); this.hide()
                        }
                      }
                    }
                  }}
              />
            }
             <Field
               label     = 'Dari Masa'
               name      = 'masa1'
               className = "md-cell md-cell--6"
               component = {Searchfieldv2}
               options   = {[
                  {id: 1, name: 'Januari'},
                  {id: 2, name: 'Februari'},
                  {id: 3, name: 'Maret'},
                  {id: 4, name: 'April'},
                  {id: 5, name: 'Mei'},
                  {id: 6, name: 'Juni'},
                  {id: 7, name: 'Juli'},
                  {id: 8, name: 'Agustus'},
                  {id: 9, name: 'September'},
                  {id: 10, name: 'Oktober'},
                  {id: 11, name: 'November'},
                  {id: 12, name: 'Desember'}
               ]}
               validate  = {validation.required}
             />
             <Field
               label     = 'Ke Masa'
               name      = 'masa2'
               className = "md-cell md-cell--6"
               component = {Searchfieldv2}
               options   = {[
                  {id: 1, name: 'Januari'},
                  {id: 2, name: 'Februari'},
                  {id: 3, name: 'Maret'},
                  {id: 4, name: 'April'},
                  {id: 5, name: 'Mei'},
                  {id: 6, name: 'Juni'},
                  {id: 7, name: 'Juli'},
                  {id: 8, name: 'Agustus'},
                  {id: 9, name: 'September'},
                  {id: 10, name: 'Oktober'},
                  {id: 11, name: 'November'},
                  {id: 12, name: 'Desember'}
               ]}
               validate  = {validation.required}
             />
             <Field
               label     = 'Dari Tahun'
               name      = 'tahun1'
               className = "md-cell md-cell--6"
               component = {Textfield}
               type      = "number"
               validate  = {validation.required}
             />
             <Field
               label     = 'Ke Tahun'
               name      = 'tahun2'
               className = "md-cell md-cell--6"
               component = {Textfield}
               type      = "number"
               validate  = {validation.required}
             />
             <div className="md-cell md-cell--12">
                 <Button raised primary onClick={handleSubmit(this.handleExport.bind(this))}>Export</Button>
             </div>
           </div>
         </div>
       </div>
    )
  }
  // NEW TAB MENU


}
