import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress,
  Switch as MSwitch
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import BP21TFService from './BP_21_TF.service';
import NomorBPService from '../nomorBP.service';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import SPT21SignerService from '../SPT21Signer/SPT21Signer.service';
import NpwpService from './../../NpwpService';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import LawanTransaksiDialog from '../../../../../components/entity/LawanTransaksi.dialog';
import MasterLawanTransaksiPpService from '../../../Master/MasterLawanTransaksiPp/MasterLawanTransaksiPp.service';
import CountryCodeService from '../../../Master/CountryCode/CountryCode.service';
import Bp2023 from './BP_21_TF.form2023'
import Bp2024 from './BP_21_TF.form2024'

// @reduxForm({form: 'BP_21_TF_Form', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt: state.authEppt.currentSPT,
  formData:state.form.BP_21_TF_Form
}))
export default class BP21TFForm extends FormViewSpt {

    componentDidMount(){

    }

    render(){
        var year = new Date().getFullYear()
        try {
            year = this.props.spt.data.year
        } catch(e){}
        if(parseInt(year) <= 2023){
            return <Bp2023 {...this.props} />
        } else {
            return <Bp2024 {...this.props} />
        }
    }
  
}
