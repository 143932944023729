import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';

import FormViewSpt from '../../../../../components/entity/form.view.spt';
import NpwpService from './../../NpwpService';
import BP22Service from './BP22.service';
import nomorBP22Service from '../nomorBP22.service';
import LawanTransaksiDialog from './../../../../../components/entity/LawanTransaksi.dialog';
import MasterLawanTransaksi22Service from '../../../Master/MasterLawanTransaksi22/MasterLawanTransaksi22.service';
import F113303Form  from './form/F113303.form';
import F113304Form from './form/F113304.form';
import F113304SForm from './form/F113304S.form';
import F113304AForm from './form/F113304A.form';
import Master22TarifService from '../master/tarif/Tarif22.service';
import BlokBunifikasiService from 'app:modules/Main/pph/21_26/BlokBunifikasi/BlokBunifikasi.service';

@reduxForm({form: 'BP_22_Form', destroyOnUnmount: true, initialValues: {}})
@connect((state) => ({
  access      : state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt         : state.authEppt.currentSPT,
  formData    : getFormValues('BP_22_Form')(state),
  auth        : state.auth.company,
}))
export default class BP22Form extends FormViewSpt {
  service  = BP22Service
  viewType = 2;

  typingObj     = null;
  typingTimeout = 1000;


  initialData={

  }

  typeOptions = [{
    name   : 'Bukti Pungut Atas Impor (Oleh Ditjen Bea dan Cukai) F113303',
    objName: 'F113303',
    id     : 'F113303'
  },
  {
    name   : 'Bukti Pungut PPh Pasal 22 (Untuk Industri/Eksportir Tertentu) F113304',
    objName: 'F113304',
    id     : 'F113304'
  },{
    name   : 'Bukti Pungut PPh Pasal 22 (Untuk Industri/Importir BBM, Gas dan Pelumas) F113304S',
    objName: 'F113304S',
    id     : 'F113304S'
  },{
    name   : 'Bukti Pungut PPh Pasal 22 (Atas Pembelian Barang Oleh Badan Tertentu Yang Ditunjuk) F113304A',
    objName: 'F113304A',
    id     : 'F113304A'
  },]

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      showCalculateProgress: false,
      npwpNotValid         : true,
      lkDialog             : false
    }
  }

  async npwpValidation(evt, value){

    if(this.typingObj) clearTimeout(this.typingObj);

    this.typingObj = setTimeout(async ()=> {
      if(value){
        var res = await NpwpService.api.validate22(value)
        if(res.valid){
          this.setState({npwpNotValid: false})
          var lk = await MasterLawanTransaksi22Service.api.find({
            'npwp.equals': value,
            // 'organizationId.equals': this.props.spt.data.organization.id
          })

          var formData = {...this.props.formData};

          if(lk.data) {
            if(lk.data.length > 0) {
              var d             = lk.data[0];
              formData = {
                ...formData,
                "npwp"  : d.npwp,
                "nik"   : d.nik,
                "nama"  : d.name,
                "alamat": d.address,
                "email" : d.email
              };
            } else {
              formData.npwp = value
              this.toast.info({title: "Info", message: `Lawan Transaksi dengan npwp ${value} tidak ditemukan`})
            }
          }
          if(formData.npwp === '000000000000000' || formData.npwp === "") formData.non = true;

          if (formData.non) {
            formData.tarifSemen = formData.tarifSemen * 2
            formData.tarifKertas = formData.tarifKertas * 2
            formData.tarifBaja = formData.tarifBaja * 2
            formData.tarifOtomotif = formData.tarifOtomotif * 2
            formData.tarifFarmasi = formData.tarifFarmasi * 2
            formData.tarifTidakFinal = formData.tarifTidakFinal * 2
          }

          this.props.initialize(formData);
        } else {
          this.setState({npwpNotValid: true})
          this.toast.warning({title: "Validasi Npwp", message: "npwp tidak valid"})
        }
      }
    }, this.typingTimeout);
  }

  async beforeSave(value) {
    if(value.jmlBruto == 0 || value.jmlBruto == '') {
      this.toast.warning({title:'Warning', message:'Isi bruto harus lebih besar dari 0'})
      return false;
    }

    var date;
    if(typeof value.tgl === 'string') {
      if(value.tgl.search('/') != -1) {
        date = moment(value.tgl, 'DD/MM/YYYY');
      } else {
        date = moment(value.tgl, 'YYYY-MM-DD');
      }
    } else if(typeof value.tgl === 'object') {
      date = moment(value.tgl)
    }

    if(!(date.year() == this.props.spt.data.year && date.month()+1 == this.props.spt.data.month)) {
      this.toast.warning({title:'Warning', message:'Tanggal bukti potong harus sesuai dengan masa SPT'})
      return false;
    }

    // Remapping Objek
    var mapping = {
      "bpAtasBadanTertentu": {
        "desc52"    : value.uraian52 ? value.uraian52 : 0,
        "desc62"    : value.uraian62 ? value.uraian62 : 0,
        "desc72"    : value.uraianMewah ? value.uraianMewah : 0,
        "desc82"    : value.uraianSektorA ? value.uraianSektorA : 0,
        "desc92"    : value.uraianSektorB ? value.uraianSektorB : 0,
        "desc112"   : value.uraianLainya ? value.uraianLainya : 0,
        "gross13"   : value.brutoSemen ? value.brutoSemen : 0,
        "gross23"   : value.brutoKertas ? value.brutoKertas : 0,
        "gross33"   : value.brutoBaja ? value.brutoBaja : 0,
        "gross43"   : value.brutoOtomotif ? value.brutoOtomotif : 0,
        "gross53"   : value.bruto53 ? value.bruto53 : 0,
        "gross63"   : value.bruto63 ? value.bruto63 : 0,
        "gross73"   : value.brutoMewah ? value.brutoMewah : 0,
        "gross83"   : value.brutoSektorA ? value.brutoSektorA : 0,
        "gross93"   : value.brutoSektorB ? value.brutoSektorB : 0,
        "gross103"  : value.brutoBumnTertentu ? value.brutoBumnTertentu : 0,
        "gross113"  : value.brutoLainya ? value.brutoLainya : 0,
        "pph16"     : value.pphSemen ? value.pphSemen : 0,
        "pph26"     : value.pphKertas ? value.pphKertas : 0,
        "pph36"     : value.pphBaja ? value.pphBaja : 0,
        "pph46"     : value.pphOtomotif ? value.pphOtomotif : 0,
        "pph56"     : value.pph56 ? value.pph56 : 0,
        "pph66"     : value.pph66 ? value.pph66 : 0,
        "pph76"     : value.pphMewah ? value.pphMewah : 0,
        "pph86"     : value.pphSektorA ? value.pphSektorA : 0,
        "pph96"     : value.pphSektorB ? value.pphSektorB : 0,
        "pph106"    : value.pphBumnTertentu ? value.pphBumnTertentu : 0,
        "pph116"    : value.pphLainya ? value.pphLainya : 0,
        "rates15"   : value.tarifSemen ? value.tarifSemen : 0,
        "rates25"   : value.tarifKertas ? value.tarifKertas : 0,
        "rates35"   : value.tarifBaja ? value.tarifBaja : 0,
        "rates45"   : value.tarifOtomotif ? value.tarifOtomotif : 0,
        "rates55"   : value.tarif55 ? value.tarif55 : 0,
        "rates65"   : value.tarif65 ? value.tarif65 : 0,
        "rates75"   : value.tarifMewah ? value.tarifMewah : 0,
        "rates85"   : value.tarifSektorA ? value.tarifSektorA : 0,
        "rates95"   : value.tarifSektorB ? value.tarifSektorB : 0,
        "rates105"  : value.tarifBumnTertentu ? value.tarifBumnTertentu : 0,
        "rates115"  : value.tarifLainya ? value.tarifLainya : 0,
      },
      "bpAtasImporBendaharawan": {
        "nilaiImpor"  : value.nilaiImpor ? value.nilaiImpor : 0,
        "nop13"       : value.brutoPajakPertambahanNilai ? value.brutoPajakPertambahanNilai : 0,
        "nop23a"      : value.brutoPajakPenjualanAtasBarangMewahA ? value.brutoPajakPenjualanAtasBarangMewahA : 0,
        "nop23b"      : value.brutoPajakPenjualanAtasBarangMewahB ? value.brutoPajakPenjualanAtasBarangMewahB : 0,
        "nop23c"      : value.brutoPajakPenjualanAtasBarangMewahC ? value.brutoPajakPenjualanAtasBarangMewahC : 0,
        "nop23d"      : value.brutoPajakPenjualanAtasBarangMewahD ? value.brutoPajakPenjualanAtasBarangMewahD : 0,
        "nop23e"      : value.brutoPajakPenjualanAtasBarangMewahE ? value.brutoPajakPenjualanAtasBarangMewahE : 0,
        "nop23f"      : value.brutoPajakPenjualanAtasBarangMewahF ? value.brutoPajakPenjualanAtasBarangMewahF : 0,
        "nop23g"      : value.brutoPajakPenjualanAtasBarangMewahG ? value.brutoPajakPenjualanAtasBarangMewahG : 0,
        "nop23h"      : value.brutoPajakPenjualanAtasBarangMewahH ? value.brutoPajakPenjualanAtasBarangMewahH : 0,
        "nop33a"      : value.brutoApi ? value.brutoApi : 0,
        "nop33b"      : value.brutoNonApi ? value.brutoNonApi : 0,
        "pph15"       : value.pphPajakPertambahanNilai ? value.pphPajakPertambahanNilai : 0,
        "pph25a"      : value.pphPajakPenjualanAtasBarangMewahA ? value.pphPajakPenjualanAtasBarangMewahA : 0,
        "pph25b"      : value.pphPajakPenjualanAtasBarangMewahB ? value.pphPajakPenjualanAtasBarangMewahB : 0,
        "pph25c"      : value.pphPajakPenjualanAtasBarangMewahC ? value.pphPajakPenjualanAtasBarangMewahC : 0,
        "pph25d"      : value.pphPajakPenjualanAtasBarangMewahD ? value.pphPajakPenjualanAtasBarangMewahD : 0,
        "pph25e"      : value.pphPajakPenjualanAtasBarangMewahE ? value.pphPajakPenjualanAtasBarangMewahE : 0,
        "pph25f"      : value.pphPajakPenjualanAtasBarangMewahF ? value.pphPajakPenjualanAtasBarangMewahF : 0,
        "pph25g"      : value.pphPajakPenjualanAtasBarangMewahG ? value.pphPajakPenjualanAtasBarangMewahG : 0,
        "pph25h"      : value.pphPajakPenjualanAtasBarangMewahH ? value.pphPajakPenjualanAtasBarangMewahH : 0,
        "pph35a"      : value.pphApi ? value.pphApi : 0,
        "pph35b"      : value.pphNonApi ? value.pphNonApi : 0,
        "qty"         : value.qty,
        "rates14"     : value.tarifPajakPertambahanNilai ? value.tarifPajakPertambahanNilai : 0,
        "rates24a"    : value.tarifPajakPenjualanAtasBarangMewahA ? value.tarifPajakPenjualanAtasBarangMewahA : 0,
        "rates24b"    : value.tarifPajakPenjualanAtasBarangMewahB ? value.tarifPajakPenjualanAtasBarangMewahB : 0,
        "rates24c"    : value.tarifPajakPenjualanAtasBarangMewahC ? value.tarifPajakPenjualanAtasBarangMewahC : 0,
        "rates24d"    : value.tarifPajakPenjualanAtasBarangMewahD ? value.tarifPajakPenjualanAtasBarangMewahD : 0,
        "rates24e"    : value.tarifPajakPenjualanAtasBarangMewahE ? value.tarifPajakPenjualanAtasBarangMewahE : 0,
        "rates24f"    : value.tarifPajakPenjualanAtasBarangMewahF ? value.tarifPajakPenjualanAtasBarangMewahF : 0,
        "rates24g"    : value.tarifPajakPenjualanAtasBarangMewahG ? value.tarifPajakPenjualanAtasBarangMewahG : 0,
        "rates24h"    : value.tarifPajakPenjualanAtasBarangMewahH ? value.tarifPajakPenjualanAtasBarangMewahH : 0,
        "rates34a"    : value.tarifApi ? value.tarifApi : 0,
        "rates34b"    : value.tarifNonApi ? value.tarifNonApi : 0,
        "jenisBarang" : value.jenisBarang,
        "unit"        : value.satuan
      },
      "bpIndustriEksportir": {
        "desc62"      : value.uraian62 ? value.uraian62 : 0,
        "desc72"      : value.uraian72 ? value.uraian72 : 0,
        "desc82"      : value.uraian82 ? value.uraian82 : 0,
        "desc92"      : value.uraian92 ? value.uraian92 : 0,
        "desc102"     : value.uraian102 ? value.uraian102 : 0,
        "desc112"     : value.uraian112 ? value.uraian112 : 0,
        "gross13"     : value.brutoSemen ? value.brutoSemen : 0,
        "gross23"     : value.brutoKertas ? value.brutoKertas : 0,
        "gross33"     : value.brutoBaja ? value.brutoBaja : 0,
        "gross43"     : value.brutoOtomotif ? value.brutoOtomotif : 0,
        "gross53"     : value.brutoFarmasi ? value.brutoFarmasi : 0,
        "gross63"     : value.bruto63 ? value.bruto63 : 0,
        "gross73"     : value.bruto73 ? value.bruto73 : 0,
        "gross83"     : value.bruto83 ? value.bruto83 : 0,
        "gross93"     : value.bruto93 ? value.bruto93 : 0,
        "gross103"    : value.bruto103 ? value.bruto103 : 0,
        "gross113"    : value.bruto113 ? value.bruto113 : 0,
        "pph16"       : value.pphSemen ? value.pphSemen : 0,
        "pph26"       : value.pphKertas ? value.pphKertas : 0,
        "pph36"       : value.pphBaja ? value.pphBaja : 0,
        "pph46"       : value.pphOtomotif ? value.pphOtomotif : 0,
        "pph56"       : value.pphFarmasi ? value.pphFarmasi : 0,
        "pph66"       : value.pph66 ? value.pph66 : 0,
        "pph76"       : value.pph76 ? value.pph76 : 0,
        "pph86"       : value.pph86 ? value.pph86 : 0,
        "pph96"       : value.pph96 ? value.pph96 : 0,
        "pph106"      : value.pph106 ? value.pph106 : 0,
        "pph116"      : value.pph116 ? value.pph116 : 0,
        "rates15"     : value.tarifSemen ? value.tarifSemen : 0,
        "rates25"     : value.tarifKertas ? value.tarifKertas : 0,
        "rates35"     : value.tarifBaja ? value.tarifBaja : 0,
        "rates45"     : value.tarifOtomotif ? value.tarifOtomotif : 0,
        "rates55"     : value.tarifFarmasi ? value.tarifFarmasi : 0,
        "rates65"     : value.tarif65 ? value.tarif65 : 0,
        "rates75"     : value.tarif75 ? value.tarif75 : 0,
        "rates85"     : value.tarif85 ? value.tarif85 : 0,
        "rates95"     : value.tarif95 ? value.tarif95 : 0,
        "rates105"    : value.tarif105 ? value.tarif105 : 0,
        "rates115"    : value.tarif115 ? value.tarif115 : 0,
      },
      "bpIndustriImportir": {
        "desc72"      : value.uraianMewah,
        "desc82"      : value.uraianSektorA,
        "desc92"      : value.uraianSektorB,
        "desc102"     : value.uraianLainyaA,
        "desc112"     : value.uraianLainyaB,
        "gross13"     : value.brutoSemen ? value.brutoSemen : 0,
        "gross23"     : value.brutoKertas ? value.brutoKertas : 0,
        "gross33"     : value.brutoBaja ? value.brutoBaja : 0,
        "gross43"     : value.brutoOtomotif ? value.brutoOtomotif : 0,
        "gross53"     : value.brutoFinal ? value.brutoFinal : 0,
        "gross63"     : value.brutoTidakFinal ? value.brutoTidakFinal : 0,
        "gross73"     : value.brutoMewah ? value.brutoMewah : 0,
        "gross83"     : value.brutoSektorA ? value.brutoSektorA : 0,
        "gross93"     : value.brutoSektorB ? value.brutoSektorB : 0,
        "gross103"    : value.brutoLainyaA ? value.brutoLainyaA : 0,
        "gross113"    : value.brutoLainyaB ? value.brutoLainyaB : 0,
        "pph16"       : value.pphSemen ? value.pphSemen : 0,
        "pph26"       : value.pphKertas ? value.pphKertas : 0,
        "pph36"       : value.pphBaja ? value.pphBaja : 0,
        "pph46"       : value.pphOtomotif ? value.pphOtomotif : 0,
        "pph56"       : value.pphFinal ? value.pphFinal : 0,
        "pph66"       : value.pphTidakFinal ? value.pphTidakFinal : 0,
        "pph76"       : value.pphMewah ? value.pphMewah : 0,
        "pph86"       : value.pphSektorA ? value.pphSektorA : 0,
        "pph96"       : value.pphSektorB ? value.pphSektorB : 0,
        "pph106"      : value.pphLainyaA ? value.pphLainyaA : 0,
        "pph116"      : value.pphLainyaB ? value.pphLainyaB : 0,
        "rates15"     : value.tarifSemen ? value.tarifSemen : 0,
        "rates25"     : value.tarifKertas ? value.tarifKertas : 0,
        "rates35"     : value.tarifBaja ? value.tarifBaja : 0,
        "rates45"     : value.tarifOtomotif ? value.tarifOtomotif : 0,
        "rates55"     : value.tarifFinal ? value.tarifFinal : 0,
        "rates65"     : value.tarifTidakFinal ? value.tarifTidakFinal : 0,
        "rates75"     : value.tarifMewah ? value.tarifMewah : 0,
        "rates85"     : value.tarifSektorA ? value.tarifSektorA : 0,
        "rates95"     : value.tarifSektorB ? value.tarifSektorB : 0,
        "rates105"    : value.tarifLainyaA ? value.tarifLainyaA : 0,
        "rates115"    : value.tarifLainyaB ? value.tarifLainyaB : 0,
      },
      "phone"         : value.telepon,
      "ltId"          : value.ltId,
      "postalCode"    : value.kodePos,
      "address"       : value.alamat,
      "cuttingDate"   : value.tgl,
      "email"         : value.email,
      "name"          : value.nama,
      "no"            : value.no,
      "non"           : value.non,
      "npwp"          : value.npwp,
      "numbering"     : value.auto,
      "ref"           : value.referensi,
      "signAddress"   : value.alamatPemotong,
      "signName"      : value.namaPemotong,
      "signNpwp"      : value.npwpPemotong,
      "totalGross"    : value.jmlBruto ? value.jmlBruto : 0,
      "totalPph"      : value.jmlPph ? value.jmlPph : 0,
      "type"          : value.type,
      "words"         : value.terbilang,
      spt             : this.props.spt.data
    }
    if(value.id && value.id != "new") mapping.id = value.id
    if(value.consumeId) mapping.consumeId = value.consumeId
    if(typeof mapping.cuttingDate == "object") mapping.cuttingDate = moment(mapping.cuttingDate).format("YYYY-MM-DD")
    mapping.numbering = true

    value = mapping;
    // Remapping Objek

    const current = new Date();
    value.blockBunifikasiMonth = current.getMonth();
    value.blockBunifikasiYear = current.getFullYear();
    try {
      let res = await BlokBunifikasiService.api.getSettingAccess(this.props.match.params.companyId)
      if(res.data.active) {
        value.blockBunifikasiMonth = res.data.bulan
        value.blockBunifikasiYear = res.data.tahun
      }
    } catch(e) {}


    return value;
  }


  async handleSave(value) {
    var res = null;
    var beforeSave = await this.beforeSave(value);
    if(beforeSave) {
      if(typeof beforeSave === 'object') value = beforeSave;
      try {
        this.setState({onProgress: true})
        var bpLoc = '';
        switch (value.type) {
          case 'F113303':
            bpLoc = 'bp-atas-impor-bendaharawan'

            value = {
              nilaiImpor: 0,
              "brutoPajakPertambahanNilai": 0,
              "tarifPajakPertambahanNilai": 10,
              "pphPajakPertambahanNilai": 0,
              "brutoPajakPenjualanAtasBarangMewahA": 0,
              "tarifPajakPenjualanAtasBarangMewahA": 0,
              "pphPajakPenjualanAtasBarangMewahA": 0,
              "brutoPajakPenjualanAtasBarangMewahB": 0,
              "tarifPajakPenjualanAtasBarangMewahB": 0,
              "pphPajakPenjualanAtasBarangMewahB": 0,
              "brutoPajakPenjualanAtasBarangMewahC": 0,
              "tarifPajakPenjualanAtasBarangMewahC": 0,
              "pphPajakPenjualanAtasBarangMewahC": 0,
              "brutoPajakPenjualanAtasBarangMewahD": 0,
              "tarifPajakPenjualanAtasBarangMewahD": 0,
              "pphPajakPenjualanAtasBarangMewahD": 0,
              "brutoPajakPenjualanAtasBarangMewahE": 0,
              "tarifPajakPenjualanAtasBarangMewahE": 0,
              "pphPajakPenjualanAtasBarangMewahE": 0,
              "brutoPajakPenjualanAtasBarangMewahF": 0,
              "tarifPajakPenjualanAtasBarangMewahF": 0,
              "pphPajakPenjualanAtasBarangMewahF": 0,
              "brutoPajakPenjualanAtasBarangMewahG": 0,
              "tarifPajakPenjualanAtasBarangMewahG": 0,
              "pphPajakPenjualanAtasBarangMewahG": 0,
              "brutoPajakPenjualanAtasBarangMewahH": 0,
              "tarifPajakPenjualanAtasBarangMewahH": 0,
              "pphPajakPenjualanAtasBarangMewahH": 0,
              "brutoApi": 0,
              "tarifApi": 0,
              "pphApi": 0,
              "brutoNonApi": 0,
              "tarifNonApi": 0,
              "pphNonApi": 0,
              ...value
            }

            break;
            case 'F113304':
              bpLoc = 'bp-industri-eksportir'
              break;
            case 'F113304S':
              bpLoc = 'bp-industri-importir'
              break;
            case 'F113304A':
              bpLoc = 'bp-atas-pembelian-barang'
              break;
          default:
            break;
        }
        if(value.id) {
          res = await this.service.api.update(value)
        } else {
          res = await this.service.api.save(value)
        }
        this.setState({onProgress: false})
        this.afterSave(res, value)

        var pathArray = this.props.location.pathname.split('/');
        var path = '';
        pathArray.forEach((d, i)=> {
          if(pathArray.length-1 != i) {
            path = path+d;
            if(i != pathArray.length-2) path = path+'/'
          }
        })

        this.props.history.push(path)
      } catch(e) {
        var msg = e.message;
        if(e.response) msg = e.response.data.message;
        this.setState({
          isError: true,
          onProgress: false,
          errorMessage: msg
        })
      }
    }
  }

  jenisFormSwithcer() {
    switch (this.props.formData.type) {
      case 'F113303':
        return <F113303Form change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      case 'F113304':
        return <F113304Form change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      case 'F113304S':
        return <F113304SForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      case 'F113304A':
        return <F113304AForm change={this.props.change} initialize={this.props.initialize} connectCmp={(childFunc)=> {this.reCalculate = childFunc}} />
      default:
        break;
    }
  }

  async initData() {
    var tarif = {};
    if(this.props.match.params.id == 'new') {
      var nomorBP    = "";
      try {
        // let nomorBPReq = await nomorBP22Service.api.get(this.props.spt.data.organization.id)
        let nomorBPReq = await nomorBP22Service.api.get(this.props.spt.data.id)
        if(nomorBPReq.data.mode === 'PREF_SUF') {
          nomorBP = nomorBPReq.data.prefix+nomorBPReq.data.suffix;
        } else {
          nomorBP = nomorBPReq.data.suffix+nomorBPReq.data.prefix;
        }
      } catch (error) {

      }

      let organization = this.props.organization

      // if (!organization) {
      //   organization = this.props.spt.data.organization
      // } else {
      //   if (!organization.npwp) {
      //     organization = this.props.spt.data.organization
      //   }
      // }

      this.initialData = {
        ...this.initialData,
        no             : nomorBP,                        //.data.no,
        namaPemotong   : organization.name,
        npwpPemotong   : organization.npwp,
        alamatPemotong : organization.address,
        spt            : this.props.spt.data,
        jmlPph         : 0,
        tgl            : new Date(),
        jmlBruto       : 0,
        terbilang      : "",
        auto           : true,
        non            : false,
        tarifApi       : 2.5,
        tarifNonApi    : 7.5,
        tarifPajakPertambahanNilai: 10,
        tarifPajakPenjualanAtasBarangMewahA: 10,
        tarifPajakPenjualanAtasBarangMewahB: 20,
        tarifPajakPenjualanAtasBarangMewahC: 30,
        tarifPajakPenjualanAtasBarangMewahD: 40,
        tarifPajakPenjualanAtasBarangMewahE: 50,
        tarifPajakPenjualanAtasBarangMewahF: 60,
        tarifPajakPenjualanAtasBarangMewahG: 75,
        tarifSemen      : 0.25,
        tarifKertas     : 0.1,
        tarifBaja       : 0.3,
        tarifOtomotif   : 0.3,
        tarifFarmasi    : 0.3,
        tarif75         : 5,
        tarif85         : 1.5,
        tarif95         : 1.5,
        tarif105        : 1.5,
        tarifFinal      : 0.3,
        tarifTidakFinal : 0.25,
        tarifMewah      : 5,
        tarifSektorA    : 1.5,
        tarifSektorB    : 1.5,
        tarifLainyaA    : 1.5,
        tarifBumnTertentu: 1.5
        // F113303: {}
      }
      this.props.initialize(this.initialData);
    } else {

      var bpLoc = ''
      switch (this.props.match.params.type) {
        case "F113303":
          bpLoc = 'bp-atas-impor-bendaharawan'
          break;
        case 'F113304':
          bpLoc = 'bp-industri-eksportir'
          break;
        case 'F113304S':
          bpLoc = 'bp-industri-importir'
          break;
        case 'F113304A':
          bpLoc = 'bp-atas-pembelian-barang'
          break;
        default:
          break;
      }

      let res = await this.service.api.findOne(this.props.match.params.id);

      // Remapping Objek
      var data = res.data
      var remapping = {
        uraianMewah                         : data.bpIndustriImportir ? data.bpIndustriImportir.desc72   : null,
        uraianSektorA                       : data.bpIndustriImportir ? data.bpIndustriImportir.desc82   : null,
        uraianSektorB                       : data.bpIndustriImportir ? data.bpIndustriImportir.desc92   : null,
        uraianLainyaA                       : data.bpIndustriImportir ? data.bpIndustriImportir.desc102  : null,
        uraianLainyaB                       : data.bpIndustriImportir ? data.bpIndustriImportir.desc112  : null,
        brutoSemen                          : data.bpIndustriImportir ? data.bpIndustriImportir.gross13  : 0,
        brutoKertas                         : data.bpIndustriImportir ? data.bpIndustriImportir.gross23  : 0,
        brutoBaja                           : data.bpIndustriImportir ? data.bpIndustriImportir.gross33  : 0,
        brutoOtomotif                       : data.bpIndustriImportir ? data.bpIndustriImportir.gross43  : 0,
        brutoFinal                          : data.bpIndustriImportir ? data.bpIndustriImportir.gross53  : 0,
        brutoTidakFina                      : data.bpIndustriImportir ? data.bpIndustriImportir.gross63  : 0,
        brutoMewah                          : data.bpIndustriImportir ? data.bpIndustriImportir.gross73  : 0,
        brutoSektorA                        : data.bpIndustriImportir ? data.bpIndustriImportir.gross83  : 0,
        brutoSektorB                        : data.bpIndustriImportir ? data.bpIndustriImportir.gross93  : 0,
        brutoLainyaA                        : data.bpIndustriImportir ? data.bpIndustriImportir.gross103 : 0,
        brutoLainyaB                        : data.bpIndustriImportir ? data.bpIndustriImportir.gross113 : 0,
        pphSemen                            : data.bpIndustriImportir ? data.bpIndustriImportir.pph16    : 0,
        pphKertas                           : data.bpIndustriImportir ? data.bpIndustriImportir.pph26    : 0,
        pphBaja                             : data.bpIndustriImportir ? data.bpIndustriImportir.pph36    : 0,
        pphOtomotif                         : data.bpIndustriImportir ? data.bpIndustriImportir.pph46    : 0,
        pphFinal                            : data.bpIndustriImportir ? data.bpIndustriImportir.pph56    : 0,
        pphTidakFinal                       : data.bpIndustriImportir ? data.bpIndustriImportir.pph66    : 0,
        pphMewah                            : data.bpIndustriImportir ? data.bpIndustriImportir.pph76    : 0,
        pphSektorA                          : data.bpIndustriImportir ? data.bpIndustriImportir.pph86    : 0,
        pphSektorB                          : data.bpIndustriImportir ? data.bpIndustriImportir.pph96    : 0,
        pphLainyaA                          : data.bpIndustriImportir ? data.bpIndustriImportir.pph106   : 0,
        pphLainyaB                          : data.bpIndustriImportir ? data.bpIndustriImportir.pph116   : 0,
        tarifSemen                          : data.bpIndustriImportir ? data.bpIndustriImportir.rates15  : 0,
        tarifKertas                         : data.bpIndustriImportir ? data.bpIndustriImportir.rates25  : 0,
        tarifBaja                           : data.bpIndustriImportir ? data.bpIndustriImportir.rates35  : 0,
        tarifOtomotif                       : data.bpIndustriImportir ? data.bpIndustriImportir.rates45  : 0,
        tarifFinal                          : data.bpIndustriImportir ? data.bpIndustriImportir.rates55  : 0,
        tarifTidakFina                      : data.bpIndustriImportir ? data.bpIndustriImportir.rates65  : 0,
        tarifMewah                          : data.bpIndustriImportir ? data.bpIndustriImportir.rates75  : 0,
        tarifSektorA                        : data.bpIndustriImportir ? data.bpIndustriImportir.rates85  : 0,
        tarifSektorB                        : data.bpIndustriImportir ? data.bpIndustriImportir.rates95  : 0,
        tarifLainyaA                        : data.bpIndustriImportir ? data.bpIndustriImportir.rates105 : 0,
        tarifLainyaB                        : data.bpIndustriImportir ? data.bpIndustriImportir.rates115 : 0,
        uraian62                            : data.bpIndustriEksportir ? data.bpIndustriEksportir.desc62   : null,
        uraian72                            : data.bpIndustriEksportir ? data.bpIndustriEksportir.desc72   : null,
        uraian82                            : data.bpIndustriEksportir ? data.bpIndustriEksportir.desc82   : null,
        uraian92                            : data.bpIndustriEksportir ? data.bpIndustriEksportir.desc92   : null,
        uraian102                           : data.bpIndustriEksportir ? data.bpIndustriEksportir.desc102  : null,
        uraian112                           : data.bpIndustriEksportir ? data.bpIndustriEksportir.desc112  : null,
        brutoSemen                          : data.bpIndustriEksportir ? data.bpIndustriEksportir.gross13  : 0,
        brutoKertas                         : data.bpIndustriEksportir ? data.bpIndustriEksportir.gross23  : 0,
        brutoBaja                           : data.bpIndustriEksportir ? data.bpIndustriEksportir.gross33  : 0,
        brutoOtomotif                       : data.bpIndustriEksportir ? data.bpIndustriEksportir.gross43  : 0,
        brutoFarmasi                        : data.bpIndustriEksportir ? data.bpIndustriEksportir.gross53  : 0,
        bruto63                             : data.bpIndustriEksportir ? data.bpIndustriEksportir.gross63  : 0,
        bruto73                             : data.bpIndustriEksportir ? data.bpIndustriEksportir.gross73  : 0,
        bruto83                             : data.bpIndustriEksportir ? data.bpIndustriEksportir.gross83  : 0,
        bruto93                             : data.bpIndustriEksportir ? data.bpIndustriEksportir.gross93  : 0,
        bruto103                            : data.bpIndustriEksportir ? data.bpIndustriEksportir.gross103 : 0,
        bruto113                            : data.bpIndustriEksportir ? data.bpIndustriEksportir.gross113 : 0,
        pphSemen                            : data.bpIndustriEksportir ? data.bpIndustriEksportir.pph16    : 0,
        pphKertas                           : data.bpIndustriEksportir ? data.bpIndustriEksportir.pph26    : 0,
        pphBaja                             : data.bpIndustriEksportir ? data.bpIndustriEksportir.pph36    : 0,
        pphOtomotif                         : data.bpIndustriEksportir ? data.bpIndustriEksportir.pph46    : 0,
        pphFarmasi                          : data.bpIndustriEksportir ? data.bpIndustriEksportir.pph56    : 0,
        pph66                               : data.bpIndustriEksportir ? data.bpIndustriEksportir.pph66    : 0,
        pph76                               : data.bpIndustriEksportir ? data.bpIndustriEksportir.pph76    : 0,
        pph86                               : data.bpIndustriEksportir ? data.bpIndustriEksportir.pph86    : 0,
        pph96                               : data.bpIndustriEksportir ? data.bpIndustriEksportir.pph96    : 0,
        pph106                              : data.bpIndustriEksportir ? data.bpIndustriEksportir.pph106   : 0,
        pph116                              : data.bpIndustriEksportir ? data.bpIndustriEksportir.pph116   : 0,
        tarifSemen                          : data.bpIndustriEksportir ? data.bpIndustriEksportir.rates15  : 0,
        tarifKertas                         : data.bpIndustriEksportir ? data.bpIndustriEksportir.rates25  : 0,
        tarifBaja                           : data.bpIndustriEksportir ? data.bpIndustriEksportir.rates35  : 0,
        tarifOtomotif                       : data.bpIndustriEksportir ? data.bpIndustriEksportir.rates45  : 0,
        tarifFarmasi                        : data.bpIndustriEksportir ? data.bpIndustriEksportir.rates55  : 0,
        tarif65                             : data.bpIndustriEksportir ? data.bpIndustriEksportir.rates65  : 0,
        tarif75                             : data.bpIndustriEksportir ? data.bpIndustriEksportir.rates75  : 0,
        tarif85                             : data.bpIndustriEksportir ? data.bpIndustriEksportir.rates85  : 0,
        tarif95                             : data.bpIndustriEksportir ? data.bpIndustriEksportir.rates95  : 0,
        tarif105                            : data.bpIndustriEksportir ? data.bpIndustriEksportir.rates105 : 0,
        tarif115                            : data.bpIndustriEksportir ? data.bpIndustriEksportir.rates115 : 0,
        nilaiImpor                          : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.nilaiImpor  : 0,
        brutoPajakPertambahanNilai          : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.nop13       : 0,
        brutoPajakPenjualanAtasBarangMewahA : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.nop23a      : 0,
        brutoPajakPenjualanAtasBarangMewahB : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.nop23b      : 0,
        brutoPajakPenjualanAtasBarangMewahC : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.nop23c      : 0,
        brutoPajakPenjualanAtasBarangMewahD : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.nop23d      : 0,
        brutoPajakPenjualanAtasBarangMewahE : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.nop23e      : 0,
        brutoPajakPenjualanAtasBarangMewahF : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.nop23f      : 0,
        brutoPajakPenjualanAtasBarangMewahG : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.nop23g      : 0,
        brutoPajakPenjualanAtasBarangMewahH : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.nop23h      : 0,
        brutoApi                            : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.nop33a      : 0,
        brutoNonApi                         : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.nop33b      : 0,
        pphPajakPertambahanNilai            : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.pph15       : 0,
        pphPajakPenjualanAtasBarangMewahA   : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.pph25a      : 0,
        pphPajakPenjualanAtasBarangMewahB   : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.pph25b      : 0,
        pphPajakPenjualanAtasBarangMewahC   : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.pph25c      : 0,
        pphPajakPenjualanAtasBarangMewahD   : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.pph25d      : 0,
        pphPajakPenjualanAtasBarangMewahE   : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.pph25e      : 0,
        pphPajakPenjualanAtasBarangMewahF   : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.pph25f      : 0,
        pphPajakPenjualanAtasBarangMewahG   : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.pph25g      : 0,
        pphPajakPenjualanAtasBarangMewahH   : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.pph25h      : 0,
        pphApi                              : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.pph35a      : 0,
        pphNonApi                           : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.pph35b      : 0,
        qty                                 : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.qty         : null,
        tarifPajakPertambahanNilai          : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.rates14     : 0,
        tarifPajakPenjualanAtasBarangMewahA : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.rates24a    : 0,
        tarifPajakPenjualanAtasBarangMewahB : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.rates24b    : 0,
        tarifPajakPenjualanAtasBarangMewahC : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.rates24c    : 0,
        tarifPajakPenjualanAtasBarangMewahD : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.rates24d    : 0,
        tarifPajakPenjualanAtasBarangMewahE : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.rates24e    : 0,
        tarifPajakPenjualanAtasBarangMewahF : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.rates24f    : 0,
        tarifPajakPenjualanAtasBarangMewahG : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.rates24g    : 0,
        tarifPajakPenjualanAtasBarangMewahH : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.rates24h    : 0,
        tarifApi                            : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.rates34a    : 0,
        tarifNonApi                         : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.rates34b    : 0,
        jenisBarang                         : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.jenisBarang : null,
        satuan                              : data.bpAtasImporBendaharawan ? data.bpAtasImporBendaharawan.unit        : null,
        uraian52                            : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.desc52   : null,
        uraian62                            : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.desc62   : null,
        uraianMewah                         : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.desc72   : null,
        uraianSektorA                       : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.desc82   : null,
        uraianSektorB                       : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.desc92   : null,
        uraianLainya                        : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.desc112  : null,
        brutoSemen                          : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.gross13  : 0,
        brutoKertas                         : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.gross23  : 0,
        brutoBaja                           : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.gross33  : 0,
        brutoOtomotif                       : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.gross43  : 0,
        bruto53                             : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.gross53  : 0,
        bruto63                             : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.gross63  : 0,
        brutoMewah                          : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.gross73  : 0,
        brutoSektorA                        : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.gross83  : 0,
        brutoSektorB                        : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.gross93  : 0,
        brutoBumnTertentu                   : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.gross103 : 0,
        brutoLainya                         : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.gross113 : 0,
        pphSemen                            : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.pph16    : 0,
        pphKertas                           : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.pph26    : 0,
        pphBaja                             : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.pph36    : 0,
        pphOtomotif                         : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.pph46    : 0,
        pph56                               : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.pph56    : 0,
        pph66                               : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.pph66    : 0,
        pphMewah                            : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.pph76    : 0,
        pphSektorA                          : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.pph86    : 0,
        pphSektorB                          : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.pph96    : 0,
        pphBumnTertentu                     : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.pph106   : 0,
        pphLainya                           : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.pph116   : 0,
        tarifSemen                          : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.rates15  : 0,
        tarifKertas                         : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.rates25  : 0,
        tarifBaja                           : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.rates35  : 0,
        tarifOtomotif                       : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.rates45  : 0,
        tarif55                             : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.rates55  : 0,
        tarif65                             : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.rates65  : 0,
        tarifMewah                          : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.rates75  : 0,
        tarifSektorA                        : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.rates85  : 0,
        tarifSektorB                        : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.rates95  : 0,
        tarifBumnTertentu                   : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.rates105 : 0,
        tarifLainya                         : data.bpAtasBadanTertentu ? data.bpAtasBadanTertentu.rates115 : 0,
        alamat                              : data.address,
        tgl                                 : data.cuttingDate,
        email                               : data.email,
        nama                                : data.name,
        npwp                                : data.npwp,
        no                                  : data.no,
        non                                 : data.non,
        auto                                : data.numbering,
        referensi                           : data.ref,
        alamatPemotong                      : data.signAddress,
        namaPemotong                        : data.signName,
        npwpPemotong                        : data.signNpwp,
        jmlBruto                            : data.totalGross ? data.totalGross : 0,
        jmlPph                              : data.totalPph ? data.totalPph : 0,
        type                                : data.type,
        terbilang                           : data.words,
        spt                                 : this.props.spt.data,
        id                                  : data.id
        // console.log(val, "TELL ME")
      }
      if(data.type == "F13303")  remapping.type = "F113303"
      if(data.type == "F13304")  remapping.type = "F113304"
      if(data.type == "F13304S") remapping.type = "F113304S"
      if(data.type == "F13304A") remapping.type = "F113304A"
      // console.log(remapping, data.type, data, data.type == "F13304A")
      res.data = remapping;
      // Remapping Objek

      this.props.initialize(res.data);
      // this.npwpValidation(null, res.data.bagA.npwp)
      this.setState({npwpNotValid: false})
    }
  }

  async afterSave(res, val) {
    if(!val.id){
      var data = {
        npwp: val.npwp,
        name: val.name,
        address: val.address,
        email: val.email,
        organization: this.props.spt.data.organization,
        npwpProfile: this.props.organization.npwp,
        phone: val.phone,
        postalCode: val.postalCode,
        ltId: val.ltId
      }
      if(data.id) delete data.id;
      await MasterLawanTransaksi22Service.api.findOrCreate(data)
    }
  }

  formView() {
    var formData = {bagB: {}};
    if  (this.props.formData) formData = this.props.formData;

    return (
      <div>
        <LawanTransaksiDialog
          spt      = {this.props.spt.data}
          onSelect = {(d)=> {
            var formData = {
              ...this.props.formData,
              "npwp"   : d.npwp,
              "nik"    : d.nik,
              "nama"   : d.name,
              "alamat" : d.address,
              "telepon": d.phone,
              "kodePos": d.postalCode,
              "email"  : d.email,
              "ltId"   : d.id
            };
            if(formData.npwp === '000000000000000' || formData.npwp === "") formData.non = true;

            if (formData.non) {
              formData.tarifSemen = formData.tarifSemen * 2
              formData.tarifKertas = formData.tarifKertas * 2
              formData.tarifBaja = formData.tarifBaja * 2
              formData.tarifOtomotif = formData.tarifOtomotif * 2
              formData.tarifFarmasi = formData.tarifFarmasi * 2
              formData.tarifTidakFinal = formData.tarifTidakFinal * 2
            }

            this.props.initialize(formData);

          }}
          service = {MasterLawanTransaksi22Service}
          visible = {this.state.lkDialog}
          onHide  = {()=> this.setState({lkDialog: false})}
        />
        <Card>
          <div className="md-grid">
            <Field
              label     = 'No. Bukti Pungut'
              name      = 'no'
              className = "md-cell md-cell--6"
              component = {Textfield}
              validate  = {validation.required}
            />

            <Field
              label        = 'Tanggal'
              name         = 'tgl'
              className    = "md-cell md-cell--6"
              // onDateChange = {async (d, e) => this.changeTarif(d)}
              component    = {Datepicker}
              validate     = {validation.required}
            />

            <Field
              label     = 'Referensi'
              name      = 'referensi'
              id        = "BP21FForm-referensi"
              className = "md-cell md-cell--12"
              component = {Textfield}
            />
          </div>
        </Card>
        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. LAWAN TRANSAKSI ' />
          <Divider/>
          <div className="md-grid">
            <div className='md-cell md-cell--6'>
            <div className='mpk-layout'>
              <Field
                label      = 'NPWP'
                className  = 'flex'
                name       = 'npwp'
                component  = {TextfieldMask}
                maskFormat = "##.###.###.#-###-###"
                mask       = "_"
                length     = {15}
                onChange   = {this.npwpValidation.bind(this)} // JLS
                validate   = {validation.required}
              />
              <Button icon primary swapTheming style={{marginTop: 10}} onClick={()=> this.setState({lkDialog: true})}>search</Button>
              </div>
              <Field
                label     = 'Nama'
                name      = 'nama'
                disabled  = {this.state.npwpNotValid} // JLS
                component = {Textfield}
                validate  = {validation.required}
              />
              {/* <Field
                label     = 'NIK'
                name      = 'penerimaPenghasilan.nik'
                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
              />
              <Field
                label     = 'Telepon'
                name      = 'telepon'
                id        = "BP21FForm-name"
                component = {Textfield}
                />*/}

            </div>
            <div className='md-cell md-cell--6'>
              {/* <Field
                label     = 'Kode Poss'
                name      = 'kodePoss'
                id        = "BP21FForm-codePoss"
                component = {Textfield}
              />*/}
              <Field
                label = 'Alamat'
                name  = 'alamat'

                disabled  = {this.state.npwpNotValid} // JLS
                component = {Textfield}
                validate  = {validation.required}
              />
              <Field
                label = 'Email'
                name  = 'email'

                disabled  = {this.state.npwpNotValid} // JLS
                component = {Textfield}
              />
            </div>
          </div>
        </Card>

        <br/>

        {this.state.showCalculateProgress && <LinearProgress id='lin_pro' style={{margin: 0}} />}
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. DETAIL DIPOTONG' />
          <Divider/>
          <div>
            {(this.props.match.params && this.props.match.params.id && this.props.match.params.id == 'new') &&

            <Field
              label     = 'Jenis Bukti Pungut'
              className = "md-cell md-cell--12"
              name      = 'type'
              // onChange  = {async (e, v)=> {
              //   var tarif = await Master22TarifService.api.byCompany(
              //     this.props.spt.data.organization.company.id,
              //     {
              //       compId: this.props.organization.company.id,
              //       tgl   : moment(this.props.formData.tgl).format("YYYY-MM-DD")
              //     }
              //   );
              //   var tarifMap = {};
              //   if(tarif) {
              //     tarifMap = tarif.details.reduce((pv, v)=> {
              //       pv[v.kode] = v;
              //       return pv;
              //     }, {});
              //   }

              //   var formData = {}

              //   switch (v) {
              //     case 'F113303':
              //       formData = {
              //         ...this.props.formData,
              //         "brutoPajakPertambahanNilai": 0,
              //         "tarifPajakPertambahanNilai": 10,
              //         "pphPajakPertambahanNilai": 0,
              //         "brutoPajakPenjualanAtasBarangMewahA": 0,
              //         "tarifPajakPenjualanAtasBarangMewahA": 10,
              //         "pphPajakPenjualanAtasBarangMewahA": 0,
              //         "brutoPajakPenjualanAtasBarangMewahB": 0,
              //         "tarifPajakPenjualanAtasBarangMewahB": 20,
              //         "pphPajakPenjualanAtasBarangMewahB": 0,
              //         "brutoPajakPenjualanAtasBarangMewahC": 0,
              //         "tarifPajakPenjualanAtasBarangMewahC": 30,
              //         "pphPajakPenjualanAtasBarangMewahC": 0,
              //         "brutoPajakPenjualanAtasBarangMewahD": 0,
              //         "tarifPajakPenjualanAtasBarangMewahD": 40,
              //         "pphPajakPenjualanAtasBarangMewahD": 0,
              //         "brutoPajakPenjualanAtasBarangMewahE": 0,
              //         "tarifPajakPenjualanAtasBarangMewahE": 50,
              //         "pphPajakPenjualanAtasBarangMewahE": 0,
              //         "brutoPajakPenjualanAtasBarangMewahF": 0,
              //         "tarifPajakPenjualanAtasBarangMewahF": 60,
              //         "pphPajakPenjualanAtasBarangMewahF": 0,
              //         "brutoPajakPenjualanAtasBarangMewahG": 0,
              //         "tarifPajakPenjualanAtasBarangMewahG": 75,
              //         "pphPajakPenjualanAtasBarangMewahG": 0,
              //         "brutoPajakPenjualanAtasBarangMewahH": 0,
              //         "tarifPajakPenjualanAtasBarangMewahH": 0,
              //         "pphPajakPenjualanAtasBarangMewahH": 0,
              //         "brutoApi": 0,
              //         "tarifApi": tarifMap['API'].tarif,
              //         "pphApi": 0,
              //         "brutoNonApi": 0,
              //         "tarifNonApi": tarifMap['NON-API'].tarif,
              //         "pphNonApi": 0,
              //       }
              //       break;
              //     case 'F113304':
              //       formData = {
              //         ...this.props.formData,
              //         "brutoSemen": 0,
              //         "tarifSemen": tarifMap['SEMEN'].tarif,
              //         "pphSemen": 0,
              //         "brutoKertas": 0,
              //         "tarifKertas": tarifMap['KERTAS'].tarif,
              //         "pphKertas": 0,
              //         "brutoBaja": 0,
              //         "tarifBaja": tarifMap['BAJA'].tarif,
              //         "pphBaja": 0,
              //         "brutoOtomotif": 0,
              //         "tarifOtomotif": tarifMap['OTOMOTIF'].tarif,
              //         "pphOtomotif": 0,
              //         "brutoFarmasi": 0,
              //         "tarifFarmasi": tarifMap['FARMASI'].tarif,
              //         "pphFarmasi": 0,
              //         "uraian62": "",
              //         "bruto63": 0,
              //         "tarif65": 0,
              //         "pph66": 0,
              //         "uraian72": "",
              //         "bruto73": 0,
              //         "tarif75": 0,
              //         "pph76": 0,
              //         "uraian82": "",
              //         "bruto83": 0,
              //         "tarif85": 1.5,
              //         "pph86": 0,
              //         "uraian92": "",
              //         "bruto93": 0,
              //         "tarif95": 1.5,
              //         "pph96": 0,
              //         "uraian102": "",
              //         "bruto103": 0,
              //         "tarif105": 0,
              //         "pph106": 0,
              //         "uraian112": "",
              //         "bruto113": 0,
              //         "tarif115": 0,
              //         "pph116": 0,
              //       }
              //       break;
              //     case 'F113304S':
              //       formData = {
              //         ...this.props.formData,
              //         "brutoSemen": 0,
              //         "tarifSemen": tarifMap['SEMEN'].tarif,
              //         "pphSemen": 0,
              //         "brutoKertas": 0,
              //         "tarifKertas": tarifMap['KERTAS'].tarif,
              //         "pphKertas": 0,
              //         "brutoBaja": 0,
              //         "tarifBaja": tarifMap['BAJA'].tarif,
              //         "pphBaja": 0,
              //         "brutoOtomotif": 0,
              //         "tarifOtomotif": tarifMap['OTOMOTIF'].tarif,
              //         "pphOtomotif": 0,
              //         "brutoFinal": 0,
              //         "tarifFinal": tarifMap['FINAL'].tarif,
              //         "pphFinal": 0,
              //         "brutoTidakFinal": 0,
              //         "tarifTidakFinal": tarifMap['TIDAK-FINAL'].tarif,
              //         "pphTidakFinal": 0,
              //         "uraianMewah": "",
              //         "brutoMewah": 0,
              //         "tarifMewah": 0,
              //         "pphMewah": 0,
              //         "uraianSektorA": "",
              //         "brutoSektorA": 0,
              //         "tarifSektorA": 1.5,
              //         "pphSektorA": 0,
              //         "uraianSektorB": "",
              //         "brutoSektorB": 0,
              //         "tarifSektorB": 1.5,
              //         "pphSektorB": 0,
              //         "uraianLainyaA": "",
              //         "brutoLainyaA": 0,
              //         "tarifLainyaA": 0,
              //         "pphLainyaA": 0,
              //         "uraianLainyaB": "",
              //         "brutoLainyaB": 0,
              //         "tarifLainyaB": 0,
              //         "pphLainyaB": 0,
              //       }
              //       break;
              //     case 'F113304A':
              //       formData = {
              //         ...this.props.formData,
              //         "brutoSemen": 0,
              //         "tarifSemen": tarifMap['SEMEN'].tarif,
              //         "pphSemen": 0,
              //         "brutoKertas": 0,
              //         "tarifKertas": tarifMap['KERTAS'].tarif,
              //         "pphKertas": 0,
              //         "brutoBaja": 0,
              //         "tarifBaja": tarifMap['BAJA'].tarif,
              //         "pphBaja": 0,
              //         "brutoOtomotif": 0,
              //         "tarifOtomotif": tarifMap['OTOMOTIF'].tarif,
              //         "pphOtomotif": 0,
              //         "uraian52": "",
              //         "bruto53": 0,
              //         "tarif55": 0,
              //         "pph56": 0,
              //         "uraian62": "",
              //         "bruto63": 0,
              //         "tarif65": 0,
              //         "pph66": 0,
              //         "uraianMewah": "",
              //         "brutoMewah": 0,
              //         "tarifMewah": 0,
              //         "pphMewah": 0,
              //         "uraianSektorA": "",
              //         "brutoSektorA": 0,
              //         "tarifSektorA": 1.5,
              //         "pphSektorA": 0,
              //         "uraianSektorB": "",
              //         "brutoSektorB": 0,
              //         "tarifSektorB": 1.5,
              //         "pphSektorB": 0,
              //         "brutoBumnTertentu": 0,
              //         "tarifBumnTertentu": 1.5,
              //         "pphBumnTertentu": 0,
              //         "uraianLainya": "",
              //         "brutoLainya": 0,
              //         "tarifLainya": 0,
              //         "pphLainya": 0,
              //       }
              //       break;
              //     default:
              //       break;
              //     }

              //     this.props.initialize(formData)
              // }} // JLS
              component = {Searchfield}
              options   = {this.typeOptions}
              validate  = {validation.required}
            />
            }

            <br/>
          </div>


          {this.jenisFormSwithcer()}
        </Card>

        <br/>

        <Card>
          <div className='md-grid'>
            <Field
              label     = 'Jumlah Bruto'
              name      = 'jmlBruto'
              component = {TextfieldMask}
              className = 'md-text-right'
              disabled
              money = {','}
            />
            <Field
              label     = 'Jumlah PPh'
              name      = 'jmlPph'
              component = {TextfieldMask}
              className = 'md-text-right'
              disabled
              money = {','}
            />
            <Field
              label     = 'Terbilang'
              name      = 'terbilang'
              component = {Textfield}
              disabled
            />
          </div>
        </Card>

        <br />

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='C. WAJIB PAJAK ' />
          <Divider/>

          <div className="md-grid">
          <Field
            label     = 'NPWP'
            name      = 'npwpPemotong'
            className = "md-cell md-cell--6"
            disabled
            component  = {TextfieldMask}
            maskFormat = "##.###.###.#-###-###"
            mask       = "_"
            validate   = {validation.required}
          />
          <Field
            label     = 'Nama'
            name      = 'namaPemotong'
            className = "md-cell md-cell--6"
            disabled
            component = {Textfield}
            validate  = {validation.required}
          />
          <Field
            label     = 'Alamat'
            name      = 'alamatPemotong'
            className = "md-cell md-cell--12"
            disabled
            component = {Textfield}
            validate  = {validation.required}
          />


          </div>
        </Card>
      </div>
    )
  }

}
