import React, { Component } from 'react';
import {
  DialogContainer,
  TextField,
  FontIcon,
  Subheader,
  Button,
  List,
  ListItem,
  Toolbar,
} from 'react-md';
import { connect } from 'react-redux';
import ListView from './listView';
import MasterLawanTransaksiPpService from '../../modules/Main/Master/MasterLawanTransaksiPp/MasterLawanTransaksiPp.service';

class LawanTransaksiView extends ListView {
  apiPath = 'getBySpt';

  titleHeader() {
    return ''
  }

  beforeFetch(params) {
    // params['npwpProfile.equals'] = this.props.npwpProfile
    params.sptId = this.props.sptId
  }

  searchForm() {
    return (
      <this.SearchContainer>
        <this.ig.Field
          label='Nama'
          name='name__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NPWP'
          name='npwp__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='NIK'
          name='nik__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
        <this.ig.Field
          label='Email'
          name='email__contains'
          className="md-cell md-cell--12"
          component={this.ig.Textfield}
        />
      </this.SearchContainer>
    )
  }

  componentWillMount() {
    var parent = this.props.getParent();
    // console.log(parent, 'parent')
    parent.handleSelect = () => {
      var selected = this.props.table.selected[0];

      return selected;
    }

    this.service = this.props.service;
    this.buildSearchContainer();
  }

  componentDidMount() {
    this.service = this.props.service
    this.firstCall = false;
  }

  service={}

  columns=[
    {label: "word.npwp",  value: "npwp", isDefaultSort:false, show:true, isSearchable:true},
    {label: "word.name",  value: "name", isDefaultSort:false, show:true, isSearchable:true},
    {label: "word.nik",  value: "nik", isDefaultSort:false, show:true, isSearchable:true},
    {label: "word.address",  value: "address", isDefaultSort:false, show:true, isSearchable:true}
  ]

  viewType=2;

  _barActions = [
    {
      label:'word.refresh',
      iconClassName:'mdi mdi-refresh',
      onClick:() => {

        window.location.hash = window.location.hash.split('?')[0]
        this.fetchData()
      }
    },
  ]

  _tableActions = [
    // {
    //   label:"More Info",
    //   iconClassName:"mdi mdi-bell",
    //   onClick: (item) => this.editItem(item)
    // }
  ]

  _viewContainer(props) {
    return <div>
      {props.children}
    </div>
  }
}

// @connect(MasterLawanTransaksiPpService.stateConnectSetting())
export default class LawanTransaksiDialog extends Component {
  service;

  LawanTransaksiView = ()=> <div/>

  constructor(props) {
    super(props);
    this.state = {
      data: []
    }
  }

  async query() {
    if(this.props.service) {
      var res = await this.props.service.api.page();
      if(res.data) {
        this.setState({data: res.data})
      }
    }
  }

  componentWillMount() {
    if(this.props.service)
      this.LawanTransaksiView = connect(this.props.service.stateConnectSetting(), this.props.service.actionConnectSetting())(LawanTransaksiView);

      this.forceUpdate();
  }

  componentDidMount() {
    // this.query();
  }

  handleSelect = () => null

  getParent = () => {
    return this;
  }

  render() {
    return <DialogContainer
    id="lk_picker"
    visible={this.props.visible}
    onHide={this.props.onHide}
    style={{width: 400}}
    width={800}
    contentStyle={{padding:0}}
    actions={[

      { secondary: true, children: 'Close', onClick: ()=>{
        if(this.props.onHide) this.props.onHide()
      }},{ children: 'Select', onClick: ()=>{
        var selected = this.handleSelect();
        // console.log(selected, 'selected')
        if(selected) {
          if(this.props.onSelect) {
            this.props.onSelect(selected)
            this.props.onHide()
          }
        } else {

        }

      }}
    ]}
    title='Lawan Transaksi'
  >
    <this.LawanTransaksiView service={this.props.service}
      //organisasiId={this.props.spt.organization.id}
      sptId={this.props.spt.id}
      getParent={this.getParent}
    />
  </DialogContainer>
  }
}
