import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress,
  Switch as MSwitch
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import BP21TFService from './BP_21_TF.service';
import NomorBPService from '../nomorBP.service';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
import SPT21SignerService from '../SPT21Signer/SPT21Signer.service';
import NpwpService from './../../NpwpService';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import LawanTransaksiDialog from '../../../../../components/entity/LawanTransaksi.dialog';
import MasterLawanTransaksiPpService from '../../../Master/MasterLawanTransaksiPp/MasterLawanTransaksiPp.service';
import CountryCodeService from '../../../Master/CountryCode/CountryCode.service';

@reduxForm({form: 'BP_21_TF_Form', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt: state.authEppt.currentSPT,
  formData:state.form.BP_21_TF_Form
}))
export default class BP21TFForm extends FormViewSpt {
  service=BP21TFService
  viewType =2;

  typingTimeout=1000;
  typingObj=null;

  initialData={
    bagA: {},
    bagB: {bruto: 0},
    bagC: {
      tanggal: new Date()
    },
    objekHitungTidakFinal:{
      tipePenghasilan: 'B',
      dipotongOleh: 'SPK',
      caraPembayaran: 'B',
      jumlahHariKerja: 0,

      brutoSebelumnya50Persen: 0,
      bruto: 0,
      phkpSebelumnya:0,
      statusPtkp: 'TK',
      jumlahTanggungan: 0
    }
  }

  bagBConfig= {
    undefined: {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: false
    },
    '21-100-03': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: true,
      jumlahTanggungan: true,
      caraPembayaran: true,
      jumlahHariKerja: true,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-04': {
      tipePenghasilan: true,
      dipotongOleh: true,
      statusPtkp: true,
      jumlahTanggungan: true,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: true,
      bruto: true
    },
    '21-100-05': {
      tipePenghasilan: true,
      dipotongOleh: true,
      statusPtkp: true,
      jumlahTanggungan: true,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: true,
      bruto: true
    },
    '21-100-06': {
      tipePenghasilan: true,
      dipotongOleh: true,
      statusPtkp: true,
      jumlahTanggungan: true,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: true,
      bruto: true
    },
    '21-100-07': {
      tipePenghasilan: true,
      dipotongOleh: true,
      statusPtkp: true,
      jumlahTanggungan: true,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: true,
      bruto: true
    },
    '21-100-08': {
      tipePenghasilan: true,
      dipotongOleh: true,
      statusPtkp: true,
      jumlahTanggungan: true,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: true,
      bruto: true
    },
    '21-100-09': {
      tipePenghasilan: true,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-10': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-11': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-12': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: true,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-13': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: true
    },
    '21-100-99': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: false
    },
    '27-100-99': {
      tipePenghasilan: false,
      dipotongOleh: false,
      statusPtkp: false,
      jumlahTanggungan: false,
      jumlahHariKerja: false,
      phkpSebelumnya: false,
      brutoSebelumnya50Persen: false,
      bruto: true
    }
  }

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      showCalculateProgress: false,
      showJumlahHariKerja: true,
      npwpNotValid: true,
      calculated: false,

      lkDialog: false
    }
  }

  handleCaraPembayaran(e, value){
    if(value == "B"){
      this.setState({ showJumlahHariKerja: false })
    } else {
      this.setState({ showJumlahHariKerja: true })
    }
  }

  async convertObjectHitungTidakFinal(objekHitungTidakFinal) {
    if (objekHitungTidakFinal.caraPembayaran) {
      if (objekHitungTidakFinal.caraPembayaran.id != null && objekHitungTidakFinal.caraPembayaran.id !== '') {
        objekHitungTidakFinal.caraPembayaran = objekHitungTidakFinal.caraPembayaran.id
      }
    }

    if (objekHitungTidakFinal.tipePenghasilan) {
      if (objekHitungTidakFinal.tipePenghasilan.id != null && objekHitungTidakFinal.tipePenghasilan.id !== '') {
        objekHitungTidakFinal.tipePenghasilan = objekHitungTidakFinal.tipePenghasilan.id
      }
    }

    if (objekHitungTidakFinal.dipotongOleh) {
      if (objekHitungTidakFinal.dipotongOleh.id != null && objekHitungTidakFinal.dipotongOleh.id !== '') {
        objekHitungTidakFinal.dipotongOleh = objekHitungTidakFinal.dipotongOleh.id
      }
    }

    if (objekHitungTidakFinal.statusPtkp) {
      if (objekHitungTidakFinal.statusPtkp.id != null && objekHitungTidakFinal.statusPtkp.id !== '') {
        objekHitungTidakFinal.statusPtkp = objekHitungTidakFinal.statusPtkp.id
      }
    }

    if (objekHitungTidakFinal.jumlahTanggungan) {
      if (objekHitungTidakFinal.jumlahTanggungan.id != null && objekHitungTidakFinal.jumlahTanggungan.id !== '') {
        objekHitungTidakFinal.jumlahTanggungan = objekHitungTidakFinal.jumlahTanggungan.id
      }
    }

    if (!objekHitungTidakFinal.jumlahTanggungan) {
      objekHitungTidakFinal.jumlahTanggungan = 0
    }

    if (!objekHitungTidakFinal.phkpSebelumnya) {
      objekHitungTidakFinal.phkpSebelumnya = 0
    }

    if (!objekHitungTidakFinal.brutoSebelumnya50Persen) {
      objekHitungTidakFinal.brutoSebelumnya50Persen = 0
    }

    return objekHitungTidakFinal
  }

  async beforeSave(value) {

    if(!this.state.calculated) {
      this.toast.warning({title: 'warning', message: 'Mohon di kalkulasi terlebih dahulu'})
      return false;
    }

    if(value.bagB.bruto == 0 || value.bagB.bruto == '') {
      this.toast.warning({title:'Warning', message:'Isi bruto harus lebih besar dari 0'})
      return false;
    }

    var date;

    if(typeof value.bagC.tanggal === 'string') {
      if(value.bagC.tanggal.search('/') != -1) {
        date = moment(value.bagC.tanggal, 'DD/MM/YYYY');
      } else {
        date = moment(value.bagC.tanggal, 'YYYY-MM-DD');
      }
    } else if(typeof value.bagC.tanggal === 'object') {
      date = moment(value.bagC.tanggal)
    }

    if(!(date.year() == this.props.spt.data.year && date.month()+1 == this.props.spt.data.month)) {
      this.toast.warning({title:'Warning', message:'Tanggal bukti potong harus sesuai dengan masa SPT'})
      return false;
    }

    // Remapping Objek
    value.ref               = value.referensi
    value.no                = value.no
    value.spt               = value.spt21
    value.name              = value.bagA.nama
    value.npwp              = value.bagA.npwp
    value.nik               = value.bagA.nik
    value.address           = value.bagA.alamat
    value.email             = value.bagA.email
    value.countryCode       = value.bagA.kodeNegara
    value.foreignTaxPrayer  = value.bagA.wpLuarNegeri
    value.bruto             = value.bagB.bruto
    value.dpp               = value.bagB.dpp
    value.pph               = value.bagB.pph
    value.rates             = value.bagB.tarif
    value.taxObjectCode     = value.bagB.kodeObjekPajak
    value.nonNpwp           = value.bagB.nonNpwp
    value.cuttingDate       = value.bagC.tanggal
    value.signName          = value.bagC.nama
    value.signNpwp          = value.bagC.npwp
    value.numbering         = false

    let objekHitungTidakFinal = value.objekHitungTidakFinal

    objekHitungTidakFinal = await this.convertObjectHitungTidakFinal(objekHitungTidakFinal);

    value.akumBruto50Sebelumnya = objekHitungTidakFinal.brutoSebelumnya50Persen
    value.caraPembayaran = objekHitungTidakFinal.caraPembayaran
    value.dipotongOleh = objekHitungTidakFinal.dipotongOleh
    value.jumlahHariKerja = objekHitungTidakFinal.jumlahHariKerja
    value.jumlahTanggungan = objekHitungTidakFinal.jumlahTanggungan
    value.akumPhkpSebelumnya = objekHitungTidakFinal.phkpSebelumnya
    value.statusPtkp = objekHitungTidakFinal.statusPtkp
    value.tipePenghasilan = objekHitungTidakFinal.tipePenghasilan
    // Remapping Objek

    if(typeof value.cuttingDate == "object") value.cuttingDate = moment(value.cuttingDate).format("YYYY-MM-DD");
    if(typeof value.cuttingDate == "string" && value.cuttingDate.indexOf('Z') != -1) value.cuttingDate = moment(value.cuttingDate, 'YYYY-MM-DD');

    return true;
  }

  async afterSave(res, val) {
    val.foreignEmployee = val.foreignTaxPrayer
    val.npwpProfile = val.signNpwp
    if(val.id) delete val.id;
    await MasterLawanTransaksiPpService.api.findOrCreate(val)
    // var formData = this.props.formData.values;

    // var data = {
    //   ...formData.bagA,
    //   organization: this.props.spt.data.organization
    // }
    // if(data.id) delete data.id;
    // await MasterLawanTransaksiPpService.api.findOrCreate(data)
  }

  async initData() {
    if(this.props.match.params.id == 'new') {

      // let nomorBP = await NomorBPService.api.get(this.props.spt.data.organization.id, "BP_21_TF")
      let nomorBP = await NomorBPService.api.get(this.props.spt.data.id)
      // let signer = await SPT21SignerService.api.getSigner(this.props.spt.data.id);

      this.initialData = {
        ...this.initialData,
        no: '1.3-'+UtilService.date.MM(this.props.spt.data.month)+'.'+UtilService.date.YY(this.props.spt.data.year)+'-'+nomorBP.data.tf,
        bagA: {},
        bagC:{
          nama: this.props.organization.name,
          npwp: this.props.organization.npwp,
          tanggal: new Date()
        },
        spt21: this.props.spt.data
      }
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      this.setState({calculated: true})

      res.data.objekHitungTidakFinal = {}

      // Remapping Objek
      res.data.referensi         = res.data.ref
      res.data.no                = res.data.no
      res.data.spt21             = res.data.spt
      res.data.bagA = {
        nama: res.data.name,
        npwp: res.data.npwp,
        nik: res.data.nik,
        alamat: res.data.address,
        email: res.data.email,
        kodeNegara: res.data.countryCode,
        wpLuarNegeri: res.data.foreignTaxPrayer,
      }
      res.data.bagB = {
        bruto: res.data.bruto,
        dpp: res.data.dpp,
        pph: res.data.pph,
        tarif: res.data.rates,
        kodeObjekPajak: res.data.taxObjectCode,
        nonNpwp: res.data.nonNpwp,
      }
      res.data.bagC = {
        tanggal: res.data.cuttingDate,
        nama: res.data.signName,
        npwp: res.data.signNpwp
      }
      res.data.numbering         = res.data.numbering

      let caraPembayaran = res.data.caraPembayaran
      if (caraPembayaran === 'B') res.data.objekHitungTidakFinal.caraPembayaran = {id: 'B', name: 'Bulanan'}
      if (caraPembayaran === 'TB') res.data.objekHitungTidakFinal.caraPembayaran = {id: 'TB', name: 'Tidak Bulanan'}

      if (res.data.jumlahHariKerja != null && res.data.jumlahHariKerja !== '') {
        res.data.objekHitungTidakFinal.jumlahHariKerja = res.data.jumlahHariKerja
      }

      let tipePenghasilan = res.data.tipePenghasilan
      if (tipePenghasilan === 'BG') res.data.objekHitungTidakFinal.tipePenghasilan = {id: 'BG', name: 'Berkesinambungan'}
      if (tipePenghasilan === 'TBG') res.data.objekHitungTidakFinal.tipePenghasilan = {id: 'TBG', name: 'Tidak Berkesinambungan'}

      let dipotongOleh = res.data.dipotongOleh
      if (dipotongOleh === 'SPK') res.data.objekHitungTidakFinal.dipotongOleh = {id: 'SPK', name: 'Satu pemberi kerja'}
      if (dipotongOleh === 'LSPK') res.data.objekHitungTidakFinal.dipotongOleh = {id: 'LSPK', name: 'Lebih dari satu pemberi kerja'}

      let statusPtkp = res.data.statusPtkp
      if (statusPtkp === 'TK') res.data.objekHitungTidakFinal.statusPtkp = {id: 'TK', name: 'TK'}
      if (statusPtkp === 'K') res.data.objekHitungTidakFinal.statusPtkp = {id: 'K', name: 'K'}
      if (statusPtkp === 'K/I') res.data.objekHitungTidakFinal.statusPtkp = {id: 'K/I', name: 'K/I'}

      let jumlahTanggungan = res.data.jumlahTanggungan
      if (jumlahTanggungan === 0) res.data.objekHitungTidakFinal.jumlahTanggungan = {id: 0, name: '0'}
      if (jumlahTanggungan === 1) res.data.objekHitungTidakFinal.jumlahTanggungan = {id: 1, name: '1'}
      if (jumlahTanggungan === 2) res.data.objekHitungTidakFinal.jumlahTanggungan = {id: 2, name: '2'}
      if (jumlahTanggungan === 3) res.data.objekHitungTidakFinal.jumlahTanggungan = {id: 3, name: '3'}

      if (res.data.akumPhkpSebelumnya != null && res.data.akumPhkpSebelumnya !== '') {
        res.data.objekHitungTidakFinal.phkpSebelumnya = res.data.akumPhkpSebelumnya
      }

      if (res.data.akumBruto50Sebelumnya != null && res.data.akumBruto50Sebelumnya !== '') {
        res.data.objekHitungTidakFinal.brutoSebelumnya50Persen = res.data.akumBruto50Sebelumnya
      }
      // Remapping Objek

      this.props.initialize(res.data);
      // this.npwpValidation(null, res.data.bagA.npwp)

      this.setState({npwpNotValid: false})
    }
  }

  async calculateAkumulasiBruto() {
    this.setState({showCalculateProgress: true, calculated: true})
    var formData = this.props.formData.values;
    try {
      let objekHitungTidakFinal = this.props.formData.values.objekHitungTidakFinal

      objekHitungTidakFinal = await this.convertObjectHitungTidakFinal(objekHitungTidakFinal);

      formData.objekHitungTidakFinal.phkpSebelumnya = 0
      formData.objekHitungTidakFinal.brutoSebelumnya50Persen = 0

      this.props.initialize(formData);

      let data = {
        npwp: formData.bagA.npwp,
        nik: formData.bagA.nik,
        year: formData.spt21.year,
        month: formData.spt21.month,
        kodeObjekPajak: formData.bagB.kodeObjekPajak,
        tipePenghasilan: objekHitungTidakFinal.tipePenghasilan,
        dipotongOleh: objekHitungTidakFinal.dipotongOleh,
        statusPtkp: objekHitungTidakFinal.statusPtkp,
        jumlahTanggungan: objekHitungTidakFinal.jumlahTanggungan,
        spt21: formData.spt21,
      }

      let idParam = this.props.match.params.id

      if(idParam !== 'new') {
        data.id = idParam
      }

      if (data.kodeObjekPajak === '21-100-03' || data.kodeObjekPajak === '21-100-09' || data.kodeObjekPajak === '21-100-13' || data.kodeObjekPajak === '21-100-99' || data.kodeObjekPajak === '27-100-99') {
        this.toast.warning({title: "Kalkulasi Akum Bruto/PHKP", message: `Kode pajak "${data.kodeObjekPajak}" tidak bisa hitung akumulasi sebelumnya.`})

        this.setState({showCalculateProgress: false})

        return
      }

      if (data.kodeObjekPajak === '21-100-04' || data.kodeObjekPajak === '21-100-05' || data.kodeObjekPajak === '21-100-06' || data.kodeObjekPajak === '21-100-07' || data.kodeObjekPajak === '21-100-08') {
        if (data.tipePenghasilan !== 'BG') {
          this.toast.warning({title: "Kalkulasi Akum Bruto/PHKP", message: `Hanya tipe penghasilan berkesinambungan yang dapat dihitung untuk kode pajak "${data.kodeObjekPajak}".`})

          this.setState({showCalculateProgress: false})

          return
        }
      }

      var res = await BP21TFService.api.calculateAkumulasi(data)

      if (data.kodeObjekPajak === '21-100-04' || data.kodeObjekPajak === '21-100-05' || data.kodeObjekPajak === '21-100-06' || data.kodeObjekPajak === '21-100-07' || data.kodeObjekPajak === '21-100-08') {
        if (data.dipotongOleh === 'SPK') {
          if (data.npwp === '000000000000000') {
            formData.objekHitungTidakFinal.brutoSebelumnya50Persen = res.data
          }
          else {
            formData.objekHitungTidakFinal.phkpSebelumnya = res.data
          }
        }
        else {
          formData.objekHitungTidakFinal.brutoSebelumnya50Persen = res.data
        }
      }

      if (data.kodeObjekPajak === '21-100-10' || data.kodeObjekPajak === '21-100-11' || data.kodeObjekPajak === '21-100-12') {
        formData.objekHitungTidakFinal.phkpSebelumnya = res.data
      }

      this.props.initialize(formData);
    } catch(e) {}

    this.setState({showCalculateProgress: false})
  }

  async calculate() {
    try {
      this.setState({showCalculateProgress: true, calculated: true})
      var formData = this.props.formData.values;
      try {
        let objekHitungTidakFinal = this.props.formData.values.objekHitungTidakFinal
        let { caraPembayaran, dipotongOleh, jumlahTanggungan, statusPtkp, tipePenghasilan } = objekHitungTidakFinal

        if (jumlahTanggungan === undefined) {
          jumlahTanggungan = 0

          objekHitungTidakFinal.jumlahTanggungan = jumlahTanggungan
        }

        if (!objekHitungTidakFinal.phkpSebelumnya) {
          objekHitungTidakFinal.phkpSebelumnya = 0
        }

        if (!objekHitungTidakFinal.brutoSebelumnya50Persen) {
          objekHitungTidakFinal.brutoSebelumnya50Persen = 0
        }

        if (typeof caraPembayaran === 'object') {
          if (caraPembayaran.id !== undefined && caraPembayaran.id !== null) {
            objekHitungTidakFinal.caraPembayaran = caraPembayaran.id
          }
        }

        if (typeof dipotongOleh === 'object') {
          if (dipotongOleh.id !== undefined && dipotongOleh.id !== null) {
            objekHitungTidakFinal.dipotongOleh = dipotongOleh.id
          }
        }

        if (typeof jumlahTanggungan === 'object') {
          if (jumlahTanggungan.id !== undefined && jumlahTanggungan.id !== null) {
            objekHitungTidakFinal.jumlahTanggungan = jumlahTanggungan.id
          }
        }

        if (typeof statusPtkp === 'object') {
          if (statusPtkp.id !== undefined && statusPtkp.id !== null) {
            objekHitungTidakFinal.statusPtkp = statusPtkp.id
          }
        }

        try {
          if (typeof tipePenghasilan === 'object') {
            if (tipePenghasilan.id !== undefined && tipePenghasilan.id !== null) {
              objekHitungTidakFinal.tipePenghasilan = tipePenghasilan.id
            }
          }
        } catch(e){}

        var param = {
          objekHitungTidakFinal: {
            ...objekHitungTidakFinal,
            kodeObjekPajak: formData.bagB.kodeObjekPajak,
            bruto: formData.bagB.bruto,
            grossUp: !(!formData.bagB.grossUp),
            tanpaNpwp: false
          },
          spt21: this.props.spt.data
        }
        //param.spt21.orgId = param.spt21.organization.id;

        if(formData.bagA.npwp === '000000000000000' || formData.bagA.npwp === "") param.objekHitungTidakFinal.tanpaNpwp = true;
        param.sptId = param.spt21.id
        param.objek = param.objekHitungTidakFinal
        var res = await BP21TFService.api.calculate(param)

        formData.bagB.tarif = res.rates;
        formData.bagB.pph = res.pph;

        let grossUp = formData.bagB.grossUp;

        if (grossUp) {
          formData.bagB.bruto = res.bruto;
        }

        let kodeObjekPajak = formData.bagB.kodeObjekPajak;

        if (kodeObjekPajak === '21-100-99') {
          formData.bagB.dpp = res.bruto;
        }
        else {
          let dppFull = ['21-100-03', '21-100-10', '21-100-11', '21-100-12', '21-100-13', '27-100-99'];

          if (dppFull.indexOf(kodeObjekPajak) !== -1) {
            formData.bagB.dpp = res.dpp;
          }
          else {
            formData.bagB.dpp = Math.floor(res.bruto * 50 / 100);
          }
        }

        formData.bagB.dppCalculate = res.dpp;
        formData.bagB.nonNpwp = res.nonNpwp;

        this.props.initialize(formData)
      } catch(e) {
        console.error("error => ", e)
      }
      this.setState({showCalculateProgress: false})
    } catch(e){
      console.error("error => ", e)
    }
  }

  async npwpValidation(evt, value){
    if(this.typingObj) clearTimeout(this.typingObj);

    this.typingObj = setTimeout(async ()=> {
      if(value){
        var res = await NpwpService.api.validate(value)
        if(res.valid){
          this.setState({npwpNotValid: false})

          var lk = await MasterLawanTransaksiPpService.api.find({
            'npwp.equals': value,
            'npwpProfile.equals': this.props.organization.npwp
          })

          if(lk.data) {
            var formData = {...this.props.formData.values};

            if(lk.data.length > 0) {
              var d = lk.data[0];
              formData.bagA = {
                "npwp" : d.npwp,
                "nik" : d.nik,
                "nama" : d.name,
                "alamat" : d.address,
                "wpLuarNegeri" : d.foreignEmployee,
                "kodeNegara" : d.countryCode,
                "email" : d.email
              };
            } else {
              formData.bagA = {
                "npwp" : value
              }
              this.toast.info({title: "Info", message: `Lawan Transaksi dengan npwp ${value} tidak ditemukan`})
            }
            this.props.initialize(formData);
          }
        } else {
          this.setState({npwpNotValid: true})
          this.toast.warning({title: "Validasi Npwp", message: "npwp tidak valid"})
        }
      }
    }, this.typingTimeout);
  }

  formView() {
    var formData = {bagB: {}, bagA: {}, objekHitungTidakFinal: {}};
    if(this.props.formData && this.props.formData.values) formData = this.props.formData.values
    if(!formData.objekHitungTidakFinal) {
      formData.objekHitungTidakFinal = {
        brutoSebelumnya50Persen: 0,
        bruto: 0,
        phkpSebelumnya:0,
        statusPtkp: 'TK',
        jumlahTanggungan: 0
      }
    }

    let isTypePenghasilanOptionAllShow = true
    let isTypePenghasilanOptionBgShow = false
    let isTypePenghasilanOptionTbgShow = false
    var typePenghasilanOptionAll = [{id: 'BG', name: 'Berkesinambungan'}, {id: 'TBG', name: 'Tidak Berkesinambungan'}];
    var typePenghasilanOptionBg = [{id: 'BG', name: 'Berkesinambungan'}]
    var typePenghasilanOptionTbg = [{id: 'TBG', name: 'Tidak Berkesinambungan'}]

    if(formData.bagB.kodeObjekPajak === '21-100-08') {
      isTypePenghasilanOptionAllShow = false
      isTypePenghasilanOptionBgShow = true
    } else if(formData.bagB.kodeObjekPajak === '21-100-09') {
      isTypePenghasilanOptionAllShow = false
      isTypePenghasilanOptionTbgShow = true
    }

    let isStatusPtkpShow = (this.bagBConfig[formData.bagB.kodeObjekPajak].statusPtkp && formData.objekHitungTidakFinal.dipotongOleh !== 'LSPK' && formData.objekHitungTidakFinal.tipePenghasilan !== 'TBG' && formData.bagA.npwp !== '000000000000000') || formData.bagB.kodeObjekPajak === '21-100-03'
    let isJumlahTanggunganShow = (this.bagBConfig[formData.bagB.kodeObjekPajak].jumlahTanggungan  && formData.objekHitungTidakFinal.dipotongOleh !== 'LSPK' && formData.objekHitungTidakFinal.tipePenghasilan !== 'TBG' && formData.bagA.npwp !== '000000000000000') || formData.bagB.kodeObjekPajak === '21-100-03'

    return (
      <div>
        <LawanTransaksiDialog
          spt={this.props.spt.data}
          onSelect={(d)=> {
            var formData = {...this.props.formData.values};
            if(!d.foreignEmployee) d.foreignEmployee = false;

            formData.bagA = {
              "npwp" : d.npwp,
              "nik" : d.nik,
              "nama" : d.name,
              "alamat" : d.address,
              "wpLuarNegeri" : d.foreignEmployee,
              "kodeNegara" : d.countryCode,
              "email" : d.email
            };

            this.setState({npwpNotValid: false})

            this.props.initialize(formData);

          }}
          service={MasterLawanTransaksiPpService}
          visible={this.state.lkDialog}
          onHide={()=> this.setState({lkDialog: false})}
        />
        <Card>
          <div className="md-grid">
            <Field
              label='No.Bukti Potong'
              name='no'
              className="md-cell md-cell--6"
              component={Textfield}
              validate={validation.required}
            />
            <Field
              label='Tanggal Bukti Potong'
              name='bagC.tanggal'
              id="BP21FForm-noBp"
              className="md-cell md-cell--6"
              component={Datepicker}
              validate={validation.required}
            />
            <Field
              label='Referensi'
              name='referensi'
              id="BP21FForm-referensi"
              className="md-cell md-cell--12"
              component={Textfield}
            />
          </div>
        </Card>

        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. IDENTITAS PENERIMA PENGHASILAN YANG DIPOTONG ' />
          <Divider/>
          <div className="md-grid">
            <div className='md-cell md-cell--6'>
              <div className='mpk-layout'>
                <Field
                  className='flex'
                  label='NPWP'
                  name='bagA.npwp'
                  id="BP21FForm-npwp"
                  component={TextfieldMask}
                  maskFormat="##.###.###.#-###-###"
                  mask="_"
                  length={15}
                  onChange={this.npwpValidation.bind(this)}
                  validate={validation.required}
                />
                <Button icon primary swapTheming style={{marginTop: 10}} onClick={()=> this.setState({lkDialog: true})}>search</Button>
              </div>

              <Field
                label='Nama'
                name='bagA.nama'

                disabled={this.state.npwpNotValid}
                component={Textfield}
                validate={validation.required}
              />
              <Field
                label='NIK'
                name='bagA.nik'

                disabled={this.state.npwpNotValid}
                component={Textfield}
                validate={validation.required}
              />
              {/* <Field
                label='Telepon'
                name='name'
                id="BP21FForm-name"
                component={Textfield} */}

            </div>
            <div className='md-cell md-cell--6'>
              {/* <Field
                label='Kode Poss'
                name='npwp'
                id="BP21FForm-codePoss"
                component={Textfield}
              /> */}
              <Field
                label='Alamat'
                name='bagA.alamat'

                disabled={this.state.npwpNotValid}
                component={Textfield}
                validate={validation.required}
              />
              <Field
                label='Email'
                name='bagA.email'

                disabled={this.state.npwpNotValid}
                component={Textfield}
              />
              <Field
                label='Warga Negara Asing'
                name='bagA.wpLuarNegeri'
                disabled={this.state.npwpNotValid}
                component={Switch}
              />

              {formData.bagA.wpLuarNegeri &&
                <Field
                  label='Kode Negara Domisili'
                  name='bagA.kodeNegara'
                  disabled={this.state.npwpNotValid}
                  component={Searchfield}
                  service={CountryCodeService}
                  valueField="code"
                />
              }
            </div>
          </div>
        </Card>

        <br/>

        {this.state.showCalculateProgress && <LinearProgress id='lin_pro' style={{margin: 0}} />}
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. PPH PASAL 21/26 YANG DIPOTONG' />
          <Divider/>
          <div className="md-grid">
            <Field
                    validate={validation.required}
                    className='md-cell md-cell--12'
                    label='Objek Pajak'
                    name='bagB.kodeObjekPajak'
                    id="BP21FForm-codePoss2"
                    viewType='dialog'
                    component={Searchfield}
                    menuWidth={600}
                    onChange={(e, v, p)=> {
                      if(v === '21-100-08') {
                        this.props.change('objekHitungTidakFinal.tipePenghasilan', 'B')
                      } else if(v === '21-100-09') {
                        this.props.change('objekHitungTidakFinal.tipePenghasilan', 'TB')
                      } else {
                        this.props.change('objekHitungTidakFinal.tipePenghasilan', null)
                      }
                    }}
                    itemTemplate={function(d) {
                      return {
                        primaryText: d[this.props.viewField],
                        secondaryText: d.detail,
                        onClick: () => {this.handleItemClick(d); this.hide() }
                      }
                    }}
                    options={[
                      {id: '21-100-03', name:'21-100-03', detail: 'Upah Pegawai Tidak tetap atau Tenaga Kerja Lepas'},
                      {id: '21-100-04', name:'21-100-04', detail: 'Imbalan Kepada Distributor Multi Level Marketing (MLM)'},
                      {id: '21-100-05', name:'21-100-05', detail: 'Imbalan kepada Petugas Dinas Luar Asuransi'},
                      {id: '21-100-06', name:'21-100-06', detail: 'Imbalan Kepada Penjaja Barang Dagangan'},
                      {id: '21-100-07', name:'21-100-07', detail: 'Imbalan Kepada Tenaga Ahli'},
                      {id: '21-100-08', name:'21-100-08', detail: 'Imbalan Kepada Bukan Pegawai yang Menerima Penghasilan yang Bersifat Berkesinambungan'},
                      {id: '21-100-09', name:'21-100-09', detail: 'Imbalan Kepada Bukan Pegawai yang Menerima Penghasilan yang Tidak Bersifat Berkesinambungan'},
                      {id: '21-100-10', name:'21-100-10', detail: 'Honorarium atau Imbalan Kepada Anggota Dewan Komisaris atau Dewan Pengawas yang tidak Merangkap sebagai Pegawai Tetap'},
                      {id: '21-100-11', name:'21-100-11', detail: 'Jasa Produksi, Tantiem, Bonus atau Imbalan Kepada Mantan Pegawai'},
                      {id: '21-100-12', name:'21-100-12', detail: 'Penarikan Dana Pensiun oleh Pegawai'},
                      {id: '21-100-13', name:'21-100-13', detail: 'Imbalan Kepada Peserta Kegiatan'},
                      {id: '21-100-99', name:'21-100-99', detail: 'Objek PPh Pasal 21 Tidak Final Lainnya'},
                      {id: '27-100-99', name:'27-100-99', detail: 'Imbalan sehubungan dengan jasa, pekerjaan dan kegiatan, hadiah dan penghargaan, pensiun dan pembayaran berkala lainnya yang dipotong PPh Pasal 26'},]}
                    validate={validation.required}
                  />

                  <br/>

          </div>

          <div className="md-grid">
            <div className='md-cell md-cell--6'>
              {(this.bagBConfig[formData.bagB.kodeObjekPajak].tipePenghasilan && isTypePenghasilanOptionAllShow) && <Field
                label='Tipe Penghasilan'
                name='objekHitungTidakFinal.tipePenghasilan'
                disabled={!this.bagBConfig[formData.bagB.kodeObjekPajak].tipePenghasilan}
                component={Searchfield}
                options={typePenghasilanOptionAll}
              />}
              {(this.bagBConfig[formData.bagB.kodeObjekPajak].tipePenghasilan && isTypePenghasilanOptionBgShow) && <Field
                label='Tipe Penghasilan'
                name='objekHitungTidakFinal.tipePenghasilan'
                disabled={!this.bagBConfig[formData.bagB.kodeObjekPajak].tipePenghasilan}
                component={Searchfield}
                options={typePenghasilanOptionBg}
              />}
              {(this.bagBConfig[formData.bagB.kodeObjekPajak].tipePenghasilan && isTypePenghasilanOptionTbgShow) && <Field
                label='Tipe Penghasilan'
                name='objekHitungTidakFinal.tipePenghasilan'
                disabled={!this.bagBConfig[formData.bagB.kodeObjekPajak].tipePenghasilan}
                component={Searchfield}
                options={typePenghasilanOptionTbg}
              />}
              {this.bagBConfig[formData.bagB.kodeObjekPajak].dipotongOleh && formData.objekHitungTidakFinal.tipePenghasilan !== 'TBG' && <Field
                label='Dipotong Oleh'
                name='objekHitungTidakFinal.dipotongOleh'
                disabled={!this.bagBConfig[formData.bagB.kodeObjekPajak].dipotongOleh}
                component={Searchfield}
                options={[{id: 'SPK', name: 'Satu pemberi kerja'}, {id: 'LSPK', name: 'Lebih dari satu pemberi kerja'}]}
              />}
              {this.bagBConfig[formData.bagB.kodeObjekPajak].caraPembayaran && <Field
                label='Cara Pembayaran'
                name='objekHitungTidakFinal.caraPembayaran'
                disabled={!this.bagBConfig[formData.bagB.kodeObjekPajak].caraPembayaran}
                component={Searchfield}
                onChange={this.handleCaraPembayaran.bind(this)}
                options={[{id: 'B', name: 'Bulanan'}, {id: 'TB', name: 'Tidak Bulanan'}]}
              />}
              {isStatusPtkpShow && <Field
                label='Status PTKP'
                name='objekHitungTidakFinal.statusPtkp'
                disabled={!this.bagBConfig[formData.bagB.kodeObjekPajak].statusPtkp}
                component={Searchfield}
                options={[{id: 'TK', name: 'TK'}, {id: 'K', name: 'K'}, {id: 'K/I', name: 'K/I'}]}
              />}
              {isJumlahTanggunganShow && <Field
                label='Jumlah Tanggungan'
                name='objekHitungTidakFinal.jumlahTanggungan'
                disabled={!this.bagBConfig[formData.bagB.kodeObjekPajak].jumlahTanggungan}
                component={Searchfield}
                options={[{id: 0, name: '0'}, {id: 1, name: '1'}, {id: 2, name: '2'}, {id: 3, name: '3'}]}
              />}
            </div>
            <div className='md-cell md-cell--6'>
              {(this.bagBConfig[formData.bagB.kodeObjekPajak].jumlahHariKerja && this.state.showJumlahHariKerja) &&
              <Field
                label='Jumlah Hari Kerja Dalam 1 Bulan'
                name='objekHitungTidakFinal.jumlahHariKerja'
                disabled={!this.bagBConfig[formData.bagB.kodeObjekPajak].jumlahHariKerja}
                component={Textfield}
              />}
              {this.bagBConfig[formData.bagB.kodeObjekPajak].phkpSebelumnya && formData.objekHitungTidakFinal.tipePenghasilan !== 'TBG' && formData.objekHitungTidakFinal.dipotongOleh !== 'LSPK' && <Field
                label='Akumulasi Penghasilan Kena Pajak Masa Sebelumnya'
                name='objekHitungTidakFinal.phkpSebelumnya'
                disabled={!this.bagBConfig[formData.bagB.kodeObjekPajak].phkpSebelumnya}
                component={TextfieldMask}
                money={','}
              />}
              {this.bagBConfig[formData.bagB.kodeObjekPajak].brutoSebelumnya50Persen && formData.objekHitungTidakFinal.dipotongOleh !== 'SPK' && formData.objekHitungTidakFinal.tipePenghasilan !== 'TBG' && <Field
                label='50% Akumulasi Penghasilan Bruto Masa Sebelumnya'
                name='objekHitungTidakFinal.brutoSebelumnya50Persen'
                disabled={!this.bagBConfig[formData.bagB.kodeObjekPajak].brutoSebelumnya50Persen}
                component={TextfieldMask}
                money={','}
              />}
              {this.bagBConfig[formData.bagB.kodeObjekPajak].bruto && <Field
                label='Jumlah Penghasilan Bruto'
                name='bagB.bruto'
                disabled={!this.bagBConfig[formData.bagB.kodeObjekPajak].bruto}
                component={TextfieldMask}
                money={','}
              />}
            </div>
          </div>

          <DataTable plain >
            <TableHeader>
              <TableRow>
                <TableColumn>Kode Objek Pajak</TableColumn>
                <TableColumn className='md-text-right'>Bruto(Rp)</TableColumn>
                <TableColumn>Dasar Pengenaan Pajak (Rp)</TableColumn>
                <TableColumn>Tanpa NPWP</TableColumn>
                <TableColumn className='md-text-right'>Tarif(%)</TableColumn>
                <TableColumn className='md-text-right'>PPH Di Potong(Rp)</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>
                  <Field
                    label='Objek Pajak'
                    name='bagB.kodeObjekPajak'
                    id="BP21FForm-codePoss"
                    viewType='dialog'
                    disabled={true}
                    component={Searchfield}
                    menuWidth={600}
                    onChange={(e, v, p)=> {
                      if(v === '21-100-08') {
                        this.props.change('objekHitungTidakFinal.tipePenghasilan', 'BG')
                      } else if(v === '21-100-09') {
                        this.props.change('objekHitungTidakFinal.tipePenghasilan', 'TBG')
                      } else {
                        this.props.change('objekHitungTidakFinal.tipePenghasilan', null)
                      }
                    }}
                    itemTemplate={function(d) {
                      return {
                        primaryText: d[this.props.viewField],
                        secondaryText: d.detail,
                        onClick: () => {this.handleItemClick(d); this.hide() }
                      }
                    }}
                    options={[
                      {id: '21-100-03', name:'21-100-03', detail: 'Upah Pegawai Tidak tetap atau Tenaga Kerja Lepas'},
                      {id: '21-100-04', name:'21-100-04', detail: 'Imbalan Kepada Distributor Multi Level Marketing (MLM)'},
                      {id: '21-100-05', name:'21-100-05', detail: 'Imbalan kepada Petugas Dinas Luar Asuransi'},
                      {id: '21-100-06', name:'21-100-06', detail: 'Imbalan Kepada Penjaja Barang Dagangan'},
                      {id: '21-100-07', name:'21-100-07', detail: 'Imbalan Kepada Tenaga Ahli'},
                      {id: '21-100-08', name:'21-100-08', detail: 'Imbalan Kepada Bukan Pegawai yang Menerima Penghasilan yang Bersifat Berkesinambungan'},
                      {id: '21-100-09', name:'21-100-09', detail: 'Imbalan Kepada Bukan Pegawai yang Menerima Penghasilan yang Tidak Bersifat Berkesinambungan'},
                      {id: '21-100-10', name:'21-100-10', detail: 'Honorarium atau Imbalan Kepada Anggota Dewan Komisaris atau Dewan Pengawas yang tidak Merangkap sebagai Pegawai Tetap'},
                      {id: '21-100-11', name:'21-100-11', detail: 'Jasa Produksi, Tantiem, Bonus atau Imbalan Kepada Mantan Pegawai'},
                      {id: '21-100-12', name:'21-100-12', detail: 'Penarikan Dana Pensiun oleh Pegawai'},
                      {id: '21-100-13', name:'21-100-13', detail: 'Imbalan Kepada Peserta Kegiatan'},
                      {id: '21-100-99', name:'21-100-99', detail: 'Objek PPh Pasal 21 Tidak Final Lainnya'},
                      {id: '27-100-99', name:'27-100-99', detail: 'Imbalan sehubungan dengan jasa, pekerjaan dan kegiatan, hadiah dan penghargaan, pensiun dan pembayaran berkala lainnya yang dipotong PPh Pasal 26'},]}
                    validate={validation.required}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    label='Bruto'
                    name='bagB.bruto'
                    disabled={!(formData.bagB.kodeObjekPajak === '21-499-99' || formData.bagB.kodeObjekPajak === '27-100-99' || formData.bagB.kodeObjekPajak === '21-100-99')}
                    component={TextfieldMask}
                    money={','}
                    validate={validation.required}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    label='Dasar Pengenaan Pajak'
                    name='bagB.dpp'
                    disabled={!(formData.bagB.kodeObjekPajak === '21-499-99' || formData.bagB.kodeObjekPajak === '27-100-99' || formData.bagB.kodeObjekPajak === '21-100-99')}
                    onChange={(e, v) => {
                      try {
                        if(formData.bagB.kodeObjekPajak === '21-100-99') {
                          this.props.change('bagB.pph', parseFloat(v)*parseFloat(formData.bagB.tarif)/100)
                        }
                      } catch(e){
                        console.log(e, '2023')
                      }
                    }}
                    component={TextfieldMask}
                    money={','}

                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    label=''
                    name='bagB.nonNpwp'
                    disabled={!(formData.bagB.kodeObjekPajak === '21-499-99' || formData.bagB.kodeObjekPajak === '27-100-99')}
                    component={Switch}

                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    label='Tarif'
            
                    disabled={!(formData.bagB.kodeObjekPajak === '21-499-99' || formData.bagB.kodeObjekPajak === '27-100-99' || formData.bagB.kodeObjekPajak === '21-100-99')}
                    component={TextfieldMask}
                    onChange={(e, v) => {
                      try {
                        if(formData.bagB.kodeObjekPajak === '21-100-99') {
                          this.props.change('bagB.dpp', parseFloat(v)*parseFloat(formData.bagB.dpp)/100)
                        }
                      } catch(e){
                        console.log(e, '2023')
                      }
                    }}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    label='PPH'
                    name='bagB.pph'
                    component={TextfieldMask}
                    money={','}
                    disabled={!(formData.bagB.kodeObjekPajak === '21-499-99' || formData.bagB.kodeObjekPajak === '27-100-99')}

                  />
                </TableColumn>
              </TableRow>
            </TableBody>
          </DataTable>


          {formData.bagB.kodeObjekPajak !== '21-499-99' &&
          <CardActions className='md-divider-border md-divider-border--top mpk-layout'>
            <Field
              label='Gross Up'
              name='bagB.grossUp'
              component={Switch}
            />
            <div className='flex' />
            <Button flat secondary onClick={()=> this.calculateAkumulasiBruto()}>Calculate Akum Bruto/PHKP</Button>
            <Button flat secondary onClick={()=> this.calculate()}>Calculate</Button>
          </CardActions>
          }
        </Card>

        <br />

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='C. IDENTITAS PEMOTONG ' />
          <Divider/>

          <div className="md-grid">
          <Field
            label='NPWP'
            name='bagC.npwp'
            className="md-cell md-cell--6"
            disabled
            component={TextfieldMask}
            maskFormat="##.###.###.#-###-###"
            mask="_"
            validate={validation.required}
          />
          <Field
            label='Nama'
            name='bagC.nama'
            className="md-cell md-cell--6"
            disabled
            component={Textfield}
            validate={validation.required}
          />
          </div>
        </Card>

      </div>
    )
  }
}
