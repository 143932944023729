import React from 'react';
import {connect} from 'react-redux';
import {Field, getFormValues, reduxForm} from 'redux-form';
import {Avatar, Card, DataTable, Divider, FontIcon, List, ListItem, TableBody, TableColumn, TableRow} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import NomorBP15Service from '../nomorBP15.service';
import {
  convert,
  Radio,
  Searchfield,
  Switch,
  Textfield,
  TextfieldMask,
  validation
} from '../../../../../components/form';
import izitoast from "izitoast";

@reduxForm({form: 'SettingNoBupot15.Form', destroyOnUnmount: true})
@connect((state) => ({
  access: state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt: state.authEppt.currentSPT,
  formData: getFormValues('SettingNoBupot15.Form')(state)
}))
export default class SettingNoBupot15Form extends FormView {
  service = NomorBP15Service
  viewType = 2;

  titleHeader() {
    return `entities.SettingNoBupot15.title`;
  }

  async initData() {
    let sptId = this.props.match.params.sptId
    let res = await NomorBP15Service.api.get(this.props.spt.data.id, null)
    this.props.initialize(res.data);
  }

  async handleSave(value) {
    try {
      this.setState({onProgress: true})

      let organization = this.props.spt.data.organization
      value.organization = organization

      console.log({value})

      if (value.id) {
        await this.service.api.update(value)
      } else {
        await this.service.api.save(value)
      }

      this.setState({onProgress: false})

      izitoast.show({title: 'success', message: 'Saved'})
    } catch (e) {
      let msg = e.message;

      if (e.response) msg = e.response.data.message;

      this.setState({
        isError: true,
        onProgress: false,
        errorMessage: msg
      })
    }
  }

  formView() {
    return (
      <div>
        <Card>
          <div className='md-grid'>
            <div className="md-cell md-cell--6">
              <Field
                label='Prefix'
                name='prefix'
                component={Textfield}
                validate={validation.required}
              />
            </div>

            <div className="md-cell md-cell--6">
              <Field
                label='Suffix'
                name='suffix'
                component={Textfield}
                validate={validation.required}
              />
            </div>

            <div className="md-cell md-cell--6">
              <Field
                label='Mode'
                name='mode'
                className="md-cell md-cell--12"
                component={Searchfield}
                options={[
                  {id: 'PREF_SUF', name: 'PREF SUF'},
                  {id: 'SUF_PREF', name: 'SUF_PREF'}
                ]}
                validate={validation.required}
              />
            </div>

            <div className="md-cell md-cell--6">
              <Field
                label='Increment'
                name='inc'
                component={Switch}
              />
            </div>
          </div>
        </Card>
        <br/>
      </div>
    )
  }

}
