import React, { useState, useEffect, useRef } from "react";
import { Button, DialogContainer, TextField, Card } from "react-md";
// import authService from "../services/authService";
import hostMpk from "../services/hosts";
import "./TFAPage.scss";
import Logo from "../components/Logo/Logo";
import { httpService } from "../../react-mpk";

const PasswordExpiration = ({ visible, onFinish, props }) => {
  const textInputConfirmPassword = useRef(null);
  const textInputPassword = useRef(null);
  const [password, setPassword] = useState("");
  const [verifyPassword, setVerifyPassword] = useState("");
  const env = hostMpk.getAll();

  useEffect(() => {
    if (visible) {
      if (textInputPassword.current) textInputPassword.current.focus();
    }
  }, [visible]);

  const handleConfirmQr = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const payload = {
      password,
      verifyPassword,
    };

    try {
      if (password === verifyPassword) {
        await httpService.post({
          url: `${env.iam.host}/auth/account/update_expired_password`,
          data: payload,
        });
        props.toastActions.izi(
          "Autentikasi",
          "Autentikasi berhasil",
          "success"
        );
        onFinish();
      } else {
        props.toastActions.izi(
          "Autentikasi",
          "Password dan Confirm Password Tidak Sesuai",
          "warning"
        );
      }
    } catch (error) {
      console.log(error.message);
      props.toastActions.izi(
        "Autentikasi",
        error.message ? error.message.id : "Autentikasi Gagal",
        "warning"
      );
    }
  };

  const handleSkipPassword = async () => {
    window.location = "/";
  };

  return (
    <DialogContainer
      id="simple-full-page-dialog"
      visible={visible}
      fullPage
      aria-labelledby="simple-full-page-dialog-title"
    >
      <div className="mpk-error-page mpk-layout fill column align-center justify-center">
        <Logo
          style={{
            height: 52,
          }}
        />
        <Card
          className="mpk-layout column align-center mpk-margin-N margin-all mpk-padding-N padding-all"
          style={{
            maxWidth: 500,
            marginTop: "1em",
          }}
        >
          <div className="mpk-layout mpk-margin-N bottom top">
            <div className="status-text text-body">
              Perbaharui Password (Password Expiration)
            </div>
          </div>
          <p className="error-message mpk-body text-body mpk-dont-break-out">
            {"Silahkan masukkan password baru & konfirmasi password"}
          </p>
          <form
            className="mpk-layout column align-end"
            id="password-form"
            onSubmit={handleConfirmQr}
            autocomplete={false}
          >
            <TextField
              id="application-form-password"
              label="Password"
              ref={textInputPassword}
              value={password}
              onChange={(text) => setPassword(text)}
              required
              type="password"
            />
            <TextField
              id="application-form-confirm-password"
              label="Confirm Password"
              ref={textInputConfirmPassword}
              value={verifyPassword}
              onChange={(text) => setVerifyPassword(text)}
              required
              type="password"
            />
            <div className="mpk-layout row mpk-margin-N margin-top">
              <Button
                secondary
                className="mpk-margin-S right"
                onClick={handleSkipPassword}
              >
                Lewati Tahap Ini
              </Button>
              <Button
                primary
                raised
                disabled={!password || !verifyPassword}
                type="submit"
              >
                Perbaharui Password
              </Button>
            </div>
          </form>
        </Card>
      </div>
    </DialogContainer>
  );
};

export default PasswordExpiration;
