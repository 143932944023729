
import React, { Component } from 'react';
import {Subheader, SelectField, DropdownMenu, TextField, Button, FontIcon, DialogContainer, List, ListItem } from 'react-md';
import _ from 'lodash';
var he = require('he')

export default class Searchfield extends Component {

  static defaultProps = {
    valueField: 'id',
    viewField: 'name',
    apiPath: 'find',
    remoteSearch: false,
    searchField: null,
    params: {},
    emptyData: {id: '', name: '', nama: '', kode: '', code: '', uraian: '', deskripsi: '', description: ''},
    itemTemplate: function(d) {
      return {
        primaryText: d[this.props.viewField],
        onClick: () => {
          if(!this.props.disabled){
            this.handleItemClick(d); this.hide()
          }
        }
      }
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      viewValue: '',
      defData: [],
      menuItems: [],
      visible: false
    }
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.rerender){
      this.buildMenuItems()
    }
    var curVal = this.props.input.value;
    var nextVal =  nextProps.input.value;
    if(typeof nextProps.input.value === 'object') {
      if(!curVal) curVal = {}
      if(curVal.id !== nextVal.id) {
        this.setState({viewValue: nextProps.input.value[this.props.viewField]})
      }
    } else {
      if(curVal !== nextVal) {
        if(this.props.options) {
          var options = this.props.options
          // console.log(options, this.props.valueField, nextVal)
          if(this.props.valueField == "parent"){
            var searchField = "npwp"
            if(isNaN(nextVal)){
              searchField = "name"
            } else {
              searchField = "npwp"
            }
            var option = options.filter((option)=>{
              return option[searchField] == nextVal
            })
          } else {
            var option = options.filter((option)=>{
              return option[this.props.valueField] == nextVal
            })
            var viewValue = option[0]
            var valueText = ""
            if(viewValue){
              valueText = viewValue[this.props.viewField]
            }
            this.setState({ viewValue : valueText })
          }
        }
        if(this.props.service){
          var params = {...this.props.params}
          var options = this.state.defData;
          var option = options.filter((option)=>{
            return option[this.props.valueField] == nextVal
          })
          var viewValue = option[0]
          var valueText = ""
          if(viewValue){
            valueText = viewValue[this.props.viewField]
          }
          this.setState({ viewValue : valueText })
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.options != prevProps.options){
      if(this.props.rerender){
        this.buildMenuItems()
      }
    }
  }

  async componentDidMount() {
    var value = this.props.input.value
    if(typeof value === 'object') {
      if(value.id) this.setState({viewValue: value[this.props.viewField]})
    } else {
      if(this.props.options) {
        var options = this.props.options
        var option = options.filter((option)=>{
          return option[this.props.valueField] == value
        })
        var viewValue = option[0]
        var valueText = ""
        if(viewValue){
          valueText = viewValue[this.props.viewField]
        }
        this.setState({ viewValue : valueText })
      }
      // if(this.props.service){
      //   var params = {...this.props.params}
      //   let res = await this.props.service.api[this.props.apiPath](params, ()=>{}, this.props.fetchOption);
      //   this.setState({
      //     defData: res.data
      //   });
      //   var options = res.data

      //   var option = options.filter((option)=>{
      //     return option[this.props.valueField] == value
      //   })
      //   var viewValue = option[0]
      //   var valueText = ""
      //   if(viewValue){
      //     valueText = viewValue[this.props.viewField]
      //   }
      //   this.setState({ viewValue : valueText })
      // }
    }


    // Testing Select Value Option console.log(value, options, option, viewValue, valueText)
    this.buildMenuItems(true)
  }

  handleItemClick(d) {
    var val = d[this.props.valueField];
    if(this.props.valueField == 'parent') val = d;
    this.props.input.value = val;
    this.props.input.onChange(val)
    this.setState({viewValue: d[this.props.viewField]})
  }

  async handleViewChange(val:String) {
    this.props.input.onChange(val)
    this.setState({viewValue: val});

    if(val.length > 1) {
      var searchField = this.props.viewField;
      if(this.props.searchField) searchField = this.props.searchField;
      var menuItems = [];
      if(!this.props.remoteSearch) {
        var filtered = _.filter(this.state.defData, (item) => {
          if(item.npwp){
            return item[searchField].toLowerCase().indexOf(val.toLowerCase()) > -1 || item.npwp.indexOf(val) > -1;
          } else {
            return item[searchField].toLowerCase().indexOf(val.toLowerCase()) > -1;
          }
        })
        menuItems = filtered.map(this.props.itemTemplate.bind(this))
      } else {
        var filter = {...this.props.params};
        if(typeof searchField == 'object') {
          searchField.forEach((d, i) => {
            filter[d+'.contains'] = val;
          })
        } else {
          filter[searchField+'.contains'] = val;
        }

        let res = await this.props.service.api[this.props.apiPath](filter, ()=>{}, this.props.fetchOption);
        menuItems = res.data.map(this.props.itemTemplate.bind(this))
      }
      this.setState({menuItems})
    } else {
      menuItems = this.state.defData.map(this.props.itemTemplate.bind(this))
      this.setState({menuItems})
    }

  }

  async buildMenuItems(first = false) {
    var menuItems = []
    var defData = []
    if(this.props.service) {
      var params = {...this.props.params}
      let res = await this.props.service.api[this.props.apiPath](params, ()=>{}, this.props.fetchOption);
      defData = res.data;
      defData.unshift(this.props.emptyData)
      menuItems = res.data.map(this.props.itemTemplate.bind(this))

      if(typeof value != 'object' && first) {
        var value = this.props.input.value
        var options = res.data

        var option = options.filter((option)=>{
          return option[this.props.valueField] == value
        })
        var viewValue = option[0]
        var valueText = ""
        if(viewValue){
          valueText = viewValue[this.props.viewField]
        }
        this.setState({ viewValue : valueText })
      }

    } else if(this.props.options) {
      defData = _.clone(this.props.options);
      defData.unshift(this.props.emptyData)

      menuItems = defData.map(this.props.itemTemplate.bind(this))
    }

    //console.log(menuItems[0], menuItems[1])

    this.setState({
      menuItems,
      defData
    })
    return menuItems;
  }

  hide = () => {
    this.setState({ visible: false });
  };


  render() {
    let input = this.props.input;
    let meta = this.props.meta;
    if(!meta) meta = {}
    let optInput = {...input}
    let disabled = this.props.disabled;
    let idProps = Math.random().toString(36).substring(7)+this.props.name;

    var menuItems = this.state.menuItems;
    if(disabled) menuItems = []

    var label = this.props.label;
    if(this.props.meta.error === "Required") label = this.props.label+' (*)'


    if(this.props.viewType == 'dialog') {
      return <div className={this.props.className}
      style={{
        display: 'block',
        ...this.props.style
      }}>
        <DialogContainer
          onHide={this.hide}
          id={this.props.id+"_menu"}
          visible={this.state.visible}
          title={this.props.label}
          width={this.props.menuWidth}
        >
          {!disabled &&
            <List>
              <ListItem primaryText='' />
              {this.state.menuItems.map((d, i)=> {
                return <ListItem key={i} {...d} />
              })}
            </List>
          }
        </DialogContainer>

        <TextField
          id={idProps}
          onChange={(val) => this.handleViewChange(val)}
          value={typeof this.state.viewValue === 'string' ? he.encode(this.state.viewValue) : this.state.viewValue}
          label={label}
          disabled={disabled}
          onFocus={()=> this.setState({visible: true})}
          inlineIndicator={<Button icon className="text-fields__inline-btn">keyboard_arrow_down</Button>}
          placeholder={this.props.placeholder}
          error={meta.touched && !!meta.error} errorText={meta.error}
        />

      </div>
    } else {
      var iconStyle = {};
      if(!label || label == '') iconStyle = {marginTop: -20}
      return <DropdownMenu
      id={this.props.id+"_menu"}
      menuItems={menuItems}
      toggleQuery=".md-text-field-container"
      sameWidth
      className={this.props.className}
      style={{
        display: 'block',
        ...this.props.style
      }}
      anchor={{
        x: DropdownMenu.HorizontalAnchors.INNER_LEFT,
        y: DropdownMenu.VerticalAnchors.BOTTOM,
      }}
      position={DropdownMenu.Positions.BELOW}
      {...this.props.dropdownProps}
    >
      <TextField
        id={idProps}
        onChange={(val) => this.handleViewChange(val)}
        value={typeof this.state.viewValue === 'string' ? he.encode(this.state.viewValue) : this.state.viewValue}
        label={label}
        disabled={disabled}
        inlineIndicator={<Button style={iconStyle} icon className="text-fields__inline-btn">keyboard_arrow_down</Button>}
        placeholder={this.props.placeholder}
        error={meta.touched && !!meta.error} errorText={meta.error}
      />
    </DropdownMenu>
    }
  }
}

