import React, { Component } from 'react'; import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {TabsContainer, Tabs, Tab, Button, DataTable, TableHeader, TableBody, TableColumn, TableRow} from 'react-md';
import { Field, reduxForm, initialize } from 'redux-form';

import OrganizationService from './Organization.service';
import ListView from '../../../../../components/entity/ListViewEbupot';
import AuthService from '../../../../../services/auth.service'
import ListViewEbupot from '../../../../../components/entity/ListViewEbupot';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Radio, convert, FileInput5, Multiselect} from '../../../../../components/form';
import http from './../../../../../services/http.service'
import counterpart from 'counterpart'
import izitoast from 'izitoast'
import FileInputNext from '../../../../../components/form/FileInputNext';
import bluebird from 'bluebird';
import { resolve } from 'bluebird';
import iziToast from 'izitoast';
import _ from 'lodash'

@reduxForm({form: 'EbupotOrg', destroyOnUnmount: true})
@connect((state)=> ({
  ...OrganizationService.stateConnectSetting()(state),
  // EPPT CORE
  auth: state.auth.company
  // EPPT CORE
}), OrganizationService.actionConnectSetting())
export default class OrganizationView extends ListViewEbupot {
  service=OrganizationService;
  addDialog=false
  editDialog=false

  static contextTypes = {
    showDialog: PropTypes.func.isRequired,
  };

  columns=OrganizationService.columns

  fetchData = async (params=this.props.table.params, onSuccess, onError) => {
    params = _.cloneDeep(params);

    var search = this.getJsonFromUrl();
    var filterObj = this.props.filterObj;
    // if(this.firstCall) filterObj = {}
    delete filterObj.size;
    delete filterObj.page;
    delete filterObj.sort;
    delete filterObj.sortBy;

    params ={
      ...params,
      ...search,
      ...filterObj
    }

    if(!onSuccess) {
      this.props.tableActions.setProperties({
        isLoading: true
      })
    }

    try {
      await this.beforeFetch(params)
      var res = {
        data: [],
        headers: {
          'x-total-count': 0
        }
      }
      var org = []
      console.log(this.props.auth)
      if(this.props.auth && this.props.auth.subscriptions && this.props.auth.subscriptions[0].organizations){
        org = [
          ...this.props.auth.subscriptions[0].organizations
        ]
      }
      if(this.props.auth && this.props.auth.organizations){
        org = [
          ...this.props.auth.organizations
        ]
      }
      if(params['nama.contains']){
        org = org.filter((d)=> {
          return (d.name+"").toLowerCase().indexOf((params['nama.contains']+"").toLowerCase()) != -1  
        })
      }
      if(params['organizationNpwp.in']){
        org = org.filter((d)=> {
          return d.npwp.indexOf(params['organizationNpwp.in']) != -1  
        })
      }
      res.data = org
      res.headers['x-total-count'] = org.length
      var data = res.data;
      data = await this.afterFetch(data)
      var total = parseInt(res.headers['x-total-count']);
      if(onSuccess) onSuccess(data, total)

      if(!onSuccess) {
        this.props.tableActions.setProperties({
          isLoading: false,
          data,
          params:{
            total
          }
        })
      }

      if(this.service && this.service.tableService) {
        this.props.dispatch({type: this.service.tableService.tableType.TABLE_SELECT_DATA_ITEM, index: 0, isSelect: false})
      }

      this.setState({data})

      return {
        data,
        total
      }
    } catch(e) {
      if(onError) onError(e);
      if(!onError) {
        this.props.tableActions.setProperties({
          isLoading: false,
          error:{
            isError:true,
            title:e.statusText,
            message:e.message
          }
        });
      }
      throw e;
    }
  };

  async uploadCertificate(item){
    // to base 64
    const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
    // to base 64
    const selected = this.props.table.selected;
    var initialValue = {}
    selected.map((d,i)=> {
      initialValue[`npwp_${i}`] = d.npwp
      initialValue[`file_${i}`] = null
      initialValue[`filename_${i}`] = null
      initialValue[`password_${i}`] = null
    })
    var res = await this.context.showDialog((_this)=> ({
      title: "Upload Certificate ( Format filename : npwp_password.p12 )",
      initialValue: initialValue,
      okText: "Upload",
      width: 1024,
      height: 600,
      contentStyle: {
        height: 600
      },
      text: (
        <div>
            <Field
              label='Certificate'
              name='files'
              className="md-cell md-cell--12"
              component={FileInputNext}
              validate={validation.required}
              multiple={true}
              onChange={(e, v)=> {
                var files = v;
                var filesLength = v.length;
                var filecounter = 0;
                while(filecounter < filesLength){
                  try {
                    var index = null
                    var getNpwp = files[filecounter].name.slice(0, 15);
                    selected.map((d, i)=> {
                      if(d.npwp == getNpwp) index = i
                    })
                    if(index != null){
                      _this.props.change(`file_${index}`, files[filecounter]);
                      _this.props.change(`filename_${index}`, files[filecounter].name);
                      try {
                        _this.props.change(`password_${index}`, files[filecounter].name.split('_')[1].split('.')[0]);
                      } catch(e){}
                    }
                  } catch(e){}
                  filecounter++;
                }
              }}
              twoLines
            />
            <br />
            <DataTable plain>
              <TableHeader>
                <TableRow>
                  <TableColumn>No.</TableColumn>
                  <TableColumn>Npwp Organisasi</TableColumn>
                  <TableColumn>Certificate</TableColumn>
                  <TableColumn>Password</TableColumn>
                </TableRow>
              </TableHeader>
              {selected.length > 0 &&
              <TableBody>
                {selected.map((d, i)=> {
                  return (
                    <TableRow key={i}>
                      <TableColumn>{i + 1}</TableColumn>
                      <TableColumn>
                        <Field
                          label='Npwp'
                          name={`npwp_${i}`}
                          className="md-cell md-cell--12"
                          component={this.ig.Textfield}
                          validate={validation.required}
                          disabled={true}
                        />
                      </TableColumn>
                      <TableColumn>
                        <Field
                          label='Filename'
                          name={`filename_${i}`}
                          className="md-cell md-cell--12"
                          component={this.ig.Textfield}
                          validate={validation.required}
                          disabled={true}
                        />
                      </TableColumn>
                      {/* <TableColumn>
                        <Field
                          label='Certificate'
                          name={`file_${i}`}
                          className="md-cell md-cell--12"
                          component={FileInputNext}
                          validate={validation.required}
                        />
                      </TableColumn> */}
                      <TableColumn>
                        <Field
                          label='Password'
                          name={`password_${i}`}
                          className="md-cell md-cell--12"
                          component={this.ig.Textfield}
                          validate={validation.required}
                        />
                      </TableColumn>
                    </TableRow>
                  )
                })}
              </TableBody>
              }
            </DataTable>
        </div>
      )
    }))
    if(res){
      var uploadFiles = await bluebird.mapSeries(selected, ((item, index)=> {
        return new Promise( async (resolve, reject)=> {
          try {
            let formData = new FormData();
            formData.append("file", res[`file_${index}`])
            formData.append("npwp", res[`npwp_${index}`])
            formData.append("password", res[`password_${index}`])
            var params = {
              npwp      : res[`npwp_${index}`],
              password  : res[`password_${index}`]
            }
            // let upload = await http.post(`/ebupot/cert/upload`, formData, {
            //   params: params
            // });
            let upload = await http.post(`/api/iams/cert/upload`, formData, {
                params: params
              });
            resolve({
              npwp: item.npwp,
              message: "Certificate berhasil di upload",
            })
          } catch(e){
            resolve({
              npwp: item.npwp,
              message: "Certificate gagal di upload dengan pesan" + e.response.data.message,
            })
          }
        })
      }))
      var result = await this.context.showDialog((_this)=> ({
        title: "",
        initialValue: {},
        width: 800,
        height: 600,
        contentStyle: {
          height: 600
        },
        text: (
          <div>
            <DataTable plain>
              <TableHeader>
                <TableRow>
                  <TableColumn>No.</TableColumn>
                  <TableColumn>Npwp Organisasi</TableColumn>
                  <TableColumn>Pesan</TableColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                {uploadFiles.map((d, i)=> {
                  return (
                    <TableRow key={i}>
                      <TableColumn>{i + 1}</TableColumn>
                      <TableColumn>{d.npwp}</TableColumn>
                      <TableColumn>{d.message}</TableColumn>
                    </TableRow>
                  )
                })}
              </TableBody>
            </DataTable>
          </div>
        )
      }))
    }
    // const toBase64 = file => new Promise((resolve, reject) => {
    //   const reader = new FileReader();
    //   reader.readAsDataURL(file);
    //   reader.onload = () => resolve(reader.result);
    //   reader.onerror = error => reject(error);
    // });
    // var res = await this.context.showDialog(()=> ({
    //   title: "Upload Certificate",
    //   initialValue: {
    //     npwp: item.npwp,
    //     password: null
    //   },
    //   okText: "Upload",
    //   width: 800,
    //   text: (
    //     <div>
    //       <Field
    //         label='Npwp'
    //         name='npwp'
    //         className="md-cell md-cell--12"
    //         component={this.ig.Textfield}
    //         disabled={true}
    //       />
    //       <Field
    //         label='Password'
    //         name='password'
    //         className="md-cell md-cell--12"
    //         component={this.ig.Textfield}
    //         validate={validation.required}
    //       />
    //       <br />
    //       <Field
    //         label='Certificate'
    //         name='file'
    //         className="md-cell md-cell--12"
    //         component={FileInputNext}
    //         validate={validation.required}
    //         multiple={true}
    //       />
    //     </div>
    //   )
    // }))
    // if(res){ 
    //   let formData = new FormData();
    //   formData.append("file", res.file)
    //   // formData.append("npwp", res.npwp)
    //   // formData.append("password", res.password)
    //   var resi = {
    //     npwp: res.npwp,
    //     password: res.password
    //   }
    //   // formData.append("content", await toBase64(res.file))
    //   var base64 = await toBase64(res.file)
    //   var resia = {
    //     npwp: res.npwp,
    //     password: res.password,
    //     content: base64
    //   }
    //   http.post(`/ebupot/cert/upload`, formData, {
    //     params: resi
    //   }).then(function (res){
    //     izitoast.success({title: 'Import File', message: counterpart('custom.doneUpload') })
    //   }).catch(function (e){
    //     izitoast.error({title: 'Import File', message: counterpart('custom.failUpload') })
    //   })
    // }
  }

  async signer(item){
    this.props.history.push("signer/"+item.npwp)
  }

  _tableActions = []
  _barActions = [
    {
      label:'word.uploadCerificate',
      iconClassName:'mdi mdi-upload',
      onClick:() => this.uploadCertificate()
    },
  ]

  // NEW TAB MENU
  async hapusPencarian(){
    try {
      try {
        localStorage.removeItem(`search_${this.service.name}`)
      } catch(e){}
      var params = Object.assign({}, params=this.props.table.params)
      this.fetchData(params)
      await this.props.dispatch(initialize(this.service.name+'_search', {}));
    } catch(e){
      console.log(e, "Search Error ...")
    }
  }

  async handleSearching(values){
    try {
      var formData = this.props.tableFilter2
      var params = Object.assign({}, params=this.props.table.params)
      if(formData.npwp && formData.npwp.length > 0){
        var npwps = ""
        formData.npwp.map((d, index)=> {
          if(index == (formData.npwp.length - 1)){
            npwps += d.npwp
          } else {
            npwps += d.npwp  + ","
          }
        })
        params['organizationNpwp.in'] = npwps
      } else { delete params['organizationNpwp.in'] }
      if(formData.nama){
        params['nama.contains'] = formData.nama
      } else { delete params['nama.contains'] }
      params.onSearch = true
      localStorage.setItem("formData_search", JSON.stringify(formData))
      this.fetchData(params)
    } catch(e){
      iziToast.info({
        title: "Pencarian",
        message: "Data tidak ditemukan, " + e.message
      })
    }
  }

  defaultSearchForm(){
    // var organization = {};
    // if(formData && formData.organization) organization = formData.organization;
    var org = []
    if(this.props.auth && this.props.auth.organizations) org = this.props.auth.organizations
    const { handleSubmit, submitting, valid, pristine } = this.props;
    return (
      <div className="md-grid" style={{ overflow: 'auto' }}>
        <Field
          label     = 'Organization'
          name      = 'organization'
          id        = "spt2126-organization"
          className = "md-cell md-cell--12"
          component = {Searchfield}
          valueField = 'parent'
          options   = {org}
          validate  = {validation.required}
          itemTemplate = {function(d) {
            let nameAndAlias = ""
            if (d.name)
              nameAndAlias = d.name
            if (d.aliasName)
              nameAndAlias += " - " + d.aliasName
            return {
              primaryText  : nameAndAlias,
              secondaryText: d.npwp,
              onClick      : () => {
                if(!this.props.disabled){
                  this.handleItemClick(d); this.hide()
                }
              }
            }
          }}
        />
        <Field
          label     = 'Nama'
          name      = 'nama'
          className = "md-cell md-cell--12"
          component = {Textfield}
        />
        <hr />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.handleSearching.bind(this))}>Cari</Button>
        <hr />
        <Button raised primary className="md-cell md-cell--12" onClick={handleSubmit(this.hapusPencarian.bind(this))}>Hapus Pencarian</Button>
      </div>
    )
  }
  // // yg ditambah
  // // NEW TAB MENU

  _tableActions = [
    // {
    //   label:"More Info",
    //   iconClassName:"mdi mdi-bell",
    //   onClick: (item) => this.editItem(item)
    // },
    // {
    //   label:"Add Certificate",
    //   iconClassName:"mdi mdi-bell",
    //   onClick: (item) => this.uploadCertificate(item)
    // },
    {
      label:"Add Signer",
      iconClassName:"mdi mdi-bell",
      onClick: (item) => this.signer(item)
    },
    // {label:"divider", iconClassName:"-"},
    // {
    //   label:"Delete",
    //   iconClassName:"mdi mdi-delete",
    //   onClick:(item, callback) => this.deleteItem(item, callback),
    //   confirmation:{
    //     title:"sentence.custom.deleteItem",
    //     message:"sentence.custom.deleteItemConfirmation"
    //   }
    // }
  ]

  addItem = () => {
    if(this.addDialog) {
      this.setState({showForm:true, formData: {}})
    } else {
      if(this.rootPath) {
        this.props.history.push(this.rootPath+"/new");
      } else {
        this.props.history.push(''+'organization'+"/new");
      }

    }
  }

  editItem = (item) => {
    if(this.editDialog) {
      this.showFormDialog(item);
    } else {
      if(this.rootPath) {
        this.props.history.push(this.rootPath+"/"+item.id)
      } else {
        this.props.history.push(''+'organization'+"/"+item.id);
      }
    }
  }

}
