import ApiService from '../../../../services/api.service';

class MasterLawanTransaksi4a2Service extends ApiService {
  name= 'masterLawanTransaksi4a2';
  path= 'pasal4/lt';

  constructor() {
    super();
    this.init()
  }

  api = {
    getBySpt: async (params)=> {
      delete params.sort
      delete params.sortBy
      delete params.column
      delete params.total
      delete params.keyword
      delete params.startDate
      delete params.endDate
      params.page = params.page - 1
      var res = await this.http.get(this.apiLocation+'/'+this.path, {params: params})
      return res;
    },
    findOrCreate: async (data)=> {
      var res = await this.http.post(this.apiLocation+'/'+this.path+'/check', data)
      return res.data;
    }
  }
}


export default new MasterLawanTransaksi4a2Service();
