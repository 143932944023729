import React from 'react';
import DialogContainer from '../../Dialog/DialogContainer';
import Hint from '../../Hint';
import { List, ListItem, Button } from 'react-md';
import t from 'counterpart';

class SelectSubscription extends React.Component{
  render(){
    let { visible, onCancel, company, onSelect } = this.props;
    return(
      <DialogContainer
        id="companies-select-subscription"
        title={t.translate('sentence.company.selectSubscription')}
        visible={visible}
        onHide={onCancel}
        actions={[
          <Button flat onClick={onCancel}>
            {t.translate('word.close')}
          </Button>
        ]}
        dialogStyle={{
          width: '100%',
          maxWidth: 480
        }}
      >
        {company ? (
          <div>
            <Hint
              text={t.translate('sentence.company.selectSubscriptionHint').replace('*companyName', company.name)}
            />
            <List>
              {company.subscriptions.map( sub => (
                <ListItem
                  key={`select-subscriotion-${sub.id}`}
                  primaryText={sub.name && sub.name !== '' ? sub.name : (
                    sub.productPackage ? sub.productPackage.name : sub.id
                  )}
                  secondaryText={
                    `${sub.organizations.length} organizations | created by ${sub.createdBy}`
                  }
                  onClick={() => {
                    onSelect( company, sub);
                  }}
                />
              ))}
            </List>
          </div>
        ) : null}
      </DialogContainer>
    )
  }
}

export default SelectSubscription;