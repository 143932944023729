import ApiService from '../../../../services/api.service';

class FileUploadService extends ApiService {
  name= 'FileUpload';
  path= 'upload/image';

  constructor() {
    super()
    this.init()
    this.initApi()
  }

  api = {
    multiUpload: async (data)=> {
      var res = await this.http.post(this.apiLocation+`/file/upload-multiple`, data);
      return res;
    },
    upload: async (data)=> {
      var res = await this.http.post(this.apiLocation+`/upload/image`, data);
      return res;
    },
    getImage: async (id)=> {
      var res = await this.http.get(`${this.apiLocation}/upload/image/${id}`, {
        responseType: 'arraybuffer'
      })
      return res
    }
  }
}

export default new FileUploadService();
