import React from 'react';
import {connect} from 'react-redux';
import { Field, reduxForm, getFormValues} from 'redux-form';
import moment from 'moment';
import {Divider, Subheader, CardTitle,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Card,
  CardActions,
  Button,
  LinearProgress
} from 'react-md';

import FormView from '../../../../../components/entity/form.view';
import BP23Service from './BP_23.service';
// import NomorBPService from '../nomorBP.service';
import {validation, Textfield, Searchfield, TextfieldMask, Switch, Datepicker, Checkbox} from '../../../../../components/form';
import UtilService from '../../../../../services/utils.service'
// import SPT21SignerService from '../SPT21Signer/SPT21Signer.service';
import NpwpService from './../../NpwpService';
import FormViewSpt from '../../../../../components/entity/form.view.spt';
import terbilangService from '../../../../../services/terbilang.service';
import Master23TarifLainService from '../master/Tarif23/Master23TarifLain.service';
import nomorBP23Service from '../nomorBP23.service';
import Master23TarifService from '../master/Tarif23/Master23Tarif.service';
import _ from 'lodash';
import LawanTransaksiDialog from '../../../../../components/entity/LawanTransaksi.dialog';
import MasterLawanTransaksi23Service from '../../../Master/MasterLawanTransaksi23/MasterLawanTransaksi23.service';
import BlokBunifikasiService from "modules/Main/pph/21_26/BlokBunifikasi/BlokBunifikasi.service";

@reduxForm({form: 'BP_23_Form', destroyOnUnmount: true})
@connect((state) => ({
  access      : state.entity.access.api.find,
  organization: state.authEppt.currentOrganization,
  spt         : state.authEppt.currentSPT,
  formData    : getFormValues('BP_23_Form')(state),
  auth: state.auth.company,
}))
export default class BP23Form extends FormViewSpt {
  service  = BP23Service
  viewType = 2;

  typingObj=null;
  typingTimeout=1000;


  initialData={
    bagA: {},
    bagB: {bruto: 0},
    bagC: {}
  }

  constructor(props) {
    super(props);

    this.state= {
      ...this.state,
      showCalculateProgress: false,
      npwpNotValid         : true,
      tarif23              : {
        "dividen"               : 15.0,
        "bunga"                 : 15.0,
        "royalti"               : 15.0,
        "hadiahDanPenghargaan"  : 15.0,
        "sewaDanPenghasilanLain": 2.0,
        "jasaTeknik"            : 2.0,
        "jasaManagemen"         : 2.0,
        "jasaKonsultan"         : 2.0,
        "jasaLain"              : 2.0,
      },

      lkDialog: false
    }
  }

  async npwpValidation(evt, value){
    // EPPT CORE
    if(value == "000000000000000"){
      this.props.change("bagB.bunga.nonNpwp", 1)
      this.props.change("bagB.dividen.nonNpwp", 1)
      this.props.change("bagB.royalti.nonNpwp", 1)
      this.props.change("bagB.hadiahDanPenghargaan.nonNpwp", 1)
      this.props.change("bagB.sewaDanPenghasilan.nonNpwp", 1)
      this.props.change("bagB.jasaTeknik.nonNpwp", 1)
      this.props.change("bagB.jasaManagemen.nonNpwp", 1)
      this.props.change("bagB.jasaKonsultan.nonNpwp", 1)
      this.props.change("bagB.jasaLain1.nonNpwp", 1)
      this.props.change("bagB.jasaLain2.nonNpwp", 1)
      this.props.change("bagB.jasaLain3.nonNpwp", 1)
      this.props.change("bagB.jasaLain4.nonNpwp", 1)
      this.props.change("bagB.jasaLain5.nonNpwp", 1)
    } else {
      this.props.change("bagB.bunga.nonNpwp", 0)
      this.props.change("bagB.dividen.nonNpwp", 0)
      this.props.change("bagB.royalti.nonNpwp", 0)
      this.props.change("bagB.hadiahDanPenghargaan.nonNpwp", 0)
      this.props.change("bagB.sewaDanPenghasilan.nonNpwp", 0)
      this.props.change("bagB.jasaTeknik.nonNpwp", 0)
      this.props.change("bagB.jasaManagemen.nonNpwp", 0)
      this.props.change("bagB.jasaKonsultan.nonNpwp", 0)
      this.props.change("bagB.jasaLain0.nonNpwp", 0)
      this.props.change("bagB.jasaLain2.nonNpwp", 0)
      this.props.change("bagB.jasaLain3.nonNpwp", 0)
      this.props.change("bagB.jasaLain4.nonNpwp", 0)
      this.props.change("bagB.jasaLain5.nonNpwp", 0)
    }
    // EPPT CORE
    if(this.typingObj) clearTimeout(this.typingObj);

    this.typingObj = setTimeout(async ()=> {
      if(value){
        var res = await NpwpService.api.validate(value)
        if(res.valid){
          this.setState({npwpNotValid: false})

          var lk = await MasterLawanTransaksi23Service.api.find({
            'npwp.equals': value,
            // 'organizationId.equals': this.props.spt.data.organization.id
          })

          if(lk.data) {
            var formData = {...this.props.formData};

            if(lk.data.length > 0) {
              var d = lk.data[0];
              // EPPT CORE
              formData.bagA = {
                "npwp"  : d.npwp,
                "nik"   : d.nik,
                "nama"  : d.name,
                "alamat": d.address,
                "email" : d.email
              };
              // EPPT CORE
            } else {
              formData.bagA = {
                "npwp" : value
              }
              this.toast.info({title: "Info", message: `Lawan Transaksi dengan npwp ${value} tidak ditemukan`})
            }
            this.props.initialize(formData);
          }
        } else {
          this.setState({npwpNotValid: true})
          this.toast.warning({title: "Validasi Npwp", message: "npwp tidak valid"})
        }
      }
    }, this.typingTimeout);

    // if(value){
    //   var res = await NpwpService.api.validate(value)
    //   if(res.valid){
    //     this.setState({npwpNotValid: false})
    //   } else {
    //     this.setState({npwpNotValid: true})
    //     this.toast.warning({title: "Validasi Npwp", message: "npwp tidak valid"})
    //   }

      /*
      var nonNpwp = 0;
      var x2      = 1;
      if(value === '000000000000000' || value == '' || !value) {
        nonNpwp = 1;
        // x2 = 1;
      }


      var form = this.props.formData;
      var d    = _.cloneDeep(form.bagB);

      Object.keys(d).forEach((k, i) => {
        if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang')) {

          var x = d[k];

          // console.log(x)
          if(typeof x === 'object') {
            x.nonNpwp = nonNpwp

            if(k.search('jasaLain') >= 0) {
              x.tarif = this.state.tarif23.jasaLain*x2
            } else if(k.search('sewaDanPenghasilan')>= 0){
              x.tarif = this.state.tarif23.sewaDanPenghasilanLain*x2
            } else {
              x.tarif = this.state.tarif23[k]*x2
            }
          }
        }
      })

      console.log(d)
      await this.props.dispatch(this.props.change('bagB', d))
      */

      // await this.calculate()

    // }
  }

  async beforeSave(value) {
    // if(value.bagB.bruto == 0 || value.bagB.bruto == '') {
    //   this.toast.warning({title:'Warning', message:'Isi bruto harus lebih besar dari 0'})
    //   return false;
    // }

    var date;
    if(typeof value.bagC.tanggal === 'string') {
      if(value.bagC.tanggal.search('/') != -1) {
        date = moment(value.bagC.tanggal, 'DD/MM/YYYY');
      } else {
        date = moment(value.bagC.tanggal, 'YYYY-MM-DD');
      }
    } else if(typeof value.bagC.tanggal === 'object') {
      date = moment(value.bagC.tanggal)
    }

    // if(!(date.year() == this.props.spt.data.year && date.month()+1 == this.props.spt.data.month)) {
    //   this.toast.warning({title:'Warning', message:'Tanggal bukti potong harus sesuai dengan masa SPT'})
    //   return false;
    // }

    // for (let i = 1; i <= 6; i++) {
    //   let nama = value.bagB[`jasaLain${i}`].nama
    //   let bruto = parseFloat(value.bagB[`jasaLain${i}`].bruto)

    //   if (bruto > 0) {
    //     if (!nama) {
    //       this.toast.warning({title:'Warning', message:`Jenis jasa lain ${i} harus diisi.`})
    //       return false;
    //     }
    //   }
    // }

    // Remapping Objek
    var mapping = {
      no : value.no,
      cuttingDate : value.bagC.tanggal,
      ref : value.referensi,
      npwp : value.bagA.npwp,
      name : value.bagA.nama,
      address : value.bagA.alamat,
      email : value.bagA.email,
      gross : value.bagB.jmlBruto,
      pph : value.bagB.jmlPph,
      words : value.bagB.terbilang,
      document : {
        "bunga": {
          "gross": value.bagB.bunga.bruto,
          "non"  : value.bagB.bunga.nonNpwp,
          "pph"  : value.bagB.bunga.pph,
          "rates": value.bagB.bunga.tarif
        },
        "dividen": {
          "gross": value.bagB.dividen.bruto,
          "non"  : value.bagB.dividen.nonNpwp,
          "pph"  : value.bagB.dividen.pph,
          "rates": value.bagB.dividen.tarif
        },
        "royalti": {
          "gross": value.bagB.royalti.bruto,
          "non"  : value.bagB.royalti.nonNpwp,
          "pph"  : value.bagB.royalti.pph,
          "rates": value.bagB.royalti.tarif
        },
        "hadiahDanPenghargaan": {
          "gross": value.bagB.hadiahDanPenghargaan.bruto,
          "non"  : value.bagB.hadiahDanPenghargaan.nonNpwp,
          "pph"  : value.bagB.hadiahDanPenghargaan.pph,
          "rates": value.bagB.hadiahDanPenghargaan.tarif
        },
        "sewaDanPenghasilan": {
          "gross": value.bagB.sewaDanPenghasilan.bruto,
          "non"  : value.bagB.sewaDanPenghasilan.nonNpwp,
          "pph"  : value.bagB.sewaDanPenghasilan.pph,
          "rates": value.bagB.sewaDanPenghasilan.tarif
        },
        "jasaTeknik": {
          "gross": value.bagB.jasaTeknik.bruto,
          "non"  : value.bagB.jasaTeknik.nonNpwp,
          "pph"  : value.bagB.jasaTeknik.pph,
          "rates": value.bagB.jasaTeknik.tarif
        },
        "jasaManajemen": {
          "gross": value.bagB.jasaManagemen.bruto,
          "non"  : value.bagB.jasaManagemen.nonNpwp,
          "pph"  : value.bagB.jasaManagemen.pph,
          "rates": value.bagB.jasaManagemen.tarif
        },
        "jasaKonsultan": {
          "gross": value.bagB.jasaKonsultan.bruto,
          "non"  : value.bagB.jasaKonsultan.nonNpwp,
          "pph"  : value.bagB.jasaKonsultan.pph,
          "rates": value.bagB.jasaKonsultan.tarif
        },
        "jasaLain1": {
          "id"    : value.bagB.jasaLain1.id,
          "name"  : value.bagB.jasaLain1.nama,
          "code"  : value.bagB.jasaLain1.code,
          "gross" : value.bagB.jasaLain1.bruto,
          "non"   : value.bagB.jasaLain1.nonNpwp,
          "pph"   : value.bagB.jasaLain1.pph,
          "rates" : value.bagB.jasaLain1.tarif
        },
        "jasaLain2": {
          "id"    : value.bagB.jasaLain2.id,
          "name"  : value.bagB.jasaLain2.nama,
          "code"  : value.bagB.jasaLain2.code,
          "gross" : value.bagB.jasaLain2.bruto,
          "non"   : value.bagB.jasaLain2.nonNpwp,
          "pph"   : value.bagB.jasaLain2.pph,
          "rates" : value.bagB.jasaLain2.tarif
        },
        "jasaLain3": {
          "id"    : value.bagB.jasaLain3.id,
          "name"  : value.bagB.jasaLain3.nama,
          "code"  : value.bagB.jasaLain3.code,
          "gross" : value.bagB.jasaLain3.bruto,
          "non"   : value.bagB.jasaLain3.nonNpwp,
          "pph"   : value.bagB.jasaLain3.pph,
          "rates" : value.bagB.jasaLain3.tarif
        },
        "jasaLain4": {
          "id"    : value.bagB.jasaLain4.id,
          "name"  : value.bagB.jasaLain4.nama,
          "code"  : value.bagB.jasaLain4.code,
          "gross" : value.bagB.jasaLain4.bruto,
          "non"   : value.bagB.jasaLain4.nonNpwp,
          "pph"   : value.bagB.jasaLain4.pph,
          "rates" : value.bagB.jasaLain4.tarif
        },
        "jasaLain5": {
          "id"    : value.bagB.jasaLain5.id,
          "name"  : value.bagB.jasaLain5.nama,
          "code"  : value.bagB.jasaLain5.code,
          "gross" : value.bagB.jasaLain5.bruto,
          "non"   : value.bagB.jasaLain5.nonNpwp,
          "pph"   : value.bagB.jasaLain5.pph,
          "rates" : value.bagB.jasaLain5.tarif
        }
      },
      signNpwp : value.bagC.npwp,
      signName : value.bagC.nama,
      spt : this.props.spt.data
    }

    if(value.id && value.id != "new") mapping.id = value.id
    if(value.consumeId) mapping.consumeId = value.consumeId
    if(typeof mapping.cuttingDate == "object") mapping.cuttingDate = moment(mapping.cuttingDate).format("YYYY-MM-DD")
    mapping.numbering = true

    value = mapping;
    // Remapping Objek

    value.blockBunifikasiMonth = 4
    value.blockBunifikasiYear = 2022
    try {
      let res = await BlokBunifikasiService.api.getSettingAccess(this.props.match.params.companyId)
      if(res.data.active){
        value.blockBunifikasiMonth = res.data.bulan
        value.blockBunifikasiYear = res.data.tahun
      }
    } catch(e){}

    return value;
  }

  async afterSave(res, val) {
    var formData = this.props.formData;

    var data = {
      ...formData.bagA,
      organization: this.props.spt.data.organization,
      npwpProfile: this.props.spt.data.organization.npwp
    }
    if(data.id) delete data.id;
    await MasterLawanTransaksi23Service.api.findOrCreate(data)
  }

  async initData() {
    if(this.props.match.params.id == 'new') {

    // EPPT CORE
    var tarif = {};
    try {
      // tarif = await Master23TarifService.api.byCompany({
      //   compId: this.props.organization.company.id,
      //   tgl   : moment().format("YYYY-MM-DD")
      // });

      var tarif = await Master23TarifService.api.byCompany()
      var tarifLain = await Master23TarifLainService.api.byCompany()
      tarif.jasaManagemen         = tarif.jasaManajemen;
    } catch (error) {}

    if(tarif) {
      await this.setState({'tarif23': tarif});
    }


      // let nomorBPReq = await nomorBP23Service.api.get(this.props.spt.data.organization.id)
      let nomorBPReq = await nomorBP23Service.api.get(this.props.spt.data.id)
      var nomorBP    = "";

      if(nomorBPReq.data.mode === 'PREF_SUF') {
        nomorBP = nomorBPReq.data.prefix+nomorBPReq.data.suffix;
      } else {
        nomorBP = nomorBPReq.data.suffix+nomorBPReq.data.prefix;
      }
      let signer = {};  //await SPT21SignerService.api.getSigner(this.props.spt.data.id);

      this.initialData = {
        ...this.initialData,
        no  : nomorBP,   //.data.no,
        bagC: {
          nama   : this.props.organization.name,
          npwp   : this.props.organization.npwp,
          tanggal: new Date(),
        },
        spt   : this.props.spt.data,
        "bagB": {
          "bunga": {
              "bruto"  : 0,
              "nama"   : "Bunga",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.bunga
          },
          "dividen": {
              "bruto"  : 0,
              "nama"   : "Dividen",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.dividen
          },
          "hadiahDanPenghargaan": {
              "bruto"  : 0,
              "nama"   : "Hadiah dan Penghargaan",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.hadiahDanPenghargaan
          },
          "jasaKonsultan": {
              "bruto"  : 0,
              "nama"   : "Jasa Konsultan",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.jasaKonsultan
          },
          "jasaLain1": {
              "bruto"  : 0,
              "nama"   : "",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.jasaLain
          },
          "jasaLain2": {
              "bruto"  : 0,
              "nama"   : "",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.jasaLain,
          },
          "jasaLain3": {
              "bruto"  : 0,
              "nama"   : "",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.jasaLain
          },
          "jasaLain4": {
              "bruto"  : 0,
              "nama"   : "",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.jasaLain
          },
          "jasaLain5": {
              "bruto"  : 0,
              "nama"   : "",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.jasaLain
          },
          "jasaLain6": {
              "bruto"  : 0,
              "nama"   : "",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.jasaLain
          },
          "jasaManagemen": {
              "bruto"  : 0,
              "nama"   : "Jasa Managemen",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.jasaManagemen
          },
          "jasaTeknik": {
              "bruto"  : 0,
              "nama"   : "Jasa Teknik",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.jasaTeknik
          },
          "jmlBruto": 0,
          "jmlPph"  : 0,
          "royalti" : {
              "bruto"  : 0,
              "nama"   : "Royalti",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.royalti
          },
          "sewaDanPenghasilan": {
              "bruto"  : 0,
              "nama"   : "Sewa dan Penghasilan",
              "nonNpwp": 0,
              "pph"    : 0,
              "tarif"  : this.state.tarif23.sewaDanPenghasilan
          },
          "terbilang": ""
        }
      }
      this.props.initialize(this.initialData);
    } else {
      let res = await this.service.api.findOne(this.props.match.params.id);
      // Remapping Objek

      var remapping = {
        consumeId: res.data.consumeId,
        id: res.data.id,
        no  : res.data.no,
        bagA: {
          alamat: res.data.address,
          email : res.data.email,
          npwp  : res.data.npwp,
          nama  : res.data.name
        },
        bagC: {
          nama: res.data.signName,
          npwp: res.data.signNpwp,
          tanggal: res.data.cuttingDate
        },
        referensi: res.data.ref,
        spt   : res.data.spt,
        bagB: {
          bunga: {
              bruto  : res.data.document.bunga.gross,
              nama   : "Bunga",
              nonNpwp: res.data.document.bunga.non,
              pph    : res.data.document.bunga.pph,
              tarif  : res.data.document.bunga.rates
          },
          dividen: {
              bruto  : res.data.document.dividen.gross,
              nama   : "Dividen",
              nonNpwp: res.data.document.dividen.non,
              pph    : res.data.document.dividen.pph,
              tarif  : res.data.document.dividen.rates
          },
          hadiahDanPenghargaan: {
              bruto  : res.data.document.hadiahDanPenghargaan.gross,
              nama   : "Hadiah dan Penghargaan",
              nonNpwp: res.data.document.hadiahDanPenghargaan.non,
              pph    : res.data.document.hadiahDanPenghargaan.pph,
              tarif  : res.data.document.hadiahDanPenghargaan.rates
          },
          jasaKonsultan: {
              bruto  : res.data.document.jasaKonsultan.gross,
              nama   : "Jasa Konsultan",
              nonNpwp: res.data.document.jasaKonsultan.non,
              pph    : res.data.document.jasaKonsultan.pph,
              tarif  : res.data.document.jasaKonsultan.rates
          },
          jasaLain1: {
              bruto  : res.data.document.jasaLain1.gross,
              nama   : res.data.document.jasaLain1.name,
              code   : res.data.document.jasaLain1.code,
              id     : res.data.document.jasaLain1.id,
              nonNpwp: res.data.document.jasaLain1.non,
              pph    : res.data.document.jasaLain1.pph,
              tarif  : res.data.document.jasaLain1.rates
          },
          jasaLain2: {
              bruto  : res.data.document.jasaLain2.gross,
              nama   : res.data.document.jasaLain2.name,
              code   : res.data.document.jasaLain2.code,
              id     : res.data.document.jasaLain2.id,
              nonNpwp: res.data.document.jasaLain2.non,
              pph    : res.data.document.jasaLain2.pph,
              tarif  : res.data.document.jasaLain2.rates
          },
          jasaLain3: {
              bruto  : res.data.document.jasaLain3.gross,
              nama   : res.data.document.jasaLain3.name,
              code   : res.data.document.jasaLain3.code,
              id     : res.data.document.jasaLain3.id,
              nonNpwp: res.data.document.jasaLain3.non,
              pph    : res.data.document.jasaLain3.pph,
              tarif  : res.data.document.jasaLain3.rates
          },
          jasaLain4: {
              bruto  : res.data.document.jasaLain4.gross,
              nama   : res.data.document.jasaLain4.name,
              code   : res.data.document.jasaLain4.code,
              id     : res.data.document.jasaLain4.id,
              nonNpwp: res.data.document.jasaLain4.non,
              pph    : res.data.document.jasaLain4.pph,
              tarif  : res.data.document.jasaLain4.rates
          },
          jasaLain5: {
              bruto  : res.data.document.jasaLain5.gross,
              nama   : res.data.document.jasaLain5.name,
              code   : res.data.document.jasaLain5.code,
              id     : res.data.document.jasaLain5.id,
              nonNpwp: res.data.document.jasaLain5.non,
              pph    : res.data.document.jasaLain5.pph,
              tarif  : res.data.document.jasaLain5.rates
          },
          jasaLain6: {
              bruto  : 0,
              nama   : "",
              nonNpwp: 0,
              pph    : 0,
              tarif  : 0
          },
          jasaManagemen: {
              bruto  : res.data.document.jasaManajemen.gross,
              nama   : "Jasa Managemen",
              nonNpwp: res.data.document.jasaManajemen.non,
              pph    : res.data.document.jasaManajemen.pph,
              tarif  : res.data.document.jasaManajemen.rates
          },
          jasaTeknik: {
              bruto  : res.data.document.jasaTeknik.gross,
              nama   : "Jasa Teknik",
              nonNpwp: res.data.document.jasaTeknik.non,
              pph    : res.data.document.jasaTeknik.pph,
              tarif  : res.data.document.jasaTeknik.rates
          },
          jmlBruto: res.data.gross,
          jmlPph  : res.data.pph,
          royalti : {
              bruto  : res.data.document.royalti.gross,
              nama   : "Royalti",
              nonNpwp: res.data.document.royalti.non,
              pph    : res.data.document.royalti.pph,
              tarif  : res.data.document.royalti.rates
          },
          sewaDanPenghasilan: {
              bruto  : res.data.document.sewaDanPenghasilan.gross,
              nama   : "Sewa dan Penghasilan",
              nonNpwp: res.data.document.sewaDanPenghasilan.non,
              pph    : res.data.document.sewaDanPenghasilan.pph,
              tarif  : res.data.document.sewaDanPenghasilan.rates
          },
          terbilang: res.data.words
        }
      }

      remapping.numbering = res.data.numbering
      res.data = remapping
      // Remapping Objek

      this.props.initialize(res.data);
      // this.npwpValidation(null, res.data.bagA.npwp)
      this.setState({npwpNotValid: false})
    }
  }

  async changeTarif(d, e) {
    var tarif = await Master23TarifService.api.byCompany()

    if(tarif) {
      await this.setState({'tarif23': tarif});
    }

    var form = {...this.props.formData};
    var bagB = form.bagB;

    if(bagB) {
      bagB.bunga.tarif                = this.state.tarif23.bunga;
      bagB.dividen.tarif              = this.state.tarif23.dividen;
      bagB.hadiahDanPenghargaan.tarif = this.state.tarif23.hadiahDanPenghargaan;
      bagB.jasaKonsultan.tarif        = this.state.tarif23.jasaKonsultan;
      bagB.jasaLain1.tarif            = this.state.tarif23.jasaLain;
      bagB.jasaLain2.tarif            = this.state.tarif23.jasaLain;
      bagB.jasaLain3.tarif            = this.state.tarif23.jasaLain;
      bagB.jasaLain4.tarif            = this.state.tarif23.jasaLain;
      bagB.jasaLain5.tarif            = this.state.tarif23.jasaLain;
      bagB.jasaLain6.tarif            = this.state.tarif23.jasaLain;
      bagB.jasaManagemen.tarif        = this.state.tarif23.jasaManagemen;
      bagB.jasaTeknik.tarif           = this.state.tarif23.jasaTeknik;
      bagB.royalti.tarif              = this.state.tarif23.royalti;
      bagB.sewaDanPenghasilan.tarif   = this.state.tarif23.sewaDanPenghasilanLain;

      await this.props.initialize(form);

      await this.reCalculate()
    }
  }

  // async calculate(e,v,pv,f, parent) {
  //   var form = this.props.formData;
  //   var d    = form.bagB[parent];

  //   var bruto = parseFloat(d.bruto);
  //   var tarif = parseFloat(d.tarif);

  //   d.nonNpwp = 0;
  //   if(form.bagA.npwp === '000000000000000' || form.bagA.npwp == '' || !form.bagA.npwp) {
  //     d.nonNpwp = 1;
  //     // x2 = 1;
  //   }

  //   if(f.search('bruto') >= 0) {
  //     bruto = parseFloat(v)

  //     if(bruto == 0) d.nonNpwp = 0

  //   } else if(f.search('tarif') >= 0) {
  //     tarif = parseFloat(v)
  //   }


  //   await this.props.change(`bagB.${parent}.nonNpwp`, d.nonNpwp);


  //   if(!bruto) bruto = 0;
  //   if(!tarif) tarif = 0;

  //   var nonNpwp                  = 1;
  //   if  (d.nonNpwp == 1) nonNpwp = 2;

  //   await this.props.change(`bagB.${parent}.pph`, bruto*tarif*nonNpwp/100);
  //   this.calculateTotal()
  // }

  async calculate(e,v,pv,f, parent) {
    var form = this.props.formData;
    var d    = form.bagB[parent];

    var bruto = parseFloat(d.bruto);
    var tarif = parseFloat(d.tarif);

    d.nonNpwp = 0;
    if(form.bagA.npwp === '000000000000000' || form.bagA.npwp == '' || !form.bagA.npwp) {
      d.nonNpwp = 1;
      // x2 = 1;
    }

    if(f.search('bruto') >= 0) {
      bruto = parseFloat(v)

      if(bruto == 0) d.nonNpwp = 0

    } else if(f.search('tarif') >= 0) {
      tarif = parseFloat(v)
    }


    await this.props.change(`bagB.${parent}.nonNpwp`, d.nonNpwp);


    if(!bruto) bruto = 0;
    if(!tarif) tarif = 0;

    var nonNpwp                  = 1;
    if  (d.nonNpwp == 1) nonNpwp = 2;

    var pph = Math.floor(bruto * tarif * nonNpwp / 100);

    await this.props.change(`bagB.${parent}.pph`, pph);
    this.calculateTotal()
  }

  async calculateTotal() {
    var form = this.props.formData;
    var d    = form.bagB;

    var jmlPph   = 0;
    var jmlBruto = 0;
    Object.keys(d).forEach((k, i) => {
      if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang' || k == 'lastModifiedBy' || k == 'lastModifiedDate' || k == 'id' || k == 'createdDate' || k == 'createdBy')) {
        var x = d[k];
        if(typeof x == 'object') {
          jmlPph   = jmlPph+ parseFloat(x.pph);
          jmlBruto = jmlBruto+ parseFloat(x.bruto)
        }


      }
    })

    this.props.change('bagB.jmlBruto', jmlBruto)
    this.props.change('bagB.jmlPph', jmlPph);

    // console.log(jmlPph)

    this.props.change('bagB.terbilang', terbilangService(parseInt(jmlPph)));

  }

  async reCalculate() {
    var form = {...this.props.formData};
    var d    = form.bagB;

    d.nonNpwp = 0;
    if(form.bagA.npwp === '000000000000000' || form.bagA.npwp == '' || !form.bagA.npwp) {
      d.nonNpwp = 1;
      // x2 = 1;
    }

    var nonNpwp                  = 1;
    if  (d.nonNpwp == 1) nonNpwp = 2;

    Object.keys(d).forEach((k, i) => {
      if(!(k == 'jmlBruto' || k == 'jmlPph' || k == 'terbilang' || k == 'lastModifiedBy' || k == 'lastModifiedDate' || k == 'id' || k == 'createdDate' || k == 'createdBy')) {
        var x = d[k];
        if(typeof x == 'object') {
          if(x.bruto) {
            x.pph = x.bruto*x.tarif*nonNpwp/100;
          }
        }
      }
    })

    console.log(form)

    await this.props.initialize(form);

    this.calculateTotal()

  }

  formView() {
    var formData                       = {bagB: {}};
    if  (this.props.formData) formData = this.props.formData

    var companyId                                                              = '';
    if  (this.props.organization && this.props.organization.company) companyId = this.props.organization.company.id;
    if  (formData.spt && formData.spt.organization) companyId                  = formData.spt.organization.company.id

    return (
      <div>

        <LawanTransaksiDialog
          spt      = {this.props.spt.data}
          onSelect = {(d)=> {
            var formData = {...this.props.formData};

            // EPPT CORE
            formData.bagA = {
              "npwp"  : d.npwp,
              "nik"   : d.nik,
              "nama"  : d.name,
              "alamat": d.address,
              "email" : d.email
            };
            // EPPT CORE

            this.setState({npwpNotValid: false})

            this.props.initialize(formData);

          }}
          service = {MasterLawanTransaksi23Service}
          visible = {this.state.lkDialog}
          onHide  = {()=> this.setState({lkDialog: false})}
        />
        <Card>
          <div className="md-grid">
            <Field
              label     = 'No.Bukti Potong'
              name      = 'no'
              className = "md-cell md-cell--6"
              component = {Textfield}
              validate  = {validation.required}
            />
            <Field
              label        = 'Tanggal Bukti Potong'
              name         = 'bagC.tanggal'
              className    = "md-cell md-cell--6"
              // onDateChange = {async (d, e) => this.changeTarif(d)}
              component    = {Datepicker}
              validate     = {validation.required}
            />
            <Field
              label     = 'Referensi'
              name      = 'referensi'
              id        = "BP21FForm-referensi"
              className = "md-cell md-cell--12"
              component = {Textfield}
            />
          </div>
        </Card>

        <br/>

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='A. IDENTITAS PENERIMA PENGHASILAN YANG DIPOTONG ' />
          <Divider/>
          <div className="md-grid">
            <div className='md-cell md-cell--6'>
              <div className='mpk-layout'>
                <Field
                  className  = 'flex'
                  label      = 'NPWP'
                  name       = 'bagA.npwp'
                  id         = "BP21FForm-npwp"
                  component  = {TextfieldMask}
                  maskFormat = "##.###.###.#-###-###"
                  mask       = "_"
                  length     = {15}
                  onChange   = {this.npwpValidation.bind(this)}
                />
                <Button icon primary swapTheming style={{marginTop: 10}} onClick={()=> this.setState({lkDialog: true})}>search</Button>
               </div>
              <Field
                label = 'Nama'
                name  = 'bagA.nama'
                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
              />
              {/* <Field
                label = 'NIK'
                name  = 'bagA.nik'
                component = {Textfield}
              /> */}
              {/* <Field
                label     = 'Telepon'
                name      = 'name'
                id        = "BP21FForm-name"
                component = {Textfield} */}

            </div>
            <div className='md-cell md-cell--6'>
              {/* <Field
                label     = 'Kode Poss'
                name      = 'npwp'
                id        = "BP21FForm-codePoss"
                component = {Textfield}
              /> */}
              <Field
                label = 'Alamat'
                name  = 'bagA.alamat'
                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
              />
              <Field
                label = 'Email'
                name  = 'bagA.email'
                disabled  = {this.state.npwpNotValid}
                component = {Textfield}
              />
            </div>
          </div>
        </Card>

        <br/>

        {this.state.showCalculateProgress && <LinearProgress id='lin_pro' style={{margin: 0}} />}
        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='B. PPH PASAL 23 YANG DIPOTONG' />
          <Divider/>

          <DataTable plain fixedScrollWrapperStyle={{overflow: 'inherit'}} style={{overflow: 'inherit'}}>
            <TableHeader>
              <TableRow>
                <TableColumn>No.</TableColumn>
                <TableColumn>Jenis Penghasilan</TableColumn>
                <TableColumn className='md-text-right'>Bruto(Rp)</TableColumn>
                <TableColumn className='md-text-right'>Tanpa Npwp</TableColumn>
                <TableColumn className='md-text-right'>Tarif(%)</TableColumn>
                <TableColumn className='md-text-right'>PPH Di Potong(Rp)</TableColumn>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableColumn>
                  1.
                </TableColumn>
                <TableColumn>Dividen</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.dividen.bruto'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    money     = {','}
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'dividen')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'bagB.dividen.nonNpwp'
                    component = {Textfield}
                    className = 'md-text-right'
                    money     = {','}
                    disabled
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.dividen.tarif'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'dividen')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.dividen.pph'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    money     = {','}
                    disabled
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  2.
                </TableColumn>
                <TableColumn>Bunga</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.bunga.bruto'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'bunga')}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'bagB.bunga.nonNpwp'
                    component = {Textfield}
                    className = 'md-text-right'
                    disabled
                    money = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.bunga.tarif'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'bunga')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.bunga.pph'
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    disabled
                    money = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  3.
                </TableColumn>
                <TableColumn>Royalti</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.royalti.bruto'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'royalti')}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'bagB.royalti.nonNpwp'
                    component = {Textfield}
                    className = 'md-text-right'
                    disabled
                    money = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.royalti.tarif'
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'royalti')}
                    component = {TextfieldMask}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.royalti.pph'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    disabled
                    money = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  4.
                </TableColumn>
                <TableColumn>Hadiah dan Penghargaan</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.hadiahDanPenghargaan.bruto'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'hadiahDanPenghargaan')}
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'bagB.hadiahDanPenghargaan.nonNpwp'
                    component = {Textfield}
                    className = 'md-text-right'
                    money     = {','}
                    disabled
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.hadiahDanPenghargaan.tarif'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'hadiahDanPenghargaan')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.hadiahDanPenghargaan.pph'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    disabled
                    money = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  5.
                </TableColumn>
                <TableColumn>Sewa dan Penghasilan Lain sehubungan dengan penggunaan harta **)</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.sewaDanPenghasilan.bruto'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'sewaDanPenghasilan')}
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'bagB.sewaDanPenghasilan.nonNpwp'
                    component = {Textfield}
                    className = 'md-text-right'
                    money     = {','}
                    disabled
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.sewaDanPenghasilan.tarif'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'sewaDanPenghasilan')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.sewaDanPenghasilan.pph'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    money     = {','}
                    disabled
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>
                  6.
                </TableColumn>
                <TableColumn>Jasa Teknik, Jasa Manajemen , Jasa Konsultasi dan Jasa Lain sesuai PMK-244/PMK.03/2008 **) :</TableColumn>
                <TableColumn>

                </TableColumn>
                <TableColumn>

                </TableColumn>
                <TableColumn>

                </TableColumn>
                <TableColumn>

                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>

                </TableColumn>
                <TableColumn>a. Jasa Teknik</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.jasaTeknik.bruto'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaTeknik')}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'bagB.jasaTeknik.nonNpwp'
                    component = {Textfield}
                    className = 'md-text-right'
                    disabled
                    money = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.jasaTeknik.tarif'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaTeknik')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.jasaTeknik.pph'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    disabled
                    money = {','}
                  />
                </TableColumn>
              </TableRow>
              <TableRow>
                <TableColumn>

                </TableColumn>
                <TableColumn>b. Jasa Manajemen</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.jasaManagemen.bruto'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaManagemen')}
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name = 'bagB.jasaManagemen.nonNpwp'
                    disabled
                    component = {Textfield}
                    className = 'md-text-right'
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.jasaManagemen.tarif'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaManagemen')}
                    component = {TextfieldMask}
                    className = 'md-text-right'
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.jasaManagemen.pph'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    disabled
                    money = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>

                </TableColumn>
                <TableColumn>c. Jasa Konsultan</TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.jasaKonsultan.bruto'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaKonsultan')}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'bagB.jasaKonsultan.nonNpwp'
                    component = {Textfield}
                    className = 'md-text-right'
                    disabled
                    money = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.jasaKonsultan.tarif'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaKonsultan')}
                    component = {TextfieldMask}
                    className = 'md-text-right'
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.jasaKonsultan.pph'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    disabled
                    money = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>

                </TableColumn>
                <TableColumn>d. Jasa Lain: **)</TableColumn>
                <TableColumn>

                </TableColumn>
                <TableColumn>

                </TableColumn>
                <TableColumn>

                </TableColumn>
                <TableColumn>

                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>

                </TableColumn>
                <TableColumn>
                  <Field
                    label      = 'Jasa'
                    name       = 'bagB.jasaLain1.nama'
                    component  = {Searchfield}
                    valueField = 'name'
                    viewField  = 'name'
                    service    = {Master23TarifLainService}
                    apiPath    = 'lookup'
                    remoteSearch={false}
                    searchField = {['name']}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.jasaLain1.bruto'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaLain1')}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'bagB.jasaLain1.nonNpwp'
                    component = {Textfield}
                    className = 'md-text-right'
                    disabled
                    money = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.jasaLain1.tarif'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaLain1')}
                    component = {TextfieldMask}
                    className = 'md-text-right'
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.jasaLain1.pph'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    disabled
                    money = {','}
                  />
                </TableColumn>
              </TableRow>


              <TableRow>
                <TableColumn>

                </TableColumn>
                <TableColumn>
                  <Field
                    label      = 'Jasa'
                    name       = 'bagB.jasaLain2.nama'
                    component  = {Searchfield}
                    valueField = 'name'
                    viewField  = 'name'
                    service    = {Master23TarifLainService}
                    apiPath    = 'lookup'
                    remoteSearch={false}
                    searchField = {['name']}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.jasaLain2.bruto'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaLain2')}
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'bagB.jasaLain2.nonNpwp'
                    component = {Textfield}
                    className = 'md-text-right'
                    disabled
                    money = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.jasaLain2.tarif'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaLain2')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.jasaLain2.pph'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    money     = {','}
                    disabled
                  />
                </TableColumn>
              </TableRow>


              <TableRow>
                <TableColumn>

                </TableColumn>
                <TableColumn>
                  <Field
                    label      = 'Jasa'
                    name       = 'bagB.jasaLain3.nama'
                    component  = {Searchfield}
                    valueField = 'name'
                    viewField  = 'name'
                    service    = {Master23TarifLainService}
                    apiPath    = 'lookup'
                    remoteSearch={false}
                    searchField = {['name']}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.jasaLain3.bruto'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaLain3')}
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name = 'bagB.jasaLain3.nonNpwp'
                    disabled
                    component = {Textfield}
                    className = 'md-text-right'
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.jasaLain3.tarif'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaLain3')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.jasaLain3.pph'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    money     = {','}
                    disabled
                  />
                </TableColumn>
              </TableRow>


              <TableRow>
                <TableColumn>

                </TableColumn>
                <TableColumn>
                  <Field
                    label      = 'Jasa'
                    name       = 'bagB.jasaLain4.nama'
                    component  = {Searchfield}
                    valueField = 'name'
                    viewField  = 'name'
                    service    = {Master23TarifLainService}
                    apiPath    = 'lookup'
                    remoteSearch={false}
                    searchField = {['name']}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.jasaLain4.bruto'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    money     = {','}
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaLain4')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'bagB.jasaLain4.nonNpwp'
                    component = {Textfield}
                    className = 'md-text-right'
                    money     = {','}
                    disabled
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.jasaLain4.tarif'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaLain4')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.jasaLain4.pph'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    money     = {','}
                    disabled
                  />
                </TableColumn>
              </TableRow>

              <TableRow>
                <TableColumn>

                </TableColumn>
                <TableColumn>
                  <Field
                    label      = 'Jasa'
                    name       = 'bagB.jasaLain5.nama'
                    component  = {Searchfield}
                    valueField = 'name'
                    viewField  = 'name'
                    service    = {Master23TarifLainService}
                    apiPath    = 'lookup'
                    remoteSearch={false}
                    searchField = {['name']}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.jasaLain5.bruto'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaLain5')}
                    money     = {','}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    name      = 'bagB.jasaLain5.nonNpwp'
                    component = {Textfield}
                    className = 'md-text-right'
                    money     = {','}
                    disabled
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Tarif'
                    name      = 'bagB.jasaLain5.tarif'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    onChange  = {(e, v, vp, f) => this.calculate(e, v, vp, f, 'jasaLain5')}
                  />
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.jasaLain5.pph'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    money     = {','}
                    disabled
                  />
                </TableColumn>
              </TableRow>

              <TableRow style={{background: '#ddd'}}>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  Jumlah
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='Bruto'
                    name      = 'bagB.jmlBruto'
                    component = {TextfieldMask}
                    className = 'md-text-right'
                    disabled
                    money = {','}
                  />
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                </TableColumn>
                <TableColumn>
                  <Field
                    // label='PPH'
                    name      = 'bagB.jmlPph'
                    className = 'md-text-right'
                    component = {TextfieldMask}
                    disabled
                    money = {','}
                  />
                </TableColumn>
              </TableRow>

              <TableRow style={{background: '#ddd'}}>
                <TableColumn>

                </TableColumn>
                <TableColumn colSpan={5}>
                  <div className="md-grid" style={{ padding: 0 }}>
                    <div className="md-cell md-cell--1">
                      <p style={{ margin: 10, marginLeft: -10 }}>Terbilang</p>
                    </div>
                    <div className="md-cell md-cell--11">
                      <Field
                        // label='Bruto'
                        name      = 'bagB.terbilang'
                        component = {Textfield}
                        disabled
                      />
                    </div>
                  </div>
                </TableColumn>
              </TableRow>
            </TableBody>

          </DataTable>
        </Card>

        <br />

        <Card>
          <CardTitle title='' style={{padding: '10px 16px'}} subtitle='C. IDENTITAS PEMOTONG ' />
          <Divider/>

          <div className="md-grid">
          <Field
            label     = 'NPWP'
            name      = 'bagC.npwp'
            className = "md-cell md-cell--6"
            component  = {TextfieldMask}
            maskFormat = "##.###.###.#-###-###"
            mask       = "_"
          />
          <Field
            label     = 'Nama'
            name      = 'bagC.nama'
            className = "md-cell md-cell--6"
            component = {Textfield}
          />
          </div>
        </Card>

      </div>
    )
  }

}
